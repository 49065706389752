// import React, { useEffect } from "react";
// import {
//   Box,
//   FormControlLabel,
//   Grid,
//   Radio,
//   RadioGroup,
//   Stack,
// } from "@mui/material";
// import CustomDataGrid from "../components/CustomDataGrid";
// // import MainHeading from "../components/MainHeading";
// import CustomField from "../components/CustomField";
// import CustomButton from "../components/CustomButton";
// import { useDispatch } from "react-redux";
// import Loading from "../components/Loading";
// import CheckBox from "../components/CheckBox";
// import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";

// const CustomPreview = ({
//   children,
//   paginationDetail,
//   rows,
//   columns,
//   handleCell,
//   moduleHeading,
//   searchLabel,
//   handleBySearch,
//   handleGetAll,
//   isModal = false,
//   loading,
//   isByPatient = false,
//   isExactMatch = false,
//   showNew,
//   navPath,
// }) => {
//   let flage = false;
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const previewFormik = useFormik({
//     initialValues: {
//       showInActive: false,
//       searchTerm: "",
//       page: 0,
//       pageSize: 1,
//       isPatientSearch: true,
//       exactMatch: false,
//     },
//     onSubmit: (values) => {
//       console.log("value submit search", values);
//       if (values.searchTerm) {
//         dispatch(
//           handleBySearch({
//             ...values,
//             page: 1,
//           })
//         );
//       } else {
//         dispatch(handleGetAll(values.page + 1));
//       }
//     },
//   });

//   const handlePageChange = (newPage) => {
//     // setPage(newPage);
//     previewFormik.setFieldValue("page", newPage);
//   };

//   // Perform initial fetch on component mount
//   useEffect(() => {
//     if (!flage) {
//       flage = true;
//       dispatch(handleGetAll(previewFormik.values.page + 1));
//     }
//   }, [previewFormik.values.page]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (previewFormik.values.searchTerm === "") {
//           dispatch(handleGetAll(previewFormik.values.page + 1));
//         } else {
//         }
//       } catch (err) {
//         console.log("err", err);
//       }
//     };

//     fetchData();
//   }, [previewFormik.values.searchTerm, previewFormik.values.page]);

//   return (
//     <Box margin="15px">
//       <Grid container rowSpacing={1}>
//         {/* {!isModal && (
//           <Grid item md={12} sm={12} xs={12}>
//             <MainHeading mainheading={moduleHeading} />
//           </Grid>
//         )} */}

//         {/* {children && (
//           <Grid item md={12}>
//             {children}
//           </Grid>
//         )} */}

//         <Grid item md={12}>
//           <Stack
//             direction="row"
//             alignItems="flex-end"
//             justifyContent="space-between"
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "end",
//                 margin: "3px",
//               }}
//               component={"form"}
//               onSubmit={previewFormik.handleSubmit}
//             >
//               <CustomField
//                 type="text"
//                 size="small"
//                 label={searchLabel}
//                 value={previewFormik.values.searchTerm}
//                 name="searchTerm"
//                 handleChange={previewFormik.handleChange}
//               />
//               <CustomButton
//                 type="submit"
//                 isBlue={true}
//                 padding="5px 30px"
//                 margin={"0 10px 0"}
//                 isSearch={true}
//               >
//                 Search
//               </CustomButton>
//             </Box>
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               {children && (
//                 // <Grid item md={12}>
//                 <Box>{children}</Box>
//                 // </Grid>
//               )}
//               {showNew && (
//                 <Box>
//                   <CustomButton
//                     type="button"
//                     isBlue={true}
//                     padding="5px 30px"
//                     margin={"0 10px 0"}
//                     isAdd={true}
//                     handleClick={() => navigate(navPath)}
//                   >
//                     Add New
//                   </CustomButton>
//                 </Box>
//               )}
//             </Box>
//           </Stack>
//         </Grid>

//         <Grid item md={12}>
//           <Stack direction="row" alignItems="center" gap="12px">
//             {isByPatient && (
//               <Box
//                 sx={{
//                   borderRight: "2px solid grey",
//                 }}
//               >
//                 <RadioGroup row>
//                   <FormControlLabel
//                     value={true}
//                     control={
//                       <Radio
//                         checked={previewFormik.values.isPatientSearch}
//                         onChange={() =>
//                           previewFormik.setValues((preVals) => ({
//                             ...preVals,
//                             isPatientSearch: true,
//                           }))
//                         }
//                       />
//                     }
//                     label="Patient"
//                   />
//                   <FormControlLabel
//                     value={false}
//                     control={
//                       <Radio
//                         checked={!previewFormik.values.isPatientSearch}
//                         onChange={() =>
//                           previewFormik.setValues((preVals) => ({
//                             ...preVals,
//                             isPatientSearch: false,
//                           }))
//                         }
//                       />
//                     }
//                     label="Insured"
//                   />
//                 </RadioGroup>
//               </Box>
//             )}

//             <Box>
//               <CheckBox
//                 handleChange={() =>
//                   previewFormik.setValues((preVals) => ({
//                     ...preVals,
//                     showInActive: !previewFormik.values.showInActive,
//                   }))
//                 }
//                 CheckedValue={previewFormik?.values?.showInActive}
//                 name="showInActive"
//                 label={`Show inactive`}
//               />
//             </Box>

//             {isExactMatch && (
//               <Box item md={3}>
//                 <CheckBox
//                   handleChange={() =>
//                     previewFormik.setValues((preVals) => ({
//                       ...preVals,
//                       exactMatch: !previewFormik.values.exactMatch,
//                     }))
//                   }
//                   CheckedValue={previewFormik?.values?.exactMatch}
//                   label={`Show exact matches only`}
//                 />
//               </Box>
//             )}
//           </Stack>
//         </Grid>

//         <Grid item md={12} xs={12} sm={12}>
//           {loading ? (
//             <Loading />
//           ) : (
//             <CustomDataGrid
//               columns={columns}
//               paginationDetail={paginationDetail}
//               rows={rows}
//               pageSize={previewFormik.values.pageSize}
//               handleCellClick={handleCell}
//               page={previewFormik.values.page}
//               handlePageChange={handlePageChange}
//             />
//           )}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default CustomPreview;

import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomDataGrid from "../components/CustomDataGrid";
// import MainHeading from "../components/MainHeading";
import CustomField from "../components/CustomField";
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import CheckBox from "../components/CheckBox";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const CustomPreview = ({
  totalCount,
  children,
  paginationDetail,
  rows,
  columns,
  handleCell,
  moduleHeading,
  searchLabel,
  handleBySearch,
  handleGetAll,
  isModal = false,
  loading,
  isByPatient = false,
  isExactMatch = false,
  showAll = false,
  showNew,
  navPath,
  isType = false,
  type,
}) => {
  // console.log(totalCount);

  let flage = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previewFormik = useFormik({
    initialValues: {
      showInActive: false,
      showAll: false,
      searchTerm: "",
      page: 0,
      pageSize: 1,
      isPatientSearch: true,
      exactMatch: false,
      type: 2,
    },
    onSubmit: (values) => {
      if (values.searchTerm) {
        dispatch(
          handleBySearch({
            ...values,
            page: previewFormik?.values?.page + 1,
          })
        );
        // console.log(rows);
      } else {
        // console.log(rows);
        dispatch(
          handleGetAll({
            page: values.page + 1,
            type: isType ? values.type : null,
            ...values,
          })
        );
      }
    },
  });
  const handleToggleChange = (event, newType) => {
    console.log(newType, "newType");
    previewFormik.setFieldValue("type", newType);
  };

  const handlePageChange = (newPage) => {
    console.log(newPage, "newPage")
    if (previewFormik?.values?.searchTerm) {
      console.log(previewFormik?.values?.searchTerm);

      dispatch(
        handleBySearch({
          ...previewFormik?.values,
          page: newPage + 1,
        })
      );
      previewFormik.setFieldValue("page", newPage);
    } else {
      // setPage(newPage);
      console.log(newPage);

      previewFormik.setFieldValue("page", newPage);
    }
  };

  // Perform initial fetch on component mount
  useEffect(() => {
    if (!flage) {
      flage = true;
      if (previewFormik.values.searchTerm) {
        dispatch(
          handleBySearch({
            ...previewFormik?.values,
            page:previewFormik.values.page + 1,
          })
        );
      } else {
        dispatch(
          handleGetAll({
            page: previewFormik.values.page + 1,
            type: isType ? previewFormik.values.type : null,
            ...previewFormik.values,
          })
        );
      }
    }
  }, [previewFormik.values.page, previewFormik.values.type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (previewFormik.values.searchTerm === "") {
          dispatch(
            handleGetAll({
              page: previewFormik.values.page + 1,
              type: isType ? previewFormik.values.type : null,
              ...previewFormik.values,
            })
          );
        } else {
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchData();
  }, [previewFormik.values.searchTerm, previewFormik.values.page]);

  return (
    <Box margin="15px">
      <Grid container rowSpacing={1}>
        {/* {!isModal && (
          <Grid item md={12} sm={12} xs={12}>
            <MainHeading mainheading={moduleHeading} />
          </Grid>
        )} */}

        {/* {children && (
          <Grid item md={12}>
            {children}
          </Grid>
        )} */}

        <Grid item md={12}>
          {isType && (
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <ToggleButtonGroup
                sx={{
                  "& .Mui-selected": {
                    color: "#fff !important",
                    background: "#1768C2 !important",
                  },
                }}
                value={previewFormik.values.type}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform"
              >
                <ToggleButton value={2}>Adjustment</ToggleButton>
                <ToggleButton value={1}>Remark</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          )}
        </Grid>

        <Grid item md={12}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {searchLabel && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  margin: "3px",
                }}
                component={"form"}
                onSubmit={previewFormik.handleSubmit}
              >
                <CustomField
                  type="text"
                  size="small"
                  label={searchLabel}
                  value={previewFormik.values.searchTerm}
                  name="searchTerm"
                  handleChange={previewFormik.handleChange}
                />
                <CustomButton
                  type="submit"
                  isBlue={true}
                  padding="5px 30px"
                  margin={"0 10px 0"}
                  isSearch={true}
                >
                  Search
                </CustomButton>
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {children && (
                // <Grid item md={12}>
                <Box>{children}</Box>
                // </Grid>
              )}
              {showNew && (
                <Box>
                  <CustomButton
                    type="button"
                    isBlue={true}
                    padding="5px 30px"
                    margin={"0 10px 0"}
                    isAdd={true}
                    handleClick={() => navigate(navPath)}
                  >
                    Add New
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item md={12}>
          <Stack direction="row" alignItems="center" gap="12px">
            {isByPatient && (
              <Box
                sx={{
                  borderRight: "2px solid grey",
                }}
              >
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        checked={previewFormik.values.isPatientSearch}
                        onChange={() =>
                          previewFormik.setValues((preVals) => ({
                            ...preVals,
                            isPatientSearch: true,
                          }))
                        }
                      />
                    }
                    label="Patient"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        checked={!previewFormik.values.isPatientSearch}
                        onChange={() =>
                          previewFormik.setValues((preVals) => ({
                            ...preVals,
                            isPatientSearch: false,
                          }))
                        }
                      />
                    }
                    label="Insured"
                  />
                </RadioGroup>
              </Box>
            )}
            {searchLabel && (
              <Box>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      showInActive: !previewFormik.values.showInActive,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.showInActive}
                  name="showInActive"
                  label={`Show inactive`}
                />
              </Box>
            )}
            {showAll && (
              <Box>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      showAll: !previewFormik.values.showAll,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.showAll}
                  name="showAll"
                  label={`Show All`}
                />
              </Box>
            )}

            {isExactMatch && (
              <Box item md={3}>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      exactMatch: !previewFormik.values.exactMatch,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.exactMatch}
                  label={`Show exact matches only`}
                />
              </Box>
            )}
          </Stack>
        </Grid>

        <Grid item md={12} xs={12} sm={12}>
          {loading ? (
            <Loading />
          ) : (
            <CustomDataGrid
              columns={columns}
              paginationDetail={paginationDetail}
              rows={rows}
              pageSize={previewFormik.values.pageSize}
              handleCellClick={handleCell}
              page={previewFormik.values.page}
              handlePageChange={handlePageChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomPreview;

// import React, { useEffect } from "react";
// import {
//   Box,
//   FormControlLabel,
//   Grid,
//   Radio,
//   RadioGroup,
//   Stack,
//   ToggleButton,
//   ToggleButtonGroup
// } from "@mui/material";
// import CustomDataGrid from "../components/CustomDataGrid";
// import CustomField from "../components/CustomField";
// import CustomButton from "../components/CustomButton";
// import { useDispatch } from "react-redux";
// import Loading from "../components/Loading";
// import CheckBox from "../components/CheckBox";
// import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";

// const CustomPreview = ({
//   children,
//   paginationDetail,
//   rows,
//   columns,
//   handleCell,
//   moduleHeading,
//   searchLabel,
//   handleBySearch,
//   handleGetAll,
//   isModal = false,
//   loading,
//   isByPatient = false,
//   isExactMatch = false,
//   showNew,
//   navPath,
//   isType = false,
// }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const previewFormik = useFormik({
//     initialValues: {
//       showInActive: false,
//       searchTerm: "",
//       page: 0,
//       pageSize: 1,
//       isPatientSearch: true,
//       exactMatch: false,
//       type: null,
//     },
//     onSubmit: (values) => {
//       console.log("value submit search", values);
//       if (values.searchTerm) {
//         dispatch(
//           handleBySearch({
//             ...values,
//             page: 1,
//           })
//         );
//       } else {
//         dispatch(handleGetAll(values.page + 1, values.type)); // Pass type here
//       }
//     },
//   });

//   const handleToggleChange = (event, newType) => {
//     previewFormik.setFieldValue("type", newType);
//   };

//   const handlePageChange = (newPage) => {
//     previewFormik.setFieldValue("page", newPage);
//   };

//   useEffect(() => {
//     dispatch(handleGetAll(previewFormik.values.page + 1, previewFormik.values.type)); // Fetch data on mount
//   }, [dispatch, previewFormik.values.page, previewFormik.values.type]);

//   useEffect(() => {
//     if (previewFormik.values.searchTerm === "") {
//       dispatch(handleGetAll(previewFormik.values.page + 1, previewFormik.values.type));
//     }
//   }, [previewFormik.values.searchTerm, previewFormik.values.page, dispatch, previewFormik.values.type]);

//   return (
//     <Box margin="15px">
//       <Grid container rowSpacing={1}>
//         <Grid item md={12}>
//           {isType && (
//             <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
//               <ToggleButtonGroup
//                 sx={{
//                   '& .Mui-selected': {
//                     color: "#fff !important",
//                     background: "#1768C2 !important",
//                   }
//                 }}
//                 value={previewFormik.values.type}
//                 exclusive
//                 onChange={handleToggleChange}
//                 aria-label="Type"
//               >
//                 <ToggleButton value={1}>Adjustment</ToggleButton>
//                 <ToggleButton value={2}>Remark</ToggleButton>
//               </ToggleButtonGroup>
//             </Stack>
//           )}
//         </Grid>

//         <Grid item md={12}>
//           <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "end",
//                 margin: "3px",
//               }}
//               component={"form"}
//               onSubmit={previewFormik.handleSubmit}
//             >
//               <CustomField
//                 type="text"
//                 size="small"
//                 label={searchLabel}
//                 value={previewFormik.values.searchTerm}
//                 name="searchTerm"
//                 handleChange={previewFormik.handleChange}
//               />
//               <CustomButton
//                 type="submit"
//                 isBlue={true}
//                 padding="5px 30px"
//                 margin={"0 10px 0"}
//                 isSearch={true}
//               >
//                 Search
//               </CustomButton>
//             </Box>
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               {children && <Box>{children}</Box>}
//               {showNew && (
//                 <Box>
//                   <CustomButton
//                     type="button"
//                     isBlue={true}
//                     padding="5px 30px"
//                     margin={"0 10px 0"}
//                     isAdd={true}
//                     handleClick={() => navigate(navPath)}
//                   >
//                     Add New
//                   </CustomButton>
//                 </Box>
//               )}
//             </Box>
//           </Stack>
//         </Grid>

//         <Grid item md={12}>
//           <Stack direction="row" alignItems="center" gap="12px">
//             {isByPatient && (
//               <Box sx={{ borderRight: "2px solid grey" }}>
//                 <RadioGroup row>
//                   <FormControlLabel
//                     value={true}
//                     control={
//                       <Radio
//                         checked={previewFormik.values.isPatientSearch}
//                         onChange={() =>
//                           previewFormik.setValues((preVals) => ({
//                             ...preVals,
//                             isPatientSearch: true,
//                           }))
//                         }
//                       />
//                     }
//                     label="Patient"
//                   />
//                   <FormControlLabel
//                     value={false}
//                     control={
//                       <Radio
//                         checked={!previewFormik.values.isPatientSearch}
//                         onChange={() =>
//                           previewFormik.setValues((preVals) => ({
//                             ...preVals,
//                             isPatientSearch: false,
//                           }))
//                         }
//                       />
//                     }
//                     label="Insured"
//                   />
//                 </RadioGroup>
//               </Box>
//             )}
//             <Box>
//               <CheckBox
//                 handleChange={() =>
//                   previewFormik.setValues((preVals) => ({
//                     ...preVals,
//                     showInActive: !previewFormik.values.showInActive,
//                   }))
//                 }
//                 CheckedValue={previewFormik?.values?.showInActive}
//                 name="showInActive"
//                 label={`Show inactive`}
//               />
//             </Box>
//             {isExactMatch && (
//               <Box item md={3}>
//                 <CheckBox
//                   handleChange={() =>
//                     previewFormik.setValues((preVals) => ({
//                       ...preVals,
//                       exactMatch: !previewFormik.values.exactMatch,
//                     }))
//                   }
//                   CheckedValue={previewFormik?.values?.exactMatch}
//                   label={`Show exact matches only`}
//                 />
//               </Box>
//             )}
//           </Stack>
//         </Grid>

//         <Grid item md={12} xs={12} sm={12}>
//           {loading ? (
//             <Loading />
//           ) : (
//             <CustomDataGrid
//               columns={columns}
//               paginationDetail={paginationDetail}
//               rows={rows}
//               pageSize={previewFormik.values.pageSize}
//               handleCellClick={handleCell}
//               page={previewFormik.values.page}
//               handlePageChange={handlePageChange}
//             />
//           )}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default CustomPreview;
