// import { Close, Warning } from "@mui/icons-material";
// import {
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Stack,
// } from "@mui/material";
// import React from "react";
// import CustomButton from "./CustomButton";

// const CustomDialog = ({
//   open,
//   handleClose,
//   title,
//   children,
//   isAction = false,
//   isWarning = false,
// }) => {
//   return (
//     <Dialog
//       onClose={handleClose}
//       aria-labelledby="custom-dialog"
//       open={open}
//       hideBackdrop
//       sx={{
//         position: "absolute",
//         transform: "translate(-50%, -50%)",
//         top: "50%",
//         left: "50%",
//         zIndex: 4445,
//       }}
//     >
//       {title && (
//         <DialogTitle sx={{ m: 0, p: 2 }} id="custom-dialog">
//           {title}
//         </DialogTitle>
//       )}
//       <IconButton
//         aria-label="close"
//         onClick={handleClose}
//         sx={{
//           position: "absolute",
//           right: 8,
//           top: 8,
//         }}
//       >
//         <Close />
//       </IconButton>
//       <DialogContent dividers>
//         <Box marginTop="35px">
//         <Stack direction="row" alignItems={"flex-start"} columnGap={"20px"}>
//           <div>{isWarning ? <Warning color="warning" fontSize="large" /> : null}</div>
//           {children}
//         </Stack>
//         </Box>
//       </DialogContent>
//       {isAction && (
//         <DialogActions>
//           <CustomButton isBlue={true} handleClick={handleClose}>
//             Save changes
//           </CustomButton>
//           <CustomButton handleClick={handleClose}>Save D</CustomButton>
//         </DialogActions>
//       )}
//     </Dialog>
//   );
// };

// export default CustomDialog;
import { Close, Warning } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import "./Custom-styling/customDialog.css"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const CustomDialog = ({
  open,
  handleClose,
  title,
  children,
  isWarning = false,
  handleConfirm,
  handleCancel,
  confirmButtonTitle,
  cancelButtonTitle,
  isCancel
  
}) => {
  return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="custom-dialog"
        open={open}
        hideBackdrop
        // sx={{
        //   height: "auto",
        
        // overflowY:"scroll",
        // '& .MuiDialog-container': {
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   },
        // '& .MuiPaper-root': {
        //   position: 'relative',
        //     margin: 0,
            
        //   },
        //   "& .css-1f7nymo-MuiModal-root-MuiDialog-root" :{
        //     zIndex: "6000 !important",
        //   },
        // }}
        sx={{
          position: "absolute", 
          zIndex: 9999,  
          height: "auto",
          overflowY: "scroll",
          '& .MuiDialog-container': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& .MuiPaper-root': {
            position: 'relative',
            margin: 0,
          },
        }}
      >
        {title && (
          <DialogTitle sx={{
            display:"flex",
            alignItems:"center",
            gap:"10px"
          }}  component={"h3"} id="custom-dialog">
           
            <Typography component={"span"}><Warning color="warning" fontSize="large" /></Typography>
            <Typography component={"h3"} fontWeight={600}>{title}</Typography>
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 8,
          }}
        >
          <Close fontSize="large" />
        </IconButton>
        <DialogContent dividers>
          <Box >
            <Stack direction="row" alignItems={"flex-start"} columnGap={"10px"}>
              {isWarning && <Warning color="warning" fontSize="large" />}
              {isCancel ?  <Typography component="h3" marginRight={"10px"}>Are you sure you want to cancel?</Typography> : children}        
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          {handleConfirm && (
            <CustomButton handleClick={handleConfirm} isBlue={true}>
              {confirmButtonTitle}
            </CustomButton>
          )}
       
        </DialogActions>
      </Dialog>
  );
};

export default CustomDialog;



