import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Grid, InputLabel, useTheme } from "@mui/material";

import CustomButton from "../../../components/CustomButton";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSearchField from "../../../components/CustomSearchField";
import { MultiSelect } from "react-multi-select-component";
import { FormControl } from "@mui/material";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import StMultiPayer from "./StMultiPayer";
import CustomPreview from "../../../layout/CustomPreview";
import CustomMultiSelect from "../../../components/CustomMultiSelect/CustomMultiSelect";
import { useSelector } from "react-redux";
import {
  facilityCols,
  patientCols,
  payerCol,
  procedureCodesCols,
  providerCols,
} from "../../../utils/gridCols";
import { getPayerAction } from "../../../features/actions/payerAction";
import {
  getPatientAction,
  getPatientBySearchTermAction,
} from "../../../features/actions/createPatientAction";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../../features/actions/facilityAction";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "../../../features/actions/procedureCodeAction";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";

const StatusControlInfo = ({ moduleName, formik }) => {
  const theme = useTheme()
  const [dosOpt, setDosOpt] = useState([]);
  const [payertypeOpt, setPayerTypeOpt] = useState([]);
  const [claimTypeOpt, setClaimTypeOpt] = useState([]);
  const [openPayerMod, setOpenPayerMod] = useState(false);
  const [claimStatusOpt, setClaimStatusOpt] = useState([]) 
  const [openPatientMod, setopenPatientMod] = useState(false);
  const [openFacilityMod, setOpenFacilityMod] = useState(false);
  const [openProcedureCodeMod, setOpenProcedureCodeMod] = useState(false);
  const [openProviderMod, setOpenProviderMod] = useState(false);
  // use controls
  const { getAllPayer } = useSelector((state) => state.payer);
  const { getAllPatients } = useSelector((state) => state.patient);
  const { getFacilityData } = useSelector((state) => state.facility);
  const { getProcedureCodes } = useSelector((state) => state.procedureCodes);
  const { getProviders } = useSelector((state) => state.provider);
  const payerLoading = useSelector((state) => state.payer.loading);
  const patientLoading = useSelector((state) => state.patient.loading);
  const facilityLoading = useSelector((state) => state.facility.loading);
  const procedureLoading = useSelector((state) => state.procedureCodes.loading);
  const providerLoading = useSelector((state) => state.provider.loading);
console.log(claimStatusOpt, "claimStatus")
  // fetch cts here
  const dataFetchUrl = {
    dos: "/ct-dos",
    payerType: "/ct-payerType",
    claimType: "/ct-claimTypes",
    claimStatus: `/ct-claimStatusModules/GetClaimStatusModulesByModuleName?ModuleName=${moduleName}`
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };
  useEffect(() => {
    fetchData(dataFetchUrl.dos, setDosOpt);
    fetchData(dataFetchUrl.payerType, setPayerTypeOpt);
    fetchData(dataFetchUrl.claimType, setClaimTypeOpt);
    fetchData(dataFetchUrl.claimStatus, setClaimStatusOpt);
  }, []);

  // handle select payer id
  const handleSelectPayer = (params) => {
    console.log(params, "checkParams");
    formik.setFieldValue("currentPayerSeqNum", params?.payerSequenceNo);
    formik.setFieldValue("currentPayerIdName", params?.payerName);
    setOpenPayerMod(false);
  };

  console.log(formik.values,"formik values")
  return (
    <>
     <Box
  component="form"
  sx={{
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0px 0px 20px 20px",
    borderBottom: `1px solid ${theme.palette.bottomBorder.primary}`,
  }}
  onSubmit={formik.handleSubmit}
>
  <CustomButton
    type="submit"
    children={"Search"}
    isSearch={true}
    isBlue={true}
  />
</Box>
<Box>
  <Grid container spacing={2} marginTop={"10px"} padding={"0px 20px 20px 20px"}>
    <Grid item  xs={12} sm={12} md={6}>
      <CustomSelectBox2
        name="claimstatusid"
        value={formik.values.claimstatusid}
        dropdownOptions={claimStatusOpt?.map((item) => ({
          id: item?.claimStatusId,
          value: item.claimStatusName,
        }))}
        label="Claim Status"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
        showDefaultOpt={true}
      />
    </Grid>

    <Grid item xs={12} sm={12} md={6} sx={{marginTop:'15px'}}>
      <Box>
      <CheckBox
        label="Include Deleted"
        // CheckedValue={formik.values.showLastPayment === true}
        // name="showLastPayment"
        // handleChange={() =>
        //   formik.setFieldValue(
        //     "showLastPayment",
        //     !formik.values.showLastPayment
        //   )
        // }
      />
      </Box>
    </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={3}
            // className="containerItem1"
            // sx={{
            //   display: { xs: "none", sm: "grid", md: "grid" },
            // }}
          >
            <CustomSelectBox2
              dropdownOptions={dosOpt?.map((el) => ({
                id: el?.dosId,
                value: el?.dos,
              }))}
              label="Entered Date"

              // handleChange={formik.handleChange}
              // handleBlur={formik.handleBlur}
              // formik={formik}
              // showDefaultOpt={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            // className="containerItem1"
            sx={{
              display: { xs: "none", sm: "grid", md: "grid" },
            }}
          >
            <CustomSelectBox2
              dropdownOptions={dosOpt?.map((el) => ({
                id: el?.dosId,
                value: el?.dos,
              }))}
              label="Date of Service"
              // handleChange={formik.handleChange}
              // handleBlur={formik.handleBlur}
              // formik={formik}
              // showDefaultOpt={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            // className="containerItem1"
            sx={{
              display: { xs: "none", sm: "grid", md: "grid" },
            }}
          >
            <CustomSelectBox2
              dropdownOptions={dosOpt?.map((el) => ({
                id: el?.dosId,
                value: el?.dos,
              }))}
              label="Last Submitted Date"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              // showDefaultOpt={true}
            />
          </Grid>

          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <CustomDatePicker />
          </Grid> */}
    <Grid item  xs={12} sm={12} md={6}>
      <CustomSearchField
        label="Current payer Id"
        handleModalOpen={() => setOpenPayerMod(true)}
        fieldVal={formik.values.currentPayerIdName}
        name="currentPayerIdName"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        resetField={{
          currentPayerIdName: "",
          currentPayerSeqNum: null,
        }}
        isRequired={true}
        formik={formik}
      />
    </Grid>
    <Grid item  xs={12} sm={12} md={6}>
      <CustomSearchField
        label="Patient"
        handleModalOpen={() => setopenPatientMod(true)}
        fieldVal={formik.values.patientName}
        name="patientName"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isRequired={true}
        formik={formik}
        resetField={{
          patientName: "",
          // patientAccountNo: null,
          patientId: null,

        }}
      />
    </Grid>

    <Grid item  xs={12} sm={12} md={6}>
      <CustomSearchField
        label="Rendering Provider"
        handleModalOpen={() => setOpenProviderMod(true)}
        fieldVal={formik.values.providerName}
        name="providerName"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isRequired={true}
        formik={formik}
        resetField={{
          providerName: "",
        }}
      />
    </Grid>
    <Grid item  xs={12} sm={12} md={6}>
      <CustomSearchField
        label="Facility"
        handleModalOpen={() => setOpenFacilityMod(true)}
        isRequired={true}
        formik={formik}
        resetField={{
          facilityName: "",
        }}
        fieldVal={formik.values.facilityName}
        name="facilityName"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />
    </Grid>

    <Grid item  xs={12} sm={12} md={12}>
      <CustomSearchField
        label="Procedure Code"
        handleModalOpen={() => setOpenProcedureCodeMod(true)}
        fieldVal={formik.values.procedureCode}
        name="procedureCode"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        resetField={{
          procedureCode: null,
        }}
        isRequired={true}
        formik={formik}
      />
    </Grid>

    <Grid item  xs={12} sm={12} md={12}>
      <FormControl fullWidth>
        <label className="customLabel" style={{ color: theme.palette.Labeltext.primary }}>Claim Type</label>
        {/* <MultiSelect
          options={claimTypeOpt?.map((el) => ({
            label: el?.claimType,
            value: el?.claimTypeId,
          }))}
          value={formik.values.claimTypeId}
          onChange={(selectedOption) => {
            formik.setFieldValue("claimTypeId", selectedOption);
          }}
          style={{
            control: (base) => ({
              ...base,
              backgroundColor: "red",
            }),
            option: (provided) => ({
              ...provided,
              color: "blue",
            }),
          }}
        /> */}
         <CustomMultiSelect
            className="custom-multi-select"
            options={claimTypeOpt?.map((el) => ({
              label: el?.claimType,
              value: el?.claimTypeId,
            }))}
            value={formik.values.claimTypeId}
            handleChange={(selectedOption) => {
              formik.setFieldValue("claimTypeId", selectedOption);
            }}
           handleSelectAll={true}
         />
        
      </FormControl>
    </Grid>

    <Grid item  xs={12} sm={12} md={12}>
      <FormControl fullWidth>
        <label className="customLabel" style={{ color: theme.palette.Labeltext.primary }}>Payer Type</label>
        {/* <MultiSelect
          options={payertypeOpt?.map((el) => ({
            label: el?.payerType,
            value: el?.payerTypeId,
          }))}
          value={formik.values.currentPayerTypeId}
          onChange={(selectedOption) => {
            formik.setFieldValue("currentPayerTypeId", selectedOption);
          }}
          style={{
            control: (base) => ({
              ...base,
              backgroundColor: "red",
            }),
            option: (provided) => ({
              ...provided,
              color: "blue",
            }),
          }}
        /> */}
        <CustomMultiSelect
            className="custom-multi-select"
            options={payertypeOpt?.map((el) => ({
              label: el?.payerType,
              value: el?.payerTypeId,
            }))}
            value={formik.values.currentPayerTypeId}
            handleChange={(selectedOption) => {
              formik.setFieldValue("currentPayerTypeId", selectedOption);
            }}
           handleSelectAll={true}
         />
      </FormControl>
    </Grid>
  </Grid>
</Box>



      {/* custom modals */}
      <CustomModal
        open={openPayerMod}
        heading={"Payers"}
        handleClose={() => setOpenPayerMod(false)}
      >
        {/* <StMultiPayer handleClose={() => setOpenPayerMod(false)} /> */}
        <CustomPreview
          isModal={true}
          loading={payerLoading}
          rows={
            getAllPayer && getAllPayer?.result?.payers?.length > 0
              ? getAllPayer?.result?.payers?.map((el) => ({
                  id: el?.payerId,
                  ...el,
                }))
              : []
          }
          columns={payerCol}
          handleGetAll={getPayerAction}
          searchLabel="Search"
          handleCell={handleSelectPayer}
        />
      </CustomModal>
      {/* patient modal */}
      <CustomModal
        open={openPatientMod}
        heading="Patients"
        handleClose={() => setopenPatientMod(false)}
      >
        <CustomPreview
          loading={patientLoading}
          rows={
            getAllPatients?.result?.patients?.length > 0
              ? getAllPatients?.result?.patients?.map((el) => ({
                  // id: el?.accountNo,
                  id: el?.patientId,
                  ...el,
                }))
              : []
          }
          paginationDetail={getAllPatients}
          isModal={true}
          columns={patientCols}
          handleGetAll={getPatientAction}
          handleBySearch={getPatientBySearchTermAction}
          searchLabel="Search by Account #"
          handleCell={(params) => {
            // formik.setFieldValue("patientAccountNo", params?.accountNo);
            formik.setFieldValue("patientAccountNo", params?.patientId);
            formik.setFieldValue("patientName", params?.patientName);
            setopenPatientMod(false);
          }}
        />
      </CustomModal>
      {/* facility modal */}
      <CustomModal
        open={openFacilityMod}
        heading="Facility"
        handleClose={() => setOpenFacilityMod(false)}
      >
        <CustomPreview
          loading={facilityLoading}
          rows={
            getFacilityData?.result?.facility?.length > 0
              ? getFacilityData?.result?.facility?.map((el) => ({
                  id: el?.facilityId,
                  ...el,
                }))
              : []
          }
          paginationDetail={getFacilityData}
          isModal={true}
          columns={facilityCols}
          handleGetAll={getFacilityAction}
          handleBySearch={getFacilityBySearchTermAction}
          searchLabel="Search by First Name, Phone ,or Sequence Number"
          handleCell={(params) => {
            formik.setFieldValue("facilityID", params?.facilityId);
            formik.setFieldValue("facilityName", params?.facilityName);
            setOpenFacilityMod(false);
          }}
        />
      </CustomModal>
      {/* procedure modal */}
      <CustomModal
        open={openProcedureCodeMod}
        heading="Procedure Codes"
        handleClose={() => setOpenProcedureCodeMod(false)}
      >
        <CustomPreview
          loading={procedureLoading}
          rows={
            getProcedureCodes?.length > 0
              ? getProcedureCodes?.map((el) => ({
                  id: el?.procedureCodeId,
                  ...el,
                }))
              : []
          }
          isModal={true}
          columns={procedureCodesCols}
          handleGetAll={getProcedureCodeAction}
          handleBySearch={getProcedureCodesBySearchTermAction}
          searchLabel="Search for CPT/HCPCS codes by code or description"
          handleCell={(params) => {
            formik.setFieldValue("procedureCode", params?.procedureCode);
            setOpenProcedureCodeMod(false);
          }}
        />
      </CustomModal>
      {/* rendering provider modal */}
      <CustomModal
        open={openProviderMod}
        heading="Rendering Providers"
        handleClose={() => setOpenProviderMod(false)}
      >
        <CustomPreview
          paginationDetail={getProviders}
          loading={providerLoading}
          rows={
            getProviders?.result?.providers?.length > 0
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          isModal={true}
          columns={providerCols}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search by Provider Name and Sequence No"
          handleCell={(params) => {
            formik.setFieldValue("renderingProviderId", params?.providerId);
            formik.setFieldValue(
              "providerName",
              `${params?.providerFirstName} ${params?.providerLastName || ""}`
            );
            setOpenProviderMod(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default StatusControlInfo;