import { Box, Stack, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setClaimType } from "../../../features/slice/claimSlice";
import { useState } from "react";
import {
  getClaimAction,
  getClaimBySearchTermAction,
} from "../../../features/actions/claimAction";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomPreview from "../../../layout/CustomPreview";
import { claimCols } from "../../../utils/gridCols";

const Claim = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { getClaims, loading } = useSelector((state) => state.claim);
  const { claimType } = useSelector((state) => state.claim);
  const rows =
    getClaims && getClaims.result?.claims.length > 0
      ? getClaims.result?.claims.map((el) => ({
          // id: el.claimNumber,
          id: el.claimId,
          fromDate: el?.getClaimChargesDto?.fromDate,
          ...el,
        }))
      : [];

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  const handleSelectOption = (type) => {
    dispatch(setClaimType(type));
    navigate("/claims/new");
  };

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={claimCols}
        paginationDetail={getClaims}
        searchLabel="Search by Claim Id,Patient Name,Dos"
        moduleHeading="All Claims"
        loading={loading}
        handleCell={(params) =>
          // navigate(`/claims/update/${params.claimNumber}`)
          navigate(`/claims/update/${params.claimId}`)
        }
        handleGetAll={getClaimAction}
        handleBySearch={getClaimBySearchTermAction}
        isExactMatch={true}
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={handleClickButton}
            isUser={true}
          >
            Add Claims
          </CustomButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClosePopup}
          >
            <MenuItem
              sx={{ fontSize: "1.5rem", width: "125px" }}
              onClick={() => handleSelectOption(1)}
            >
              Professional
            </MenuItem>
            {/* <MenuItem
              sx={{ fontSize: "1.5rem" }}
              onClick={() => handleSelectOption(2)}
            >
              Institutional
            </MenuItem> */}
          </Menu>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Claim;
