import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPracticeListAction,
  newPracticeAction,
} from "../../../features/actions/practiceAction";
import PracticeInfo from "./PracticeInfo";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";

const NewPractice = () => {
  let flag = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: practiceInitVals,
    validationSchema: practiceSchema,
    onSubmit: (values, action) => {
      try {
        if (!flag) {
          flag = true;
          dispatch(newPracticeAction(values));
          dispatch(getPracticeListAction());
          navigate("/practice");
        }
      } catch (error) {
        console.error("Error creating patient:", error);
      }
      action.resetForm();
    },
  });

  return (
    <>
      <PracticeInfo formik={formik} />
    </>
  );
};

export default NewPractice;
