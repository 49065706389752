import { createSlice } from "@reduxjs/toolkit";
import {
  createPaymentAction,
  getAllPayment,
  getAllPaymentBySearchQuery,
} from "../actions/PaymentAction";
import { toast } from "react-toastify";
const initialState = {
  paymentData: {},
  loading: false,
  error: null,
  selectedClaim: [],
  getAllPayments: [],
  paymentDataForApi: {},
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {
    addSelectedClaim: (state, action) => {
      console.log(action.payload, "payment data");
      // state.selectedClaim.push(action.payload);
      state.selectedClaim = action.payload;
    },
    setPaymentDataForApi: (state, action) => {
      state.paymentDataForApi = action.payload;
    },
  },
  extraReducers: {
    [createPaymentAction.pending]: (state) => {
      state.loading = true;
    },
    [createPaymentAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentData = action.payload;
      // toast.success("Payment has been created Successfully!");
    },
    [createPaymentAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      toast.error("Payment creation failed");
    },
    [getAllPayment.pending]: (state) => {
      state.loading = true;
    },
    [getAllPayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllPayments = action.payload;
    },
    [getAllPayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllPaymentBySearchQuery.pending]: (state) => {
      state.loading = true;
    },
    [getAllPaymentBySearchQuery.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllPayments = action.payload;
    },
    [getAllPaymentBySearchQuery.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { addSelectedClaim, setPaymentDataForApi } = paymentSlice.actions;
export default paymentSlice.reducer;
