import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import {
  postPayDetailInsCols,
  postPayDetailPatientCols,
} from "../../utils/paymentCols";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CustomModal from "../../components/CustomModal";
import EditPayDetail from "./EditPayDetail";
import CustomButton from "../../components/CustomButton";

const PostPayDetail = ({ formik, selectedClaim, setShowDetail }) => {
  const theme = useTheme();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [rows, setRows] = useState(
    selectedClaim?._PaymentDetailDto?.map((el) => ({
      id: el?.procedureCode,
      ...el,
    })) || []
  );

  const handleSave = (updatedData) => {
    const updatedRows = rows.map((row) =>
      row.procedureCode === updatedData.procedureCode
        ? { ...row, ...updatedData }
        : row
    );
    setRows(updatedRows);
  };
  console.log(rows, "rows");
  // handle done
  const handleDone = () => {
    setOpenEditModal(false);
    const updatedPaymentClaim = formik.values._PaymentClaimDto?.map((claim) =>
      claim.claimId === selectedClaim.claimId
        ? { ...claim, _PaymentDetailDto: rows }
        : claim
    );
    // console.log(updatedPaymentClaim, "updatedPaymentClaim");
    formik.setFieldValue("_PaymentClaimDto", updatedPaymentClaim);
    setShowDetail(false);
  };
  console.log(formik.values._PaymentClaimDto, "formik.values._PaymentClaimDto");
  return (
    <div>
      <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
        <CustomButton handleClick={handleDone} isBlue={true} isSave={true}>
          Done
        </CustomButton>
        <CustomButton handleClick={() => {
          formik.setValues(preVals => ({...preVals, _PaymentClaimDto: [] }));
          setShowDetail(false)}} isBlue={false} isCancel={true}>
          Cancel
        </CustomButton>
      </Stack>

      <Stack>
        <Stack margin="20px 0 10px">
          <Typography component="h4" sx={{ fontWeight: 500 }}>
            Payment -{" "}
            {formik.values.paymentMethodId === 1
              ? "Check"
              : formik.values.paymentMethodId === 3
              ? "Card"
              : "EFT"}{" "}
            from{" "}
            <span style={{ color: "blue" }}>
              {formik.values.paymentByTypeId === 1
                ? `${formik.values.patientFirstName} ${formik.values.patientLastName} (${formik.values.patientId})`
                : `${formik.values.insuranceDescription} (${formik.values.insuranceDescriptionId})`}
            </span>{" "}
            received on {formik.values.receiveDate}
          </Typography>
        </Stack>
        <Stack marginBottom={"10px"}>
          <Typography component="h4" sx={{ fontWeight: 500 }}>
            Claim #{" "}
            <span style={{ color: "blue" }}>{selectedClaim.claimId}</span>
          </Typography>
        </Stack>
      </Stack>
      <DataGrid
        rows={rows}
        columns={
          // formik.values.paymentByTypeId !== 1
          //   ? patientClaimCharges
          //   : patientColumns
          [
            {
              field: "action",
              headerName: "Edit",
              flex: 1,
              minWidth: 100,
              headerAlign: "center",
              align: "center",
              headerClassName: "header-bg",
              cellClassName: "cell-content",
              renderCell: (params) => (
                <Button
                disabled={params.row.startBalance === 0}
                  variant="outlined"
                  onClick={() => {
                    setSelectedProcedure(params.row);
                    setOpenEditModal(true);
                  }}
                >
                  <Edit />
                </Button>
              ),
            },
            ...(formik.values.paymentByTypeId === 1
              ? postPayDetailPatientCols
              : postPayDetailInsCols),
          ]
        }
        sx={{
          "& .header-bg": {
            background: theme.palette.dataGridHeading.primary,
            color: theme.palette.textMainHeading.primary,

            fontSize: "14px",
          },
          "& .cell-content": {
            fontSize: "12.5px",
          },
        }}
        autoHeight
        disableSelectionOnClick
      />

      {/* custom modal */}
      <CustomModal
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
      >
        <EditPayDetail
          formik={formik}
          selectedProcedure={selectedProcedure}
          onSave={handleSave}
          setRows={setRows}
          handleClose={() => setOpenEditModal(false)}
        />
      </CustomModal>
    </div>
  );
};

export default PostPayDetail;
