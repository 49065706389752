import { AccordionDetails, Box, Grid, Stack } from "@mui/material";
import "./PatientInfo.css";
// import { newPatientAction } from "../../features/actions/createPatientAction";
import { getData } from "../../config/axiosFunctions";
import { useEffect, useState } from "react";
import path from "../../config/apiUrl";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import CustomExpandIcon from "../../components/CustomExpandIcon";
import CustomAccordion from "../../components/CustomAccordion";
import CustomModal from "../../components/CustomModal";
// import Loading from "../../components/Loading";
import TextArea from "../../components/TextArea";
import CustomAddress from "../../components/CustomAddress";
// import CustomButton from "../../components/CustomButton";
import CustomPreview from "../../layout/CustomPreview";
import {
  getGuarantorAction,
  getGuarantorBySearchTermAction,
} from "../../features/actions/guarantorAction";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
import { emplyerCols, guarantorCols } from "../../utils/gridCols";
import {
  getEmployerAction,
  getEmployerBySearchTermAction,
} from "../../features/actions/employerAction";
// import { createPatientSchema } from "../../schemas";

const PatientInfo = ({ formik }) => {
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [maritalOptions, setMaritalOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [sexOrientationOptions, setSexOrientationOptions] = useState([]);
  const [employeementOptions, setEmployeementOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [patienRelationOpt, setPatientRelationOpt] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [studentStatusOpt, setStudentStatusOpt] = useState([]);
  const [residenceOptions, setResidenceOptions] = useState([]);
  const [openGuarantorMod, setOpenGuarantorMod] = useState(false);
  const [openEmployerMod, setOpenEmployerMod] = useState(false);
  const [removeValues, setRemoveValues] = useState({
    guarantorRemove: false,
    employerRemove: false,
  });
  // const [openDefProvider, setOpenDefProvider] = useState(false);
  const { getGuarantorData, loading } = useSelector((state) => state.guarantor);
  const { getEmployerData } = useSelector((state) => state.employer);
  const employerLoading = useSelector((state) => state.employer.loading);
  // const dispatch = useDispatch();
  // Define data fetching URLs
  const dataFetchUrls = {
    accountType: `${path}/ct-accountType`,
    genderTypes: `${path}/ct-genderIdentity`,
    maritalStatus: `${path}/ct-maritalStatus`,
    raceStatus: `${path}/ct-raceStatus`,
    sexualOrientation: `${path}/ct-sexualorientatioStatus`,
    employmentStatus: `${path}/ct-employmentStatus`,
    referralSource: `${path}/ct-referralSource`,
    relationToPatient: `${path}/ct-relationToPatient`,
    ethnicityStatus: `${path}/ct-ethnicityStatus`,
    studentStatus: `${path}/ct-studentStatus`,
    residenceTypes: `${path}/ct-residenceType`,
    provider: `${path}/provider`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.accountType, setAccountTypeOptions);
    fetchDataOptions(dataFetchUrls.employmentStatus, setEmployeementOptions);
    fetchDataOptions(dataFetchUrls.ethnicityStatus, setEthnicityOptions);
    fetchDataOptions(dataFetchUrls.genderTypes, setGenderOptions);
    fetchDataOptions(dataFetchUrls.maritalStatus, setMaritalOptions);
    fetchDataOptions(dataFetchUrls.raceStatus, setRaceOptions);
    fetchDataOptions(dataFetchUrls.referralSource, setReferenceOptions);
    fetchDataOptions(dataFetchUrls.relationToPatient, setPatientRelationOpt);
    fetchDataOptions(dataFetchUrls.residenceTypes, setResidenceOptions);
    fetchDataOptions(dataFetchUrls.sexualOrientation, setSexOrientationOptions);
    fetchDataOptions(dataFetchUrls.studentStatus, setStudentStatusOpt);
  }, []);

  useEffect(() => {
    try {
      // dispatch(getGuarantorAction());
    } catch (e) {
      console.log(e, ": Error in getting guarantor action");
    }
  }, []);
  // select guarantor
  const handleSelectGuarantor = (params) => {
    formik.setValues((preVals) => ({
      ...preVals,
      guarantorDto: {
        ...params,
      },
    }));
    setRemoveValues((preVals) => ({
      ...preVals,
      guarantorRemove: true,
    }));
    setOpenGuarantorMod(false);
  };
  // select employer
  const handleSelectEmployer = (params) => {
    formik.setValues((preVals) => ({
      ...preVals,
      employerDto: {
        ...params,
      },
    }));
    setRemoveValues((preVals) => ({
      ...preVals,
      employerRemove: true,
    }));
    setOpenEmployerMod(false);
  };

  console.log(formik.values, "formikPatientValues");
  console.log(formik.errors, "formikPatientValues");
  return (
    <>
      <Box display="flex" flexDirection="column">
        {/* general Info */}
        <CustomAccordion defaultExpanded={true} heading={"General Information"}>
          <AccordionDetails>
            <Grid container spacing={2} rowGap={2}>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  // label="Account #"
                  label="Patient Id"
                  disable={true}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  // value={formik?.values?.accountNo}
                  value={formik?.values?.patientId}
                  // name="accountNo"
                  name="patientId"
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.accountTypeId}
                  name="accountTypeId"
                  dropdownOptions={accountTypeOptions?.map((opt) => ({
                    value: opt.accountType,
                    id: opt.accountTypeId,
                  }))}
                  label="Account Type"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  showDefaultOpt={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  label="Reference #"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik?.values?.referenceNo}
                  name="referenceNo"
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  label="SSN"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik?.values?.ssn}
                  name="ssn"
                  mask="999-99-9999"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} rowGap={2} marginTop="15px">
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="firstName"
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.firstName}
                  label="First Name"
                  pattern={/^[A-Za-z]+$/}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="lastName"
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.lastName}
                  label="Last Name"
                  pattern={/^[A-Za-z]+$/}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="mi"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.mi}
                  label="MI"
                  message={formik?.errors.mi}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="suffix"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.suffix}
                  label="Suffix"
                  pattern={/^[A-Za-z]+$/}
                />
              </Grid>

              <Grid item md={3}>
                <CustomSelectBox2
                  value={formik.values?.genderIdentityStatusId}
                  name="genderIdentityStatusId"
                  dropdownOptions={genderOptions?.map((opt) => ({
                    value: opt.genderIdentityName,
                    id: opt.genderIdentityId,
                  }))}
                  label="Gender"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  isRequired={true}
                  isSetName={true}
                  setValName={"genderIdentityStatusName"}
                />
              </Grid>
              <Grid item md={3}>
                <CustomDatePicker
                  dateLabel="Date Of Birth"
                  name="dateOfBirth"
                  dateValue={formik.values?.dateOfBirth}
                  handleDateChange={(date) => {
                    formik.handleChange("dateOfBirth")(date);
                  }}
                  formik={formik}
                  // handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                  isRequired={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="email"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.email}
                  label="Email"
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  name="maritalStatusId"
                  value={formik.values?.maritalStatusId}
                  dropdownOptions={maritalOptions?.map((opt) => ({
                    value: opt.maritalStatusName,
                    id: opt.maritalStatusId,
                  }))}
                  label="Marital Status"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  showDefaultOpt={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="cellPhone"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.cellPhone}
                  label="Cell Number"
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="homePhone"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.homePhone}
                  label="Home Number"
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="workPhone"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.workPhone}
                  label="Work Number"
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  name="ext"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.ext}
                  label="Ext"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </CustomAccordion>

        {/* addres details */}
        <CustomAccordion
          defaultExpanded={false}
          heading="Address Details"
          hasErrors={
            formik.submitCount > 0 &&
            !!formik.errors.address &&
            !!formik.errors.cityId &&
            !!formik.errors.stateId &&
            !!formik.errors.countryId &&
            !!formik.errors.zipCode
          }
        >
          <AccordionDetails>
            <Grid container spacing={2} rowGap={2}>
              <Grid item md={3}>
                {/* street line address */}
                <CustomField
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  type="text"
                  value={formik.values?.address}
                  name="address"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  label="Street Line Address"
                />
              </Grid>
              <Grid item md={3}>
                {/*  residence Type*/}
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.residenceTypeId}
                  name="residenceTypeId"
                  dropdownOptions={residenceOptions?.map((opt) => ({
                    value: opt.residenceTypeName,
                    id: opt.residenceTypeId,
                  }))}
                  label="Residence Type"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  showDefaultOpt={true}
                />
              </Grid>
              <CustomAddress
                formik={formik}
                fieldValues={{
                  countryId: formik.values.countryId,
                  country: formik.values.countryName,
                  countryName: "countryName",
                  stateId: "stateId",
                  stateName: "stateName",
                  state: formik.values.stateName,
                  cityId: "cityId",
                  cityName: "cityName",
                  city: formik.values.cityName,
                  zipCode: formik.values.zipCode,
                  zipCodeId: "zipCodeId",
                  zipName: "zipCode",
                }}
                isRequired={true}
              />
            </Grid>
          </AccordionDetails>
        </CustomAccordion>

        {/* guarantor */}
        <CustomAccordion
          heading={"Guarantor"}
          isAdditional={true}
          isRemove={removeValues.guarantorRemove}
          handleRemoveData={() => {
            formik.setValues((preVals) => ({
              ...preVals,
              guarantorDto: {
                guarantorFirstName: "",
                guarantorLastName: "",
                guarantorMI: "",
                guarantorDOB: null,
                guarantorSSN: "",
                guarantorAddress: "",
                relationshipToPatientId: 1,
                relationshipToPatientName: "",
                guarantorCountryId: 1,
                guarantorCountryName: "",
                guarantorStateId: 0,
                guarantorStateName: "",
                guarantorCityId: 0,
                guarantorCityName: "",
                guarantorZipCodeId: 0,
                guarantorZipCode: "",
                guarantorHomePhone: "",
                guarantorCellPhone: "",
                guarantorWorkPhone: "",
                guarantorEmail: "",
                guarantorRemarks: "",
              },
            }));
            setRemoveValues((preVals) => ({
              ...preVals,
              guarantorRemove: false,
            }));
          }}
          handleSearch={() => setOpenGuarantorMod(true)}
        >
          <AccordionDetails>
            <Grid container spacing={2} rowGap={2}>
              <Grid item md={4}>
                <Stack>
                  <CustomSelectBox2
                    value={formik.values?.guarantorDto?.relationshipToPatientId}
                    name="guarantorDto.relationshipToPatientId"
                    dropdownOptions={patienRelationOpt?.map((opt) => ({
                      value: opt.relationShipToPatientName,
                      id: opt.relationShipToPatientId,
                    }))}
                    label="Relation to Patient"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    formik={formik}
                    showDefaultOpt={true}
                  />
                </Stack>
              </Grid>

              <Grid item md={3}>
                <CustomField
                  label="Last Name"
                  value={formik.values.guarantorDto?.guarantorLastName}
                  name="guarantorDto.guarantorLastName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item md={3}>
                <CustomField
                  label="First Name"
                  value={formik.values.guarantorDto?.guarantorFirstName}
                  name="guarantorDto.guarantorFirstName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item md={2}>
                <CustomField
                  label="MI"
                  value={formik.values.guarantorDto?.guarantorMI}
                  name="guarantorDto.guarantorMI"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  max={"1"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} rowGap={2} marginTop="15px">
              <Grid item md={3}>
                <CustomDatePicker
                  dateLabel="Date of Birth"
                  name="guarantorDto.guarantorDOB"
                  dateValue={formik.values.guarantorDto?.guarantorDOB}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                  formik={formik}
              
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  label="SSN"
                  type="text"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.guarantorDto?.guarantorSSN}
                  name="guarantorDto.guarantorSSN"
                  mask="999-99-9999"
                />
              </Grid>

              <Grid item md={6}>
                <CustomField
                  label="Address"
                  value={formik.values.guarantorDto?.guarantorAddress}
                  name="guarantorDto.guarantorAddress"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} rowGap={2} marginTop="15px">
              <CustomAddress
                fieldValues={{
                  stateId: "guarantorDto.guarantorStateId",
                  stateName: "guarantorDto.guarantorStateName",
                  state: formik.values.guarantorDto?.guarantorStateName,
                  cityId: "guarantorDto.guarantorCityId",
                  cityName: "guarantorDto.guarantorCityName",
                  city: formik.values?.guarantorDto?.guarantorCityName,
                  zipCode: formik.values?.guarantorDto?.guarantorZipCode,
                  zipCodeId: "guarantorDto.guarantorZipCodeId",
                  zipName: "guarantorDto.guarantorZipCode",
                }}
                formik={formik}
              />
              <Grid item md={4}>
                <CustomField
                  label="Home Phone"
                  type="text"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.guarantorDto?.guarantorHomePhone}
                  name="guarantorDto.guarantorHomePhone"
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  label="Cell Phone"
                  type="text"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values?.guarantorDto?.guarantorCellPhone}
                  name="guarantorDto.guarantorCellPhone"
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  label="Work Phone"
                  type="text"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.guarantorDto?.guarantorWorkPhone}
                  name="guarantorDto.guarantorWorkPhone"
                  mask="(999) 999-9999"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} rowGap={2} marginTop="15px">
              <Grid item md={4}>
                <CustomField
                  type="email"
                  value={formik.values.guarantorDto?.guarantorEmail}
                  name="guarantorDto.guarantorEmail"
                  label="Email"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={8}>
                <CustomField
                  type="text"
                  value={formik.values.guarantorDto?.guarantorRemarks}
                  name="guarantorDto.guarantorRemarks"
                  label="Remarks"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </CustomAccordion>

        {/* employer */}
        <CustomAccordion
          heading="Employer"
          isAdditional={true}
          isRemove={removeValues.employerRemove}
          handleRemoveData={() => {
            formik.setValues((preVals) => ({
              ...preVals,
              employerDto: {
                employerName: "",
                employerAddress: "",
                countryId: 1,
                countryName: "",
                employerStateId: 0,
                employerStateName: "",
                employerCityId: 0,
                employerCityName: "",
                employerZipCodeId: 0,
                employerZipCode: "",
              },
            }));
            setRemoveValues((preVals) => ({
              ...preVals,
              employerRemove: false,
            }));
          }}
          handleSearch={() => setOpenEmployerMod(true)}
        >
          <AccordionDetails>
            <Grid container spacing={2} rowGap={2}>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  label="Employer Name"
                  value={formik.values.employerDto?.employerName}
                  name="employerDto.employerName"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  label="Address"
                  value={formik.values.employerDto?.employerAddress}
                  name="employerDto.employerAddress"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <CustomAddress
                fieldValues={{
                  stateId: "employerDto.employerStateId",
                  stateName: "employerDto.employerStateName",
                  state: formik.values?.employerDto?.employerStateName,
                  cityId: "employerDto.employerCityId",
                  cityName: "employerDto.employerCityName",
                  city: formik.values?.employerDto?.employerCityName,
                  zipCode: formik.values?.employerDto?.employerZipCode,
                  zipCodeId: "employerDto.employerZipCodeId",
                  zipName: "employerDto.employerZipCode",
                }}
                formik={formik}
              />
            </Grid>
          </AccordionDetails>
        </CustomAccordion>

        {/* emergency contact */}
        <CustomAccordion defaultExpanded={false} heading="Emergency Contact">
          <AccordionDetails>
            <Grid container spacing={2} rowGap={2}>
              <Grid item md={3}>
                {/* relation to patient */}
                <CustomSelectBox2
                  formik={formik}
                  value={
                    formik.values?.emergencyContact?.relationshipToPatientId
                  }
                  name="emergencyContact.relationshipToPatientId"
                  dropdownOptions={patienRelationOpt?.map((opt) => ({
                    value: opt.relationShipToPatientName,
                    id: opt.relationShipToPatientId,
                  }))}
                  label="Relation to Patient"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                {/* first name */}
                <CustomField
                  type="text"
                  value={
                    formik.values?.emergencyContact?.emergencyContactFirstName
                  }
                  name="emergencyContact.emergencyContactFirstName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  label="First Name"
                />
              </Grid>
              <Grid item md={3}>
                {/* last name */}
                <CustomField
                  type="text"
                  value={
                    formik.values?.emergencyContact?.emergencyContactLastName
                  }
                  name="emergencyContact.emergencyContactLastName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  label="Last Name"
                />
              </Grid>
              <Grid item md={3}>
                {/* address */}
                <CustomField
                  type="text"
                  value={
                    formik.values?.emergencyContact?.emergencyContactAddress
                  }
                  name="emergencyContact.emergencyContactAddress"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  label="Address"
                />
              </Grid>

              <CustomAddress
                fieldValues={{
                  stateId: "emergencyContact.emergencyContactStateId",
                  stateName: "emergencyContact.emergencyContactStateName",
                  state:
                    formik.values.emergencyContact?.emergencyContactStateName,
                  cityId: "emergencyContact.emergencyContactCityId",
                  cityName: "emergencyContact.emergencyContactCityName",
                  city: formik.values.emergencyContact
                    ?.emergencyContactCityName,
                  zipCode:
                    formik.values.emergencyContact?.emergencyContactZipCode,
                  zipCodeId: "emergencyContact.emergencyContactZipCodeId",
                  zipName: "emergencyContact.emergencyContactZipCode",
                }}
                formik={formik}
              />
            </Grid>
          </AccordionDetails>
        </CustomAccordion>

        {/* Other */}
        <CustomAccordion defaultExpanded={false} heading="Other">
          <AccordionDetails>
            <Grid container spacing={3} rowGap={2}>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="drivingLicense"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isOutlined={false}
                  value={formik.values?.drivingLicense}
                  label="Driving License"
                />
              </Grid>
              {/* employeement status */}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.employmentStatusId}
                  name="employmentStatusId"
                  dropdownOptions={employeementOptions?.map((opt) => ({
                    value: opt.employmentStatusName,
                    id: opt.employmentStatusId,
                  }))}
                  label="Employment Status"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  showDefaultOpt={true}
                />
              </Grid>
              {/* Race Status */}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.raceStatusId}
                  name="raceStatusId"
                  dropdownOptions={raceOptions?.map((opt) => ({
                    value: opt.raceStatusName,
                    id: opt.raceStatusId,
                  }))}
                  label="Race Status"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              {/* Sex Orientation */}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  name="sexualOrientationId"
                  value={formik.values?.sexualOrientationId}
                  dropdownOptions={sexOrientationOptions?.map((opt) => ({
                    value: opt.sexualOrientationName,
                    id: opt.sexualOrientationId,
                  }))}
                  label="Sex Orientation"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              {/* Referral Source */}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.referralSourceId}
                  name="referralSourceId"
                  dropdownOptions={referenceOptions?.map((opt) => ({
                    value: opt.referralSourceName,
                    id: opt.referralSourceId,
                  }))}
                  label="Referral Source"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              {/* Date of death */}
              <Grid item md={3}>
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Date Of Death"
                  name="dateOfDeath"
                  dateValue={formik.values?.dateOfDeath}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </Grid>
              {/* Ethnicity Status*/}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.ethnicityId}
                  name="ethnicityId"
                  dropdownOptions={ethnicityOptions?.map((opt) => ({
                    value: opt.ethnicityName,
                    id: opt.ethnicityId,
                  }))}
                  label="Ethnicity Status"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              {/* student Status*/}
              <Grid item md={3}>
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.studentStatusId}
                  name="studentStatusId"
                  dropdownOptions={studentStatusOpt?.map((opt) => ({
                    value: opt.studentStatusName,
                    id: opt.studentStatusId,
                  }))}
                  label="Student Status"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  showDefaultOpt={true}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </CustomAccordion>
      </Box>
      {/* guarantor modal */}
      <CustomModal
        open={openGuarantorMod}
        handleClose={() => setOpenGuarantorMod(false)}
        heading="Guarantor"
        height={"auto"}
      >
        <CustomPreview
          isModal={true}
          handleGetAll={getGuarantorAction}
          handleBySearch={getGuarantorBySearchTermAction}
          searchLabel="Search by name, address and date of birth"
          loading={loading.getGuarantorLoading}
          rows={
            getGuarantorData?.result?.guarantor?.length > 0
              ? getGuarantorData?.result?.guarantor?.map((el) => ({
                  id: el?.guarantorId,
                  ...el,
                }))
              : []
          }
          columns={guarantorCols}
          paginationDetail={getGuarantorData}
          handleCell={(params) => handleSelectGuarantor(params)}
        />
      </CustomModal>
      {/* employer modal */}
      <CustomModal
        open={openEmployerMod}
        handleClose={() => setOpenEmployerMod(false)}
        heading="Employer"
        height={"auto"}
      >
        <CustomPreview
          isModal={true}
          handleGetAll={getEmployerAction}
          handleBySearch={getEmployerBySearchTermAction}
          searchLabel="Search by name and address"
          loading={employerLoading.getEmployerLoading}
          rows={
            getEmployerData?.result?.employer?.length > 0
              ? getEmployerData?.result?.employer?.map((el) => ({
                  id: el?.employerId,
                  ...el,
                }))
              : []
          }
          columns={emplyerCols}
          paginationDetail={getEmployerData}
          handleCell={(params) => handleSelectEmployer(params)}
        />
      </CustomModal>
    </>
  );
};

export default PatientInfo;
