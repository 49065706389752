import React from "react";
import { Box, Grid, useTheme, Typography, Stack } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomDialog from "../../../components/CustomDialog";
import CustomField from "../../../components/CustomField";
import CustomAddress from "../../../components/CustomAddress";
import { useNavigate } from "react-router-dom";
import path from "../../../config/apiUrl";
import CheckBox from "../../../components/CheckBox";
import { useEffect } from "react";
import { getData } from "../../../config/axiosFunctions";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";

const CompanyInfo = ({ formik }) => {
  console.log(formik, "formik");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [planOptions, setPlanOptions] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/company");
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  const dataFetchUrls = {
    planOptions: `${path}/ct-planOptions`,
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.planOptions, setPlanOptions);
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            justifyContent:"space-between"
          }}
        >
          <Stack direction={"row"} gap={"15px"}>

          {/* <CustomButton type="submit" isBlue={true} isSave={!formik.values.companyCode && true} isEdit={formik.values.companyCode && true}>
           {formik.values.companyCode? "Update" : "Save"}
          </CustomButton> */}
             <CustomButton type="submit" isBlue={true} isSave={!formik.values.companyId && true} isEdit={formik.values.companyId && true}>
           {formik.values.companyId? "Update" : "Save"}
          </CustomButton>
          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
          </Stack>
          {/* {formik.values.companyCode && ( */}
          {formik.values.companyId && (
            <CheckBox
              CheckedValue={!formik.values.isActive}
              handleChange={() =>
                formik.setFieldValue("isActive", !formik.values.isActive)
              }
              label="Make this company inactive"
            />
          )}
        </Box>

        <Box
          sx={{
            background: theme.palette.accordiansBodyBackground.primary,
            borderRadius: "4px",
            margin: "0 15px 0",
            padding: "15px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              background: theme.palette.accordiansBodyBackground.primary,
              borderRadius: "4px",
            }}
          >
            <Grid item md={6} xs={12}>
              <CustomField
                type="text"
                label="Name"
                value={formik.values.companyName}
                name="companyName"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomField
                type="text"
                label="Address"
                value={formik.values.address}
                name="address"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomField
                type="text"
                label="Phone No"
                value={formik.values.phoneNumber}
                name="phoneNumber"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                mask="(999) 999-9999"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomField
                type="email"
                label="Email"
                value={formik.values.email}
                name="email"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomField
                type="text"
                label="Description"
                value={formik.values.description}
                name="description"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomField
                type="text"
                label="Website"
                value={formik.values.website}
                name="website"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>

            <CustomAddress
              formik={formik}
              fieldValues={{
                countryId: formik.values.countryId,
                country: formik.values.countryName,
                countryName: "countryName",
                stateId: "stateId",
                stateName: "stateName",
                state: formik.values.stateName,
                cityId: "cityId",
                cityName: "cityName",
                city: formik.values.cityName,
                zipCode: formik.values.zipCode,
                zipCodeId: "zipCodeId",
                zipName: "zipCode",
              }}
              isRequired={true}
            />

            <Grid item xs={12}>
              <CustomSelectBox2
                name="planOptionsId"
                value={formik.values.planOptionsId}
                dropdownOptions={planOptions?.map((opt) => ({
                  value: opt.planOptionsName,
                  id: opt.planOptionsId,
                }))}
                label="Patient's Signature on File"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                showDefaultOpt={true}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
    </>
  );
};

export default CompanyInfo;
