import { Button, useTheme } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import PersonIcon from "@mui/icons-material/Person";
import { Settings, Undo, UndoRounded } from "@mui/icons-material";

const CustomButton = ({
  children,
  formId,
  handleClick,
  variant,
  margin,
  width,
  height,
  color,
  padding,
  isBlue,
  type = "button",
  isCancel,
  isSave,
  isSetting,
  disabled,
  isSearch,
  isAdd,
  isEdit,
  isDelete,
  isUser,
  iconComponent,
  borderRadius,
  gap,
  isUndo,
}) => {
  const theme = useTheme();
  const buttonStyle = {
    color: isBlue
      ? `${theme.palette.btnSaveText.primary}`
      : `${theme.palette.btnCancelText.primary}`,
    margin: margin,
    gap: gap,
    backgroundColor: isBlue
      ? `${theme.palette.saveBtn.primary}`
      : `${theme.palette.cancelBtn.primary}`,
    fontSize: "1.3rem",
    fontWeight: "600",
    fontFamily: "Inter",
    letterSpacing: "1.24px",
    wordSpacing: "3px",
    textTransform: "inherit",
    padding: padding,
    borderRadius: borderRadius,
    width: width,
    height: height,
    whiteSpace: "nowrap",
    "&:hover": {
      color: isBlue
        ? `${theme.palette.saveTextHover.primary}`
        : `${theme.palette.cancelTextHover.primary}`,
      background: isBlue
        ? `${theme.palette.saveHover.primary}`
        : `${theme.palette.cancelHover.primary}`,
    },
  };
  return (
    <Button
      form={formId}
      disabled={disabled}
      type={type}
      variant={variant}
      onClick={handleClick}
      size="medium"
      sx={buttonStyle}
    >
      {isCancel ? (
        <CloseIcon sx={{ fontSize: "20px" }} />
      ) : isSave ? (
        <CheckIcon sx={{ fontSize: "20px" }} />
      ) : isSetting ? (
        <Settings sx={{ fontSize: "22px" }} />
      ) : isSearch ? (
        <SearchIcon sx={{ fontSize: "22px" }} />
      ) : isAdd ? (
        <AddIcon sx={{ fontSize: "22px" }} />
      ) : isDelete ? (
        <DeleteIcon sx={{ fontSize: "22px" }} />
      ) : isEdit ? (
        <EditIcon sx={{ fontSize: "22px" }} />
      ) : isUser ? (
        <PersonIcon sx={{ fontSize: "22px" }} />
      ) : isUndo ? (
        <UndoRounded sx={{ fontSize: "22px" }} />
      ) : (
        (iconComponent = null)
      )}

      {children}
    </Button>
  );
};

export default CustomButton;
