// making new modal
import { Box, Stack, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import InsuranceInfo from "./InsuranceInfo";
import { useFormik } from "formik";
import { insuranceInfoValues } from "../../utils/formikInitValues";
import PayerInfo from "./PayerInfo";
import { insuranceSchema } from "../../schemas/patientSchema";

const InsuredModalForm = ({
  handleAddInsurance,
  formik,
  editMode,
  openInsModal,
  editIndex,
  priorityId,
}) => {
  console.log(editMode,"editMode insuredMdal")

  const insuranceFormik = useFormik({
    enableReinitialize: true,
    initialValues: editMode
      ? formik.values.insurancePoliciesDtos[editIndex]
      : insuranceInfoValues,
    validationSchema: insuranceSchema,
    onSubmit: (values) => {
console.log(values,"insuredmodal")
      let newValue = {};
      if (values.insuredRelationShipToPatientId === 8) {
        newValue = {
          ...values,
          insuredFirstName: formik.values.firstName,
          insuredLastName: formik.values.lastName,
        };
      } else {
        newValue = { ...values };
      }
      const existingWithPriorityId = formik.values.insurancePoliciesDtos.filter(
        (policy) => policy.payerInfoPriorityId === values.payerInfoPriorityId
      );
      console.log(existingWithPriorityId,"existingWithPriorityId")
      // If no existing entry with the same `payerInfoPriorityId`, set `isDefault` to `true`
    if (editMode) {
      newValue.isDefault = formik.values.insurancePoliciesDtos[editIndex].isDefault;
    } else {
      if (existingWithPriorityId.length === 0) {
        newValue.isDefault = true;
      } else {
        newValue.isDefault = false;
      }
    }
      handleAddInsurance(newValue);
      console.log(newValue, "newValues")
    },
  });

// const insuranceFormik = useFormik({
//   enableReinitialize: true,
//   initialValues: editMode
//     ? formik.values.insurancePoliciesDtos[editIndex]
//     : insuranceInfoValues,
//   validationSchema: insuranceSchema,
//   onSubmit: (values) => {
//     console.log(values, "insuredmodal");
//     let newValue = {};

//     // If insured relation is 'self' (for example), set insured's first and last name from the form
//     if (values.insuredRelationShipToPatientId === 8) {
//       newValue = {
//         ...values,
//         insuredFirstName: formik.values.firstName,
//         insuredLastName: formik.values.lastName,
//       };
//     } else {
//       newValue = { ...values };
//     }

//     // We want to check for the existence of a policy with the same priority id
//     const existingWithPriorityId = formik.values.insurancePoliciesDtos.filter(
//       (policy) => policy.payerInfoPriorityId === values.payerInfoPriorityId
//     );
//     console.log(existingWithPriorityId, "existingWithPriorityId");

//     // If the policy is being edited, we don't want to change `isDefault` unless it's necessary
//     if (editMode) {
//       // If it's already the default one, leave it as `isDefault = true`
//       newValue.isDefault = formik.values.insurancePoliciesDtos[editIndex].isDefault;
//     } else {
//       // If no existing entry with the same `payerInfoPriorityId`, set `isDefault` to `true`
//       if (existingWithPriorityId.length === 0) {
//         newValue.isDefault = true;
//       } else {
//         newValue.isDefault = false;
//       }
//     }

//     // Now we pass the new value to handleAddInsurance
//     handleAddInsurance(newValue);
//     console.log(newValue, "newValues");
//   },
// });

  console.log(insuranceFormik.values, "insuranceFormik")
  useEffect(() => {
    if (priorityId && !editMode) {
      console.log(priorityId);

      insuranceFormik.setFieldValue("payerInfoPriorityId", priorityId);
    } else {
      insuranceFormik.setFieldValue(
        "payerInfoPriorityId",
        insuranceFormik?.values?.payerInfoPriorityId
      );
    }
  }, [priorityId]);

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <form
            onSubmit={insuranceFormik.handleSubmit}
            id="insuranceForm"
            className="formContent"
          >
            <PayerInfo
              formik={formik}
              insuranceFormik={insuranceFormik}
              priorityId={priorityId}
            />
            <Box
              sx={{
                marginY: "10px",
              }}
            >
              <CustomButton
                type="submit"
                isBlue={true}
                padding="5px 15px"
                margin="5px 5px"
                // handleClick={handleAddInsurance}
                formId="insuranceForm"
              >
                {editMode ? "Update" : "Add"}
              </CustomButton>
            </Box>
          </form>
        </Grid>
        {/* <Grid
          item
          md={12}
          sx={{
            position: "relative",
            bottom: 0,
            // left: 0,
            // textAlign: "right",
            right: 0,
            background: "#fff",
            // background:'red'
          }}
        >
          <CustomButton
            type="submit"
            isBlue={true}
            padding="5px 15px"
            margin="5px 5px"
            // handleClick={handleAddInsurance}
            formId="insuranceForm"
          >
            {editMode ? "Update" : "Add"}
          </CustomButton>
        </Grid> */}
      </Grid>
    </>
  );
};

export default InsuredModalForm;
