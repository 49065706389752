import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const newProcedureCodeAction = createAsyncThunk(
  "createProcedureCode",
  async (data) => {
    try {
      const response = await postData(`${path}/ct-procedureCodes`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateProcedureCodeAction = createAsyncThunk(
  "updateProcedureCode",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/ct-procedureCodes/${data?.procedureCodeId}`,
        data
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getProcedureCodeAction = createAsyncThunk(
  "getProcedureCodes",
  async (page) => {
    try {
      const newPage = page?.page+1
      const response = await getData(`${path}/ct-procedureCodes?page=${newPage}&pageSize=20`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getProcedureCodesByIdAction = createAsyncThunk(
  "getProcedureCodesById",
  async (id) => {
    try {

      const response = await getData( `${path}/ct-procedureCodes/${id}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search
export const getProcedureCodesBySearchTermAction = createAsyncThunk(
  "getProcedureCodes",
  async ({searchTerm,page}) => {
    try {
      console.log(page);
      
      const response = await getData(
        // http://192.168.3.73:86/api/ct-procedureCodes/SearchProcedureCodes?searchValue=20552&page=1&pageSize=4
        `${path}/ct-procedureCodes/SearchProcedureCodes?SearchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      console.log(response, "action procedure codes by search term");
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);


