import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
// import { useSelector } from "react-redux";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
// import CustomPreview from "../../layout/CustomPreview";
import Loading from "../../components/Loading";
import { useConfirm } from "material-ui-confirm";
import axios from "axios";

const columnsForPatient = [
  {
    // field: "claimNumber",
    // headerName: "Claim",
    field: "claimId",
    headerName: "Claim Id",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    rowClassName: "row-bg",
  },
  // {
  //   field: "tcn",
  //   headerName: "TCN",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  //   rowClassName: "row-bg",
  // },
  {
    field: "amount",
    headerName: "Billed",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "allowedAmount",
    headerName: "Allowed",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "paid",
    headerName: "Paid",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "adjusted",
    headerName: "Adjusted",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "unpaid",
    headerName: "Unpaid",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  // {
  //   field: "additionalActions",
  //   headerName: "Additional Actions",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  // },
  {
    field: "endBalance",
    headerName: "Balance",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    // renderCell: (params) => (
    //   <Typography sx={{ fontSize: "12.5px" }}>
    //     {params.row?.billed - params.row?.paid}
    //   </Typography>
    // ),
  },
];

const PostPayGrid = ({ setShowDetail, formik, setSelectedClaim }) => {
  const theme = useTheme(); // Access the current theme
  const confirm = useConfirm();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  // const Sum = useMemo(() => {
  //   return (id, key) => {
  //     if (formik.values?.paymentClaimDto) {
  //       let findClaim = formik.values?.paymentClaimDto.find(
  //         (val) => val.claimId === id
  //       );
  //       if (findClaim) {
  //         const sum = findClaim.paymentDetailDto.reduce(
  //           (accumulator, currentValue) => {
  //             return accumulator + parseFloat(currentValue[key]);
  //           },
  //           0
  //         );
  //         return sum;
  //       }
  //       return 0;
  //     }
  //     return 0;
  //   };
  // }, [formik.values?.paymentClaimDto]);
  // const SumFromClaimChargesDto = useMemo(() => {
  //   return (id, key) => {
  //     if (formik.values?.paymentClaimDto) {
  //       let findClaim = formik.values?.paymentClaimDto.find(
  //         (val) => val.claimId === id
  //       );
  //       if (findClaim) {
  //         const sum = findClaim.claimChargesDto.reduce(
  //           (accumulator, currentValue) => {
  //             return accumulator + parseFloat(currentValue[key]);
  //           },
  //           0
  //         );
  //         return sum;
  //       }
  //       return 0;
  //     }
  //     return 0;
  //   };
  // }, [formik.values?.paymentClaimDto]);
  // const SumForMainTotal = (key) => {
  //   let total = rows.reduce((sum, current) => sum + current[key], 0);
  //   return total;
  // };
  // const totalRow = {
  //   id: "total",
  //   patientFirstName: "Total",
  //   patientLastName: "",
  //   billed: SumForMainTotal("billed"),
  //   allowed: SumForMainTotal("allowed"),
  //   paid: SumForMainTotal("paid"),
  //   adjusted: SumForMainTotal("adjusted"),
  //   unpaid: SumForMainTotal("unpaid"),
  //   additionalActions: SumForMainTotal("additionalActions"),
  //   balance: SumForMainTotal("balance"),
  // };

  // useEffect(() => {
  //   (async function () {
  //     setLoading(true);
  //     try {
  //       let paymentClaimDto = [];
  //       if (
  //         formik.values?.paymentByTypeId === 1 &&
  //         formik.values?.paymentClaimDto.length === 0 &&
  //         formik.values.isClaim
  //       ) {
  //         const { result } = await getData(
  //           // `${path}/claim/patient/${formik.values?.accountNo}`
  //           `${path}/claim/patient/${formik.values?.patientId}`
  //         );
  //         if (result.length > 0) {
  //           paymentClaimDto = result.map((val) => ({
  //             claimId: val?.claimId,
  //             // claimNumber: val?.claimNumber,
  //             claimChargesDto: val?.claimChargesPatientDtos,
  //             ClaimStatusId: val?.claimStatusId,
  //             PaymentActionId: null,
  //             TCNId: null,
  //             ClaimControlNo: null,
  //             paymentDetailDto: [],
  //           }));
  //         } else {
  //           confirm({
  //             title:
  //               "No charges were found for this patient using the current search criteria.",
  //             confirmationText: "Ok",
  //           })
  //             .then(() => { })
  //             .catch(() => { });
  //         }
  //       } else if (
  //         formik.values?.paymentByTypeId === 2 &&
  //         formik.values?.paymentClaimDto.length === 0 &&
  //         formik.values.isClaim
  //       ) {
  //         const { result } = await getData(
  //           // `${path}/claim/payment/${formik.values?.claimNumber}`
  //           `${path}/claim/payment/${formik.values?.claimId}`
  //         );

  //         if (result.length > 0) {
  //           paymentClaimDto = result.map((val) => ({
  //             claimId: val?.claimId,
  //             // claimNumber: val?.claimNumber,
  //             claimChargesDto: val?.claimChargesPatientDtos,
  //             patientFirstName: val?.patientFirstName,
  //             patientLastName: val?.patientLastName,
  //             // patientAccountNo: val?.accountNo,
  //             patientId: val?.patientId,
  //             claimStatusId: val?.claimStatusId,
  //             PaymentActionId: null,
  //             TCNId: null,
  //             ClaimControlNo: null,
  //             paymentDetailDto: [],
  //           }));
  //         }
  //       } else if (
  //         formik.values?.paymentByTypeId === 2 &&
  //         formik.values?.paymentClaimDto.length === 0 &&
  //         !formik.values.isClaim
  //       ) {
  //         paymentClaimDto = formik.values?.paymentClaimDto || [];
  //       } else {
  //         paymentClaimDto = formik.values?.paymentClaimDto || [];
  //       }
  //       if (paymentClaimDto.length > 0) {
  //         formik.setFieldValue("paymentClaimDto", paymentClaimDto);
  //         const updatedRows = paymentClaimDto.map((el) => {
  //           const claimId = el?.claimId || el.paymentId;
  //           return {
  //             id: claimId,
  //             patientFirstName: el?.patientFirstName,
  //             patientLastName: el?.patientLastName,
  //             // patientAccountNo: el?.patientAccountNo,
  //             patientId: el?.patientId,
  //             // claimNumber: el.claimNumber,
  //             billed: SumFromClaimChargesDto(claimId, "amount"),
  //             allowed: Sum(claimId, "allowed"),
  //             paid: Sum(claimId, "paid"),
  //             adjusted: Sum(claimId, "adjusted"),
  //             unpaid: Sum(claimId, "unpaid"),
  //             additionalActions: 0,
  //             balance:
  //               SumFromClaimChargesDto(claimId, "amountUnpaid") -
  //               Sum(claimId, "allowed"),
  //             claimChargesDto: el.claimChargesDto,
  //             paymentDetailDto: el?.paymentDetailDto || [],
  //           };
  //         });
  //         setRows(updatedRows);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.log("Error while getting payment claim data:", error);
  //     }
  //   })();
  // }, [Sum, SumFromClaimChargesDto]);
  // calculate total from details
  const calculateDetailsTotal = (details) => {
    return details.reduce(
      (acc, detail) => ({
        amount: acc.amount + (detail.amount || 0),
        allowedAmount: acc.allowedAmount + (detail.allowedAmount || 0),
        paid: acc.paid + (detail.paid || 0),
        adjusted: acc.adjusted + (detail.adjusted || 0),
        unpaid: acc.unpaid + (detail.unpaid || 0),
        endBalance: acc.endBalance + (detail.endBalance || 0),
      }),
      {
        amount: 0,
        allowedAmount: 0,
        paid: 0,
        adjusted: 0,
        unpaid: 0,
        endBalance: 0,
      }
    );
  };
  useEffect(() => {
    const fetchGridData = async () => {
      try {
        setLoading(true);
        let paymentClaimDto = [];
        if (
          formik.values.paymentByTypeId === 1 &&
          formik.values._PaymentClaimDto.length === 0
        ) {
          const response = await axios.get(
            `${path}/claim/patient/${formik.values?.patientId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200 && response.data.result.length > 0) {
            setLoading(false);
            paymentClaimDto = response.data.result?.map((val) => ({
              claimId: val.claimId,
              _PaymentDetailDto: val?.claimChargesPatientDtos?.map((item) => ({
                procedureCodeId: item?.procedureCodeId,
                procedureCode: item?.procedureCode,
                amount: item?.amount,
                startBalance: item?.startBalance,
                allowedAmount: 0,
                paid: 0,
                remarksCodeId: null,
                remarksCode: "",
                adjustedReasonsId: null,
                adjustedReasons: "",
                adjusted: 0,
                unpaidReason: "",
                unpaid: 0,
                deductible: 0,
                otherCredits: 0,
                endBalance: item?.startBalance,
                isRemove: false,
                depositSlipId: 0,
                claimId: item?.claimId,
                claimChargesId: item?.claimChargesId || 0,
                claimStatusId: item?.claimStatusId || 0,
                claimStatus: item?.claimStatus || "",
                paymentActionsId: null,
                paymentActions: "",
                dos: item?.dos,
              })),
            }));
            // Set paymentClaimDto in formik
            formik.setValues((preVals) => ({
              ...preVals,
              _PaymentClaimDto: paymentClaimDto,
            }));
          } else {
            setLoading(false);
            confirm({
              title:
                "No charges were found for this patient using the current search criteria.",
              confirmationText: "Ok",
            });
          }
        } else if (
          formik.values.paymentByTypeId !== 1 &&
          formik.values._PaymentClaimDto.length === 0 &&
          formik.values.isClaim
        ) {
          const response = await axios.get(
            `${path}/claim/payment/${formik.values?.claimId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          console.log(response, "responseLife");
          if (response.status === 200 && response.data.result) {
            const result = response.data.result;
            setLoading(false);
            paymentClaimDto = [
              {
                claimId: result.claimId,
                _PaymentDetailDto: result?.claimChargesPatientDtos?.map(
                  (item) => ({
                    procedureCodeId: item?.procedureCodeId,
                    procedureCode: item?.procedureCode,
                    amount: item?.amount,
                    startBalance: item?.startBalance,
                    allowedAmount: 0,
                    paid: 0,
                    remarksCodeId: null,
                    remarksCode: "",
                    adjustedReasonsId: null,
                    adjustedReasons: "",
                    adjusted: 0,
                    unpaidReason: "",
                    unpaid: 0,
                    deductible: 0,
                    otherCredits: 0,
                    endBalance: item?.startBalance,
                    isRemove: false,
                    depositSlipId: 0,
                    claimId: item?.claimId,
                    claimChargesId: item?.claimChargesId || 0,
                    claimStatusId: item?.claimStatusId || 0,
                    claimStatus: item?.claimStatus || "",
                    paymentActionsId: null,
                    paymentActions: "",
                    dos: item?.dos,
                  })
                ),
              },
            ];
            // set values in formik
            formik.setValues((preVals) => ({
              ...preVals,
              _PaymentClaimDto: paymentClaimDto,
            }));
          }
        } else if (
          formik.values.paymentByTypeId !== 1 &&
          formik.values._PaymentClaimDto.length === 0 &&
          !formik.values.isClaim
        ) {
          const response = await axios.get(
            `${path}/insuranceClaimDetail/InsuranceId?InsuranceId=${formik.values?.insuranceDescriptionId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200 && response.data.result.length > 0) {
            setLoading(false);
            paymentClaimDto = response.data.result.map((val) => ({
              claimId: val.claimId,
              _PaymentDetailDto: val?.claimChargesPatientDtos?.map((item) => ({
                procedureCodeId: item?.procedureCodeId,
                procedureCode: item?.procedureCode,
                amount: item?.amount,
                startBalance: item?.startBalance,
                allowedAmount: 0,
                paid: 0,
                remarksCodeId: null,
                remarksCode: "",
                adjustedReasonsId: null,
                adjustedReasons: "",
                adjusted: 0,
                unpaidReason: "",
                unpaid: 0,
                deductible: 0,
                otherCredits: 0,
                endBalance: item?.startBalance,
                isRemove: false,
                depositSlipId: 0,
                claimId: item?.claimId,
                claimChargesId: item?.claimChargesId || 0,
                claimStatusId: item?.claimStatusId || 0,
                claimStatus: item?.claimStatus || "",
                paymentActionsId: null,
                paymentActions: "",
                dos: item?.dos,
              })),
            }));
            // Set paymentClaimDto in formik
            formik.setValues((preVals) => ({
              ...preVals,
              _PaymentClaimDto: paymentClaimDto,
            }));
          }
        }
      } catch (e) {
        console.error("Error fetching grid data:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchGridData();
  }, [formik.values._PaymentClaimDto.length === 0]);
  // handle the updated rows acccording to the calculation
  useEffect(() => {
    if (formik.values._PaymentClaimDto.length > 0) {
      const updatedRows = formik.values._PaymentClaimDto.map((item) => {
        const totalDetails = calculateDetailsTotal(
          item._PaymentDetailDto || []
        );
        return {
          ...item,
          id: item?.claimId,
          ...totalDetails,
        };
      });
      setRows(updatedRows);
    }
  }, [formik.values._PaymentClaimDto]);
  console.log(formik.values, "checkValuesHereFormik");
  return (
    <>
      <Box sx={{ width: "100%", borderRadius: "10px 10px 0px 0px" }}>
        {loading ? (
          <Loading />
        ) : (
          <DataGrid
            rows={rows || []}
            showCellRightBorder={true}
            rowHeight={35}
            columns={columnsForPatient}
            sx={{
              "& .header-bg": {
                // backgroundColor: "#F9F9F9",
                background: theme.palette.dataGridHeading.primary,
                color: theme.palette.textMainHeading.primary,
                // color:'black',
                fontSize: "14.5px",
              },
              "& .cell-content": {
                fontSize: "12.5px",
              },
            }}
            autoHeight
            disableSelectionOnClick
            onCellClick={(params) => {
              // if (params.row.id !== "total") {
              //   console.log("formik.values2323", formik.values, params.row.paymentDetailDto.length > 0
              //     ? params.row.paymentDetailDto
              //     : params.row.claimChargesDto || params.row)
              //   formik.setFieldValue(
              //     "currentClaim",
              //     params.row.paymentDetailDto.length > 0
              //       ? params.row.paymentDetailDto
              //       : params.row.claimChargesDto || params.row
              //   );
              // }
              setSelectedClaim(params.row);
              setShowDetail(true);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PostPayGrid;
