// import { Box, useTheme,Typography} from "@mui/material";
// import MainHeading from "../../../../components/MainHeading";
// import ClaimBatchPrintForm from "./ClaimBatchPrintForm";
// import { useFormik } from "formik";
// import { claimBatchPrintIntVal } from "../../../../utils/formikInitValues";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getClaimBatchPrint,
//   getClaimBatchPrintPdfData,
// } from "../../../../features/actions/claimBatchPrintAction";
// import Loading from "../../../../components/Loading";
// import CustomButton from "../../../../components/CustomButton";
// import { pdf } from "@react-pdf/renderer";
// import { handleCancel } from "../../../../features/slice/claimBatchPrintSlice";
// import { DataGrid } from "@mui/x-data-grid";
// import { useState } from "react";
// import { claimBatchPrintColumn } from "../../../../utils/gridCols";
// import CustomDialog from "../../../../components/CustomDialog";
// import { postData } from "../../../../config/axiosFunctions";
// import path from "../../../../config/apiUrl";
// import CmsForm15 from "../../../../components/pdfs/CmsForm15";
// import CmsUB04 from "../../../../components/pdfs/CmsUB04";
// import { useNavigate } from "react-router-dom";
// const ClaimBatchPrint = () => {
//   const dispatch = useDispatch();
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const { claimBatchPrintData, loading, dataExits } = useSelector(
//     (state) => state.claimBatchPrint
//   );

//   const [openCancelDialog, setOpenCancelDialog] = useState(false);

//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     // formik.resetForm();
//     // navigate("/claim-batch-print");
//     dispatch(handleCancel());
//     formik.resetForm();
//   };

//   const [selectedRows, setSelectedRows] = useState([]);

//   console.log("claimBatchPrintData", claimBatchPrintData, loading, dataExits);
//   const formik = useFormik({
//     initialValues: claimBatchPrintIntVal,
//     onSubmit: (values, action) => {
//       let facilitySeqNums = formik.values?.facilitySeqNums.map((v) => v?.value);
//       let officeLocation = formik.values?.officeLocation.map((v) => v?.value);
//       let payerSeqNums = formik.values?.payerSeqNums.map((v) => v?.value);
//       let practiceSeqNums = formik.values?.practiceSeqNums.map((v) => v?.value);
//       let providerSeqNums = formik.values?.providerSeqNums.map((v) => v?.value);
//       let payerPriority = formik.values?.payerPriority.map((v) => v?.value);
//       let updateValues = {
//         ...formik.values,
//         facilitySeqNums: facilitySeqNums,
//         officeLocation: officeLocation,
//         payerSeqNums: payerSeqNums,
//         practiceSeqNums: practiceSeqNums,
//         providerSeqNums: providerSeqNums,
//         payerPriority: payerPriority,
//       };
//       dispatch(getClaimBatchPrint(updateValues));
//     },
//   });

//   const handleShowPreview = async () => {
//     if(selectedRows.length > 0){
//     let formType = formik.values.claimFormId;
//     let endPoint = formType === 1 ? "cmsForm" : "ub04Form";
//     let result = await getClaimBatchPrintPdfData(endPoint, selectedRows);
//     if (result) {
//       const pdfBlob = await pdf(
//         formType === 1 ? (
//           <CmsForm15 data={result} />
//         ) : (
//           <CmsUB04 pdfData={result} />
//         )
//       ).toBlob();
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       window.open(pdfUrl, "_blank");
//     }
//     setSelectedRows([]);
//   }
//   else{
//     alert("Please select atleast one row")
//   }
//   };
//   return (
//     <>
//       {" "}
//       {!dataExits ? (
//         loading ? (
//           <Loading />
//         ) : (
//           <Box margin="20px" >
//             <MainHeading mainheading={"Claim Batch Print"} />
//             <form onSubmit={formik.handleSubmit}>
//               <ClaimBatchPrintForm formik={formik} />
//             </form>
//           </Box>
//         )
//       ) : (
//         <>
//           {!loading ? (
//             <Box margin="20px" paddingBottom={"25px"}>
//               <MainHeading mainheading={"Claim Batch Print"} />
//               <Box margin="20px" display={"flex"} gap={"10px"}>
//                 <CustomButton children={"Print"} isBlue={true} />
//                 <CustomButton children={"Test Print"} />
//                 <CustomButton
//                   handleClick={handleShowPreview}
//                   children={"Show Preview"}
//                   isSearch={true}
//                 />
//                 {/* <CustomButton
//                   handleClick={() => {
//                     dispatch(handleCancel());
//                     formik.resetForm();
//                   }}
//                   children={"Cancel"}
//                   isCancel={true}
//                 /> */}
                
//                 <CustomButton  
//              isCancel={true} handleClick={handleCancel}>
//               Cancel
//             </CustomButton>
//               </Box>
//               <Box>
//                 <DataGrid
//                   rows={
//                     claimBatchPrintData && claimBatchPrintData.length > 0
//                       ? claimBatchPrintData.map((v, i) => {
//                           return {
//                             ...v,
//                             id: v.claimNumber,
//                           };
//                         })
//                       : []
//                   }
//                   columns={claimBatchPrintColumn}
//                   sx={{
//                     "& .header-bg": {
//                       background: theme.palette.dataGridHeading.primary,
//                       color: theme.palette.textMainHeading.primary,
//                       fontSize: "16px",
//                     },
//                     "& .cell-content": {
//                       fontSize: "1.2rem",
//                     },
//                     "& .MuiTablePagination-displayedRows": {
//                       fontSize: "1.3rem",
//                     },
//                     "& .MuiTablePagination-actions": {
//                       "& svg": {
//                         fontSize: "1.8rem",
//                         color: theme.palette.textMainHeading.primary,
//                         marginBottom: "8px",
//                       },
//                       "& .MuiDataGrid-row": {
//                         backgroundColor: theme.palette.textMainHeading.primary,
//                       },
//                     },
//                   }}
//                   // pagination
//                   pageSize={20}
//                   // pageSize={paginationDetail?.result?.pageSize}
//                   // page={page}
//                   // rowCount={paginationDetail?.result?.totalCount}
//                   // onPageChange={handlePageChange}
//                   checkboxSelection={true}
//                   disableRowSelectionOnClick={true}
//                   selectionModel={selectedRows}
//                   showCellRightBorder={true}
//                   autoHeight
//                   // rowHeight={reducedCellHeight}
//                   disableSelectionOnClick
//                   onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
//                   components={{
//                     NoRowsOverlay: () => (
//                       <h3
//                         style={{
//                           width: "100%",
//                           textAlign: "center",
//                           padding: "16px",
//                         }}
//                       >
//                         {/* {rows?.length === 0 && "No Data Is Added"} */}
//                       </h3>
//                     ),
//                   }}
//                 />
//               </Box>
//               {/* </Box>{" "} */}
//             </Box>
//           ) : (
//             <Loading />
//           )}
//         </>
//       )}
//       <CustomDialog
//         open={openCancelDialog}
//         isWarning={true}
//         handleClose={() => setOpenCancelDialog(false)}
//         handleConfirm={handleConfirmCancel}
//         handleCancel={() => setOpenCancelDialog(false)}
//         confirmButtonTitle="OK"
//         cancelButtonTitle="Cancel"
//       >
//         <Typography component="h3">Are you sure you want to cancel?</Typography>
//       </CustomDialog>
//     </>
//   );
// };

// export default ClaimBatchPrint;
import { Box, useTheme, Typography } from "@mui/material";
import MainHeading from "../../../../components/MainHeading";
import ClaimBatchPrintForm from "./ClaimBatchPrintForm";
import { useFormik } from "formik";
import { claimBatchPrintIntVal } from "../../../../utils/formikInitValues";
import { useDispatch, useSelector } from "react-redux";
import {
  getClaimBatchPrint,
  getClaimBatchPrintPdfData,
} from "../../../../features/actions/claimBatchPrintAction";
import Loading from "../../../../components/Loading";
import CustomButton from "../../../../components/CustomButton";
import { pdf } from "@react-pdf/renderer";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { claimBatchPrintColumn } from "../../../../utils/gridCols";
import CustomDialog from "../../../../components/CustomDialog";
import CmsForm15 from "../../../../components/pdfs/CmsForm15";
import CmsUB04 from "../../../../components/pdfs/CmsUB04";
import { handleClaimBatchPrint } from "../../../../features/slice/claimBatchPrintSlice";
import { useNavigate } from "react-router-dom";

const ClaimBatchPrint = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { claimBatchPrintData, loading, dataExits } = useSelector(
    (state) => state.claimBatchPrint
  );

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    dispatch(handleClaimBatchPrint())
    setOpenCancelDialog(false);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  console.log("claimBatchPrintData", claimBatchPrintData, loading, dataExits);
  const formik = useFormik({
    initialValues: claimBatchPrintIntVal,
    onSubmit: (values, action) => {
      let facilitySeqNums = formik.values?.facilitySeqNums.map((v) => v?.value);
      let officeLocation = formik.values?.officeLocation.map((v) => v?.value);
      let payerSeqNums = formik.values?.payerSeqNums.map((v) => v?.value);
      let practiceSeqNums = formik.values?.practiceSeqNums.map((v) => v?.value);
      let providerSeqNums = formik.values?.providerSeqNums.map((v) => v?.value);
      let payerPriority = formik.values?.payerPriority.map((v) => v?.value);
      let updateValues = {
        ...formik.values,
        facilitySeqNums: facilitySeqNums,
        officeLocation: officeLocation,
        payerSeqNums: payerSeqNums,
        practiceSeqNums: practiceSeqNums,
        providerSeqNums: providerSeqNums,
        payerPriority: payerPriority,
      };
      dispatch(getClaimBatchPrint(updateValues));
    },
  });

  const handleShowPreview = async () => {
    if (selectedRows.length > 0) {
      let formType = formik.values.claimFormId;
      let endPoint = formType === 1 ? "cmsForm" : "ub04Form";
      let result = await getClaimBatchPrintPdfData(endPoint, selectedRows);
      if (result) {
        const pdfBlob = await pdf(
          formType === 1 ? (
            <CmsForm15 data={result} />
          ) : (
            <CmsUB04 pdfData={result} />
          )
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      }
      setSelectedRows([]);
    } else {
      alert("Please select at least one row");
    }
  };

  return (
    <>
      {!dataExits ? (
        loading ? (
          <Loading />
        ) : (
          <Box margin="20px">
            {/* <MainHeading mainheading={"Claim Batch Print"} /> */}
            <form onSubmit={formik.handleSubmit}>
              <ClaimBatchPrintForm formik={formik}  />
            </form>
          </Box>
        )
      ) : (
        <>
          {!loading ? (
            <Box margin="20px" paddingBottom={"25px"}>
              {/* <MainHeading mainheading={"Claim Batch Print"} /> */}
              <Box margin="20px" display={"flex"} gap={"10px"}>
                <CustomButton children={"Print"} isBlue={true} />
                <CustomButton children={"Test Print"} />
                <CustomButton
                  handleClick={handleShowPreview}
                  children={"Show Preview"}
                  isSearch={true}
                />
                {/* <CustomButton
                  handleClick={handleOpenCancelDialog}
                  children={"Cancel"}
                  isCancel={true}
                /> */}
                     <CustomButton isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
              </Box>
              <Box>
                <DataGrid
                  rows={
                    claimBatchPrintData && claimBatchPrintData.length > 0
                      ? claimBatchPrintData.map((v) => {
                          return {
                            ...v,
                            // id: v.claimNumber,
                            id: v.claimId,

                          };
                        })
                      : []
                  }
                  columns={claimBatchPrintColumn}
                  sx={{
                    "& .header-bg": {
                      background: theme.palette.dataGridHeading.primary,
                      color: theme.palette.textMainHeading.primary,
                      fontSize: "16px",
                    },
                    "& .cell-content": {
                      fontSize: "1.2rem",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      fontSize: "1.3rem",
                    },
                    "& .MuiTablePagination-actions": {
                      "& svg": {
                        fontSize: "1.8rem",
                        color: theme.palette.textMainHeading.primary,
                        marginBottom: "8px",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: theme.palette.textMainHeading.primary,
                      },
                    },
                  }}
                  pageSize={20}
                  checkboxSelection={true}
                  disableRowSelectionOnClick={true}
                  selectionModel={selectedRows}
                  showCellRightBorder={true}
                  autoHeight
                  disableSelectionOnClick
                  onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
                  components={{
                    NoRowsOverlay: () => (
                      <h3
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "16px",
                        }}
                      >
                        No Data Available
                      </h3>
                    ),
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Loading />
          )}
        </>
      )}
      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
      
    </>
  );
};

export default ClaimBatchPrint;
