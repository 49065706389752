// import React from "react";
// import { Box, Stack } from "@mui/material";
// import CustomPreview from "../../../layout/CustomPreview";
// import { payerCol } from "../../../utils/gridCols";
// import { useNavigate } from "react-router-dom";
// import CustomButton from "../../../components/CustomButton";
// import {
//   getPayerAction,
//   getPayerBySearchTermAction,
// } from "../../../features/actions/payerAction";
// import { useSelector } from "react-redux";

// const Payer = () => {
//   const navigate = useNavigate();
//   const { getAllPayer, loading } = useSelector((state) => state.payer);
//   console.log(getAllPayer,"getPayer")

//   const rows =
//     getAllPayer?.result && getAllPayer?.result?.length > 0
//       ? getAllPayer.result.map((row) => ({
//           ...row,
//           id: row.payerId,
//         }))
//       : [];

//   return (
//     <Box>
//       <CustomPreview
//         rows={rows}
//         columns={payerCol}
//         loading={loading}
//         handleGetAll={getPayerAction}
//         handleBySearch={getPayerBySearchTermAction}
//         paginationDetail={getAllPayer}
//         moduleHeading={"Payer"}
//         handleCell={(params) => navigate(`/payer/update/${params?.id}`)}
//         searchLabel="Search by name or address #"
//       >
//         <Stack direction="row" alignItems="center">
//           <CustomButton
//             isAdd={true}
//             isBlue={true}
//             handleClick={() => navigate("/payer/create")}
//           >
//             Add Payer
//           </CustomButton>
//         </Stack>
//       </CustomPreview>
//     </Box>
//   );
// };

// export default Payer;

import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import CustomPreview from "../../../layout/CustomPreview";
import { payerCol } from "../../../utils/gridCols";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../../features/actions/payerAction";
import { useSelector, useDispatch } from "react-redux";

const Payer = () => {
  const navigate = useNavigate();
  const { getAllPayer, loading } = useSelector((state) => state.payer);

  console.log(getAllPayer, "getPayer");
  const rows =
  getAllPayer?.result?.insurance?.length > 0
    ? getAllPayer.result.insurance.map((el) => {
        const address = el.insuranceAddressDtos?.[0];
        return {
          id: el.insuranceDescriptionId,
          insuranceDescriptionName: el.insuranceDescriptionName,
          planName: el.planName,
          payerAddress: address.address,
          insuranceTypeName: el.insuranceTypeName,
          state: address.state,
          // sequenceNo: el.sequenceNo,
          ...el,
        };
      })
    : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={payerCol}
        loading={loading}
        handleGetAll={getPayerAction}
        handleBySearch={getPayerBySearchTermAction}
        paginationDetail={getAllPayer}
        moduleHeading={"Payer"}
        // handleCell={(params) =>  navigate(`/payer/update/${params?.sequenceNo}`)} 
        handleCell={(params) =>  navigate(`/payer/update/${params?.insuranceDescriptionId}`)} 
        searchLabel="Search by name or address #"
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/payer/create")}
          >
            Add Payer
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Payer;
