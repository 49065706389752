// import React from "react";
// import "./Custom-styling/CheckBox.css";
// import { FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material";

// const CheckBox = ({ label, CheckedValue, handleChange, name }) => {
//   const theme = useTheme();
//   const checkboxColor = {
//     color: theme.palette.saveBtn.primary,
//     fontSize: "2rem !important",
  
//   };
//   console.log(theme, "checkTheme")
//   return (
//     <>
//       <FormGroup className="checkbox-container">
//         <FormControlLabel
//           className="checkbox-label"
//           control={<Checkbox checked={CheckedValue} style={checkboxColor} />}
//           label={label}
//           onChange={handleChange}
//           name={name}
//         />
//       </FormGroup>
//     </>
//   );
// };
// export default CheckBox;



import React from "react";
import "./Custom-styling/CheckBox.css";
import { FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material";

const CheckBox = ({ label, CheckedValue, handleChange, name, isSelectAll, onSelectAllChange,disabled}) => {
  const theme = useTheme();
  const checkboxColor = {
    color: theme.palette.saveBtn.primary,
    fontSize: "2rem !important",
  };

  return (
    <FormGroup className="checkbox-container">
      <FormControlLabel
        className="checkbox-label"
        control={
          <Checkbox
            checked={CheckedValue}
            style={checkboxColor}
            onChange={isSelectAll ? onSelectAllChange : handleChange}
            name={name}
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CheckBox;

