// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Grid, Stack } from "@mui/material";
// import CustomField from "../../components/CustomField";
// import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import CustomDatePicker from "../../components/CustomDatePicker";
// import CustomModal from "../../components/CustomModal";
// import CustomPreview from "../../layout/CustomPreview";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
// import CustomAddress from "../../components/CustomAddress";
// import CustomButton from "../../components/CustomButton";
// import { insuredCol } from "../../utils/gridCols";
// import { getInsuredPartyAction } from "../../features/actions/insuredPartyActions";

// const InusredPartyForm = ({ insuredPartyFormik }) => {
//   console.log(insuredPartyFormik?.values);
//   const initVals = insuredPartyFormik?.values;
//   const [genderOpt, setGenderOpt] = useState([]);

//   const {getInsuredPartyData, loading } = useSelector((state) => state.insuredParty);
//   const [openInsuredPartyMod,setOpenInsuredPartyMod]=useState(false);
//   const dataFetchUrl = {
//     gender: "/ct-genderIdentity",
//   };
//   let flage = false;
//   const fetchData = async (endPoint, setState) => {
//     try {
//       const response = await getData(`${path}${endPoint}`);
//       setState(response.result);
//     } catch (error) {
//       console.log("error while fetch data", error);
//     }
//   };

//   useEffect(() => {
//     if (!flage) {
//       flage = true;
//       fetchData(dataFetchUrl.gender, setGenderOpt);
//     }
//   }, []);

//   return (
//     <>
//       <Grid container spacing={2} rowGap={2}>
//         <Grid item md={12}>
//           <Stack width={"40%"}>
//           <CustomButton
//           isBlue={true}
//               handleClick={() => setOpenInsuredPartyMod(true)}>Search Insured Party</CustomButton>
//           </Stack>
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="text"
//             label="First Name"
//             value={initVals.insuredFirstName}
//             name={"insuredFirstName"}
//             handleChange={insuredPartyFormik.handleChange}
//             handleBlur={insuredPartyFormik.handleBlur}
//             isRequired={true}
//               error={insuredPartyFormik.errors}
//               touched={insuredPartyFormik.touched}
//             isOutlined={false}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="text"
//             label="Last Name"
//             value={initVals.insuredLastName}
//             name="insuredLastName"
//             handleChange={insuredPartyFormik.handleChange}
//             handleBlur={insuredPartyFormik.handleBlur}
//             isRequired={true}
//               error={insuredPartyFormik.errors}
//               touched={insuredPartyFormik.touched}
//             isOutlined={false}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="email"
//             label="Email"
//             value={initVals.insuredEmail}
//             name="insuredEmail"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomDatePicker
//             dateLabel="Date of Birth"
//             dateValue={initVals.insuredDateOfBirth}
//             name="insuredDateOfBirth"
//             handleDateChange={insuredPartyFormik.handleChange}
//             handleDateBlur={insuredPartyFormik.handleBlur}
//             formik={insuredPartyFormik}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomSelectBox2
//             label="Gender"
//             dropdownOptions={genderOpt?.map((opt) => ({
//               id: opt?.genderIdentityId,
//               value: opt?.genderIdentityName,
//             }))}
//             name="insuredGenderIdentityId"
//             value={initVals?.insuredGenderIdentityId}
//             handleChange={insuredPartyFormik.handleChange}
//             handleBlur={insuredPartyFormik.handleBlur}
//             formik={insuredPartyFormik}
//             setValName={"insuredGenderIdentityName"}
//             isSetName={true}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="number"
//             label="Cell Phone"
//             value={initVals.insuredCellPhone}
//             name="insuredCellPhone"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//             isRequired={true}
//             isOutlined={false}
//             //   error={insuredPartyFormik.errors}
//             //   touched={insuredPartyFormik.touched}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="number"
//             label="Home Phone"
//             value={initVals.insuredHomePhone}
//             name="insuredHomePhone"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="number"
//             label="Work Phone"
//             value={initVals.insuredWorkPhone}
//             name="insuredWorkPhone"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="number"
//             label="Ext"
//             value={initVals.insuredExt}
//             name="insuredExt"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//           />
//         </Grid>
//         <Grid item md={3}>
//           <CustomField
//             type="text"
//             label="SSN"
//             value={initVals.insuredSSN}
//             name="insuredSSN"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//              mask="999-99-999"
//           />
//         </Grid>
//         <CustomAddress
//           formik={insuredPartyFormik}
//           fieldValues={{
//             countryId: insuredPartyFormik.values.insuredCountryId,
//             country: insuredPartyFormik.values.insuredCountryName,
//             countryName: "insuredCountryName",
//             stateId: "insuredStateId",
//             stateName: "insuredStateName",
//             state: insuredPartyFormik.values.insuredStateName,
//             cityId: "insuredCityId",
//             cityName: "insuredCityName",
//             city: insuredPartyFormik.values.insuredCityName,
//             zipCode: insuredPartyFormik.values.insuredZipCode,
//             zipCodeId: "insuredZipCodeId",
//             zipName: "insuredZipCode",
//           }}
//         />

//         <Grid item md={12}>
//           <CustomField
//             type="text"
//             label="Address"
//             value={initVals.insuredAddress}
//             name="insuredAddress"
//             handleBlur={insuredPartyFormik.handleBlur}
//             handleChange={insuredPartyFormik.handleChange}
//             isRequired={true}
//               error={insuredPartyFormik.errors}
//               touched={insuredPartyFormik.touched}
//             isOutlined={false}
//           />
//         </Grid>

//         <CustomModal
//         open={openInsuredPartyMod}
//         handleClose={() => setOpenInsuredPartyMod(false)}
//         heading={"Insured Party"}
//         width={'60%'}

//       >
//         <CustomPreview
//          rows={
//           getInsuredPartyData?.result?.length > 0
//             ?  getInsuredPartyData?.result?.map((el) => ({
//                 id: el?.insuredId,
//                 ...el,
//               }))
//             : []
//         }
//         columns={insuredCol}
//         handleGetAll={getInsuredPartyAction}
//         isModal={true}
//         searchLabel="Search By First Name La"
//         moduleHeading={"Billing Provider"}
//         />
//       </CustomModal>
//       </Grid>
//     </>
//   );
// };

// export default InusredPartyForm;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Stack
  //  Box
   } from "@mui/material";
import CustomField from "../../components/CustomField";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomModal from "../../components/CustomModal";
import CustomPreview from "../../layout/CustomPreview";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import CustomAddress from "../../components/CustomAddress";
import CustomButton from "../../components/CustomButton";
import { insuredPartySchema } from "../../schemas/patientSchema";
import { useFormik } from "formik";
import { postData } from "../../config/axiosFunctions";
import { InsuredInfo } from "../../utils/formikInitValues";
import { insuredCol } from "../../utils/gridCols";
import { getInsuredPartyAction } from "../../features/actions/insuredPartyActions";
// import { useNavigate } from "react-router-dom";

const InsuredPartyForm = ({
  priorityId,
  priorityOpt,
  setOpenModalForOther,
  insuranceFormik,
}) => {
  // const navigate = useNavigate();
  const [genderOpt, setGenderOpt] = useState([]);
  const [openInsuredPartyMod, setOpenInsuredPartyMod] = useState(false);

  const insuredPartyFormik = useFormik({
    initialValues: InsuredInfo,
    validationSchema: insuredPartySchema,
    onSubmit: async (values) => {
      try {
        const response = await postData(`${path}/insuredParty`, values);
        insuranceFormik.setValues((prevVals) => ({
          ...prevVals,
          insuredPartyId: response?.result?.insuredId,
          insuredFirstName: response?.result?.insuredFirstName,
          insuredLastName: response?.result?.insuredLastName,
        }));
        setOpenModalForOther(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { getInsuredPartyData } = useSelector((state) => state.insuredParty);

  const dataFetchUrl = {
    gender: "/ct-genderIdentity",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(dataFetchUrl.gender, setGenderOpt);
  }, []);

  useEffect(() => {
    if (priorityId) {
      insuredPartyFormik.setFieldValue("insuredPriorityTypeId", priorityId);
      const priorityType = priorityOpt.find(
        (value) => value?.priorityTypeId === priorityId
      );
      insuredPartyFormik.setFieldValue(
        "insuredPriorityType",
        priorityType?.priorityType
      );
    }
  }, [priorityId]);

  const setInsuredValues = (row) => {
    console.log("handleClickRow", row);
    insuranceFormik.setValues((prevVals) => ({
      ...prevVals,
      insuredFirstName: row.insuredFirstName,
      insuredLastName: row.insuredLastName,
      insuredPartyId: row.insuredId,
    }));
    setOpenInsuredPartyMod(false);
    setOpenModalForOther(false);
  };

  return (
    <>
      <form
        onSubmit={insuredPartyFormik.handleSubmit}
        id="insuredPartyForm"
        className="formContent"
      >
        <Grid container spacing={2} rowGap={2}>
          <Grid item md={12}>
            <Stack width={"40%"}>
              <CustomButton
                isBlue={true}
                handleClick={() => setOpenInsuredPartyMod(true)}
              >
                Search Insured Party
              </CustomButton>
            </Stack>
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="First Name"
              value={insuredPartyFormik.values.insuredFirstName}
              name={"insuredFirstName"}
              handleChange={insuredPartyFormik.handleChange}
              handleBlur={insuredPartyFormik.handleBlur}
              isRequired={true}
              error={insuredPartyFormik.errors}
              touched={insuredPartyFormik.touched}
              isOutlined={false}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="Last Name"
              value={insuredPartyFormik.values.insuredLastName}
              name="insuredLastName"
              handleChange={insuredPartyFormik.handleChange}
              handleBlur={insuredPartyFormik.handleBlur}
              isRequired={true}
              error={insuredPartyFormik.errors}
              touched={insuredPartyFormik.touched}
              isOutlined={false}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="email"
              label="Email"
              value={insuredPartyFormik.values.insuredEmail}
              name="insuredEmail"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
            />
          </Grid>
          <Grid item md={3}>
            <CustomDatePicker
              dateLabel="Date of Birth"
              dateValue={insuredPartyFormik.values.insuredDateOfBirth}
              name="insuredDateOfBirth"
              handleDateChange={insuredPartyFormik.handleChange}
              handleDateBlur={insuredPartyFormik.handleBlur}
              formik={insuredPartyFormik}
            />
          </Grid>
          <Grid item md={3}>
            <CustomSelectBox2
              label="Gender"
              dropdownOptions={genderOpt?.map((opt) => ({
                id: opt?.genderIdentityId,
                value: opt?.genderIdentityName,
              }))}
              name="insuredGenderIdentityId"
              value={insuredPartyFormik?.insuredGenderIdentityId}
              handleChange={insuredPartyFormik.handleChange}
              handleBlur={insuredPartyFormik.handleBlur}
              formik={insuredPartyFormik}
              setValName={"insuredGenderIdentityName"}
              isSetName={true}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="Cell Phone"
              value={insuredPartyFormik.insuredCellPhone}
              name="insuredCellPhone"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
              isRequired={true}
              isOutlined={false}
               mask="(999) 999-9999"

            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="Home Phone"
              value={insuredPartyFormik.values.insuredHomePhone}
              name="insuredHomePhone"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
               mask="(999) 999-9999"
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="Work Phone"
              value={insuredPartyFormik.values.insuredWorkPhone}
              name="insuredWorkPhone"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
              mask="(999) 999-9999"
            />
          </Grid>
          {/* <Grid item md={3}>
            <CustomField
              type="text"
              label="Ext"
              value={insuredPartyFormik.values.insuredExt}
              name="insuredExt"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
              mask="(999) 999-9999"
            />
          </Grid> */}
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Ext"
              value={insuredPartyFormik.values.insuredExt}
              name="insuredExt"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="text"
              label="SSN"
              value={insuredPartyFormik.values.insuredSSN}
              name="insuredSSN"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
              mask="999-99-999"
            />
          </Grid>
          <CustomAddress
            formik={insuredPartyFormik}
            fieldValues={{
              countryId: insuredPartyFormik.values.insuredCountryId,
              country: insuredPartyFormik.values.insuredCountryName,
              countryName: "insuredCountryName",
              stateId: "insuredStateId",
              stateName: "insuredStateName",
              state: insuredPartyFormik.values.insuredStateName,
              cityId: "insuredCityId",
              cityName: "insuredCityName",
              city: insuredPartyFormik.values.insuredCityName,
              zipCode: insuredPartyFormik.values.insuredZipCode,
              zipCodeId: "insuredZipCodeId",
              zipName: "insuredZipCode",
            }}
          />

          <Grid item md={12}>
            <CustomField
              type="text"
              label="Address"
              value={insuredPartyFormik.values.insuredAddress}
              name="insuredAddress"
              handleBlur={insuredPartyFormik.handleBlur}
              handleChange={insuredPartyFormik.handleChange}
              isRequired={true}
              error={insuredPartyFormik.errors}
              touched={insuredPartyFormik.touched}
              isOutlined={false}
            />
          </Grid>

          <Grid
            item
            md={12}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              textAlign: "right",
              background: "#fff",
            }}
          >
            <CustomButton
              type="submit"
              isBlue={true}
              padding="5px 15px"
              formId="insuredPartyForm"
              onClick={insuredPartyFormik.handleSubmit}
            >
              Add
            </CustomButton>
          </Grid>
        </Grid>
      </form>
      <CustomModal
        open={openInsuredPartyMod}
        handleClose={() => setOpenInsuredPartyMod(false)}
        heading={"Insured Party"}
        width={"60%"}
      >
        <CustomPreview
          rows={
            getInsuredPartyData?.result?.length > 0
              ? getInsuredPartyData?.result?.map((el) => ({
                  id: el?.insuredId,
                  ...el,
                }))
              : []
          }
          columns={insuredCol}
          handleGetAll={getInsuredPartyAction}
          isModal={true}
          searchLabel="Search By First Name Last Name"
          moduleHeading={"Billing Provider"}
          handleCell={setInsuredValues}
        />
      </CustomModal>
    </>
  );
};

export default InsuredPartyForm;


