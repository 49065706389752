import {
  Box,
  Button,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  useTheme,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import ClaimInfo from "./ClaimInfo";
import ClaimCharges from "./ClaimCharges";
import { useFormik } from "formik";
import { useSpring, animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import CustomDialog from "../../../components/CustomDialog";
import {
  getClaimAcivityAction,
  getClaimAction,
  getClaimByNumberAction,
  getClaimChargesHistoryAction,
  updateClaimAction,
} from "../../../features/actions/claimAction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import path from "../../../config/apiUrl";
import fingerTouchLogo from "../../../assets/finger_touch.png";
import { Dropdown } from "react-bootstrap";
import { pdf } from "@react-pdf/renderer";
import CmsForm15 from "../../../components/pdfs/CmsForm15";
import AdditionInfo from "./AdditionInfo";
import AmbulanceInfo from "./AmbulanceInfo";
import "../../patient/createpatient.css";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import "../claim/claim-styling/claim.css";
import { getData, updateData } from "../../../config/axiosFunctions";
import CmsUB04 from "../../../components/pdfs/CmsUB04";
import { toast } from "react-toastify";
import CustomModal from "../../../components/CustomModal";
import { handleCancel } from "../../../utils/customFunctions";
import PreviewEdi from "../edi/PreviewEdi";
import PrintIcon from "@mui/icons-material/Print";
import ClaimSummary from "../../../components/pdfs/ClaimSummary";
import { useParams } from "react-router-dom";
import CustomSummary from "../../../components/CustomSummary";
// import Loading from "../../../components/Loading";
import {
  claimInitVal,
  professionalClaimValues,
} from "../../../utils/formikInitValues";
import { Link } from "react-router-dom";
import MultiButton from "../../../components/MultiButton";
import CustomDataGrid from "../../../components/CustomDataGrid";
import {
  claimChargesHistory,
  claimChargesHistoryCol,
} from "../../../utils/gridCols";
import { claimSchema } from "../../../schemas/patientSchema";
import parse from "html-react-parser";
import { resetSingleClaimData } from "../../../features/slice/claimSlice";
const UpdateClaim = () => {
  // const styles = {
  //   container: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //   },
  //   rightContent: {
  //     marginLeft: 'auto', // Optional, ensures right content is pushed to the right
  //   },
  // };

  const linkStyle = {
    textDecoration: "none",
    fontSize: "1.8rem",
    color: "#0071c5",
  };

  const theme = useTheme();
  // const { claimNumber } = useParams();
  const { claimId } = useParams();

  const navigate = useNavigate();
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [openEdiModal, setOpenEdiModal] = useState(false);
  const [openClaimHistoryMod, setOpenClaimHistoryMod] = useState(false);
  const [ediData, setEdiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  // const [openAlertDialog, setOpenAlertDialog] = useState(false);
  //   get claims
  const { claimChargesHistory } = useSelector((state) => state.claim);
  const singleClaimData = useSelector((state) => state.claim.singleClaimData);
  // const getClaimLoading = useSelector((state) => state.claim.loading);
  const getClaimLoading = useSelector(
    (state) => state.claim.getPatientClaimDetailLoad
  );
  // const findClaim = singleClaimData.result?.find(
  //   (el) => el.claimNumber === Number(claimNumber)
  // );

  // const claimActivityPathSingle = `/patients/acitvity/${singleClaimData?.patientAccountNo}/singleclaim/${singleClaimData?.claimNumber}`;
  // const claimActivityPathMultiple = `/patients/acitvity/${singleClaimData?.patientId}/multipleclaim/${singleClaimData?.claimNumber}`;

  const [tabValue, setTabValue] = useState(0);
  const [openClaimSummary, setOpenClaimSummary] = useState(false);
  const [claimChargesDto, setClaimChargesDto] = useState(
    singleClaimData?.claimChargesDto || []
  );

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    dispatch(resetSingleClaimData());
    navigate("/claims");
  };

  // fetch pdf data
  // const fetchPdfData = async () => {
  //   try {
  //     if (singleClaimData?.claimTypeId === 1) {
  //       const cmsPdfData = await axios.get(
  //         `${path}/cmsForm?claimNo=${claimNumber}`
  //       );
  //       if (cmsPdfData.status === 200) {

  //         const data = cmsPdfData.data;
  //         setPdfData(data.result);
  //       } else {
  //         setPdfData([]);
  //       }
  //     } else if (singleClaimData?.claimTypeId === 2) {
  //       const ub04Pdf = await getData(`${path}/ub04Form?claimNo=${claimNumber}`);
  //       setPdfData(ub04Pdf.result);
  //     }
  //   } catch (err) {
  //     console.log(err, "pdfError");
  //   }
  // };

  // useEffect(() => {
  //   fetchPdfData();
  // }, []);
  //   fetch diagnosis
  // const fetchAllDiagnosis = async () => {
  //   try {
  //     const response = await axios.get(`${path}/ct-diagnosisCode`);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setDiagnosisData(data.result);
  //     } else {
  //       setDiagnosisData([]);
  //     }
  //   } catch (error) {
  //     throw new Error(error);
  //   }
  // };

  const dispatch = useDispatch();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  // ... other state variables

  const formik = useFormik({
    // initialValues: initialValues,
    initialValues: singleClaimData || professionalClaimValues,
    validationSchema: claimSchema,
    // validationSchema: claimSchema,
    enableReinitialize: true, // Formik will reinitialize when `singleClaimData` changes
    onSubmit: (values, action) => {
      console.log(values, "valuesClaim");

      const emptyClaimCharged = values.claimChargesDto.length === 0;
      if (emptyClaimCharged) {
        toast.error("At least one Procedure Code is required");
      } else {
        console.log(values, "submittedClaim");
        try {
          dispatch(
            updateClaimAction({
              claimId: singleClaimData?.claimId,
              ...values,
            })
          ).then((response) => {
            if (response.type === "updateClaim/fulfilled") {
              setDisable(true);
              navigate("/claims");
              dispatch(resetSingleClaimData());
              action.resetForm();
            }
          });
        } catch (error) {
          throw error;
        }
      }
    },
  });

  useEffect(() => {
    if (singleClaimData && !getClaimLoading && singleClaimData.alertMain) {
      if (singleClaimData.alertMain.activeCount > 0) {
        setOpenAlertDialog(true);
      } else {
        setOpenAlertDialog(false);
      }
    }
  }, [singleClaimData, getClaimLoading, claimId, dispatch]);

  useEffect(() => {
    if (singleClaimData) {
      formik.setValues((preVals) => ({
        ...preVals,
        ...singleClaimData,
      }));
      console.log(formik.values, "formikValuesClaim>><<");
    }
  }, [singleClaimData, dispatch, claimId]);

  console.log(formik.values, "claimUpdatesFormik");
  let flage = false;
  useEffect(() => {
    if (!flage) {
      flage = true;
      try {
        dispatch(getClaimChargesHistoryAction(claimId));
        dispatch(getClaimByNumberAction(claimId));

        // setOpenAlertDialog(true);
      } catch (error) {
        // setOpenAlertDialog(false);
        console.log(error, "Error in fetching claim by number");
        const conform = window.confirm(
          "Something Went Wrong, You want to go back?"
        );
        if (conform) {
          navigate("/claims");
        }
        // throw error;
      }
    }
  }, [dispatch]);

  // pdf preview handle
  const handlePreviewClick = async () => {
    const pdfBlob = await pdf(
      singleClaimData?.claimTypeId === 1 ? (
        <CmsForm15 data={pdfData} />
      ) : (
        <CmsUB04 pdfData={pdfData} />
      )
    ).toBlob();
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  // handle delete claim
  const handleDeleteClaim = async () => {
    try {
      const response = await axios.put(
        // `${path}/claim/claimDelete/${Number(claimNumber)}`
        `${path}/claim/claimDelete/${Number(claimId)}`
      );

      if (response.status === 200) {
        console.log(response, "responsed");
        if (!response.result) {
          setOpenDeleteModal(false);
          toast.error(response.data?.message);
        } else {
          toast.success("Claim deleted successfully!");
          navigate("/claims");
        }
      }
    } catch (error) {
      console.log(error, "Error in delete");
    }
  };

  // const date = singleClaimData?.alertMain?.alertDto?.filter((value) => {
  //   // if (value?.alertStartDate => new Date().toLocaleDateString()) {
  //   if (new Date().toLocaleDateString() < value?.alertStartDate) {
  //     console.log(value, "value");

  //     return value;
  //   }else{
  //     return []
  //   }
  // });

  // console.log(date);

  // const handleSaveAndPreviewClick = async () => {
  //   try {
  //     const updateValues = {
  //       ...formik.values,
  //       claimChargesUpdatedDto: formik.values.claimChargesDto,
  //     };
  //     await updateData(`${path}/claim/${singleClaimData?.claimNumber}`, {
  //       claimNumber: singleClaimData?.claimNumber,
  //       ...updateValues,
  //     }).then((e) => {
  //       fetchPdfData().then((data) => {
  //         navigate("/claims");
  //         handlePreviewClick();
  //       });
  //     });
  //   } catch (error) {
  //     toast.error("Error while save and preview");
  //   }
  // };
  // const handlePreviewEdi = async () => {
  //   setOpenEdiModal(true);
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${path}/ediFile?claimNo=${singleClaimData?.claimNumber}`
  //     );

  //     if (response.status === 200) {
  //       setEdiData(response?.data?.result);
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     console.log("Error in getting EDI: ", err);
  //   }
  // };

  const claimSummaryProps = useSpring({
    width: openClaimSummary ? 450 : 0,
    right: openClaimSummary ? 0 : -70, // Start off-screen
    from: { right: -30 },
    config: { duration: 600 }, // Adjust duration as needed
  });

  // handleClaimSummary
  const handleClaimSummary = useCallback(() => {
    setOpenClaimSummary(true);
  }, []);

  // handleClaimActivity
  // const handleSingleClaimActivity = useCallback((claimNumber) => {
  //   try {
  //     dispatch(getClaimAcivityAction(claimNumber));
  //   } catch (error) {
  //     console.log(error, ": Error in getting single claim acivity");
  //   }
  // }, []);
  console.log(formik.values, "formikValuesClaim");

  return (
    <>
      {/* {getClaimLoading ? (
        <Loading />
      ) : ( */}
      <>
        <CustomModal
          open={openClaimHistoryMod}
          handleClose={() => setOpenClaimHistoryMod(false)}
          heading={"Claim History"}
        >
          <TableContainer sx={{ padding: "10px" }} component={Paper}>
            <Table>
              <TableHead
                style={{
                  background: theme.palette.infocodesHeader.primary,
                  color: theme.palette.sidebarDDownText.primary,
                }}
                // id="t-header"
              >
                <TableRow>
                  {claimChargesHistoryCol.map((col) => {
                    return <TableCell>{col.headerName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {claimChargesHistory &&
                  claimChargesHistory.length > 0 &&
                  claimChargesHistory?.map((el, id) => {
                    return (
                      <>
                        <TableRow key={id}>
                          <TableCell>{el?.chargeId}</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        {el?.chargeHistoryDto &&
                          el.chargeHistoryDto.length > 0 &&
                          el?.chargeHistoryDto.map((v, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell></TableCell>
                                <TableCell>{v?.cptCode}</TableCell>
                                <TableCell>
                                  {new Date(v?.dateChanged).toDateString(
                                    "en-US"
                                  )}
                                </TableCell>
                                <TableCell>{v?.username}</TableCell>
                                <TableCell>{v?.billTo}</TableCell>
                                <TableCell>{v?.amount}</TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomModal>

        <Box margin="20px" paddingBottom="10px">
          {/* <MainHeading
      mainheading='Update Claim'
    >
        <Link 
        to={`/patient/update/${formik?.values?.patientId}`}
        style={linkStyle}
        >
        {formik?.values?.patientFirstName} {formik?.values?.patientLastName} ({formik?.values?.patientId})
      </Link>
    </MainHeading> */}

          <Stack
            direction="row"
            alignContent="center"
            columnGap={2}
            sx={{
              order: { xs: 1, sm: 1, md: 3 },
              // margin: "15px",
            }}
          >
            {/* <Dropdown
            style={
              {
                // color: theme.palette.btnCancelText.primary,
                // backgroundColor: theme.palette.cancelBtn.primary,
              }
            }
            className="print"
          >
            <Dropdown.Toggle
              className="printDropDown"
              id="dropdown-basic"
              style={{ padding: "10px" }}
            >
              <PrintIcon />
              Print
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handlePreviewClick}>
                Show Preview
              </Dropdown.Item>
              <Dropdown.Item onClick={handleSaveAndPreviewClick}>
                Show Preview & Save
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

            {/* <Dropdown
            style={
              {
                // color: theme.palette.btnCancelText.primary,
                // backgroundColor: theme.palette.cancelBtn.primary,
              }
            }
            className="print"
          >
            <Dropdown.Toggle
              className="printDropDown"
              id="dropdown-basic"
              style={{ padding: "10px" }}
            >
              <PrintIcon />
              Activity
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate(claimActivityPathSingle)}>
                For This Claim Only
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate(claimActivityPathMultiple)}
              >
                For All Claims
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
            {/* <CustomButton
            type="reset"
            form="claimForm"
            isBlue={false}
            handleClick={() => {
              handleCancel(formik, "/claims", navigate);
            }}
            isCancel={true}
          >
            Cancel
          </CustomButton> */}
            <CustomButton
              type="reset"
              form="claimForm"
              isCancel={true}
              handleClick={handleCancel}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              disabled={disable}
              variant="contained"
              formId="claimForm"
              isBlue={true}
              isSave={true}
            >
              Update
            </CustomButton>
            <CustomButton
              variant="contained"
              isBlue={false}
              handleClick={() => setOpenDeleteModal(true)}
              isDelete={true}
            >
              Delete
            </CustomButton>

            {/* <CustomButton isBlue={false} handleClick={handlePreviewEdi}>
            Preview Electronic Claim
          </CustomButton> */}
            {/* <MultiButton
            onSelect={(e) => {
              if (e?.id === 1) {
                formik.setFieldValue(
                  "claimTypeId",
                  formik.values.claimTypeId === 1 ? 2 : 1
                );
              } else if (e?.id === 2) {
              } else if (e?.id === 3) {
              } else if (e?.id === 4) {
                navigate("/claim-tracker", {
                  state: {
                    claimNumber: formik.values.claimNumber,
                  },
                });
              } else if (e?.id === 5) {
                setOpenClaimHistoryMod(true);
              } else if (e?.id === 6) {
                handlePreviewEdi();
              }
            }}
            title="More"
            multiOpts={[
              {
                id: 1,
                value: `Convert Claim To ${
                  formik.values.claimTypeId === 1
                    ? "Institutional"
                    : "Professional"
                }`,
              },
              // { id: 2, value: "Copy Claim" },
              { id: 3, value: "Check Claim Status" },
              { id: 4, value: "Track Claim Submission History" },
              { id: 5, value: "View Charges History" },
              { id: 6, value: "Preview Electronic Claim" },
              { id: 7, value: "Update Other Claim w/ Insurance Info" },
              // { id: 8, value: "Update Patient w/Insurance Info" },
            ]}
          /> */}
          </Stack>
          <Stack
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
            justifyContent="space-between"
            gap={"15px"}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                marginBottom: "10px",
                marginTop: "20px",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Tab
                sx={{ borderRadius: "10px 10px 0 0" }}
                className="tabsdesigning"
                label="Claim"
                value={0}
              />
              <Tab
                sx={{ borderRadius: "10px 10px 0 0" }}
                className="tabsdesigning"
                label="Charges"
                value={1}
              />
              <Tab
                sx={{ borderRadius: "10px 10px 0 0" }}
                className="tabsdesigning"
                label="Additional Info"
                value={2}
              />
              <Tab
                sx={{ borderRadius: "10px 10px 0 0" }}
                className="tabsdesigning"
                label="Ambulance Info"
                value={3}
              />
            </Tabs>
          </Stack>

          {getClaimLoading ? (
            <Loading />
          ) : (
            <form id="claimForm" onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  padding: "15px",
                  border: "1px solid lightgrey",
                }}
              >
                {tabValue === 0 && <ClaimInfo formik={formik} />}
                {tabValue === 1 && (
                  <ClaimCharges
                    formik={formik}
                    setClaimChargesDto={setClaimChargesDto}
                    claimChargesDto={claimChargesDto}
                    findClaim={singleClaimData}
                  />
                )}
                {tabValue === 2 && <AdditionInfo formik={formik} />}
                {tabValue === 3 && <AmbulanceInfo formik={formik} />}
              </Box>
            </form>
          )}
        </Box>

        {/* modals */}

        {/* <CustomModal
        open={openEdiModal}
        width={"55%"}
        handleClose={() => setOpenEdiModal(false)}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <PreviewEdi data={ediData} />
          </div>
        )} */}
        {/* <PreviewEdi data={ediData} /> */}
        {/* </CustomModal> */}

        {openClaimSummary ? (
          <animated.div
            style={{
              width: "30%",
              position: "fixed",
              top: "14%",
              overflow: "hidden",
              zIndex: 1,
              ...claimSummaryProps, // Apply animated styles
            }}
          >
            <ClaimSummary
              formik={formik}
              // claimNo={Number(claimNumber)}
              handleClose={() => setOpenClaimSummary(false)}
            />
          </animated.div>
        ) : (
          <Button
            style={{ background: theme.palette.accSummary.primary }}
            className="accountSummaryBtn"
            onClick={handleClaimSummary}
          >
            <span>
              <img
                src={fingerTouchLogo}
                alt="finger-touch-logo"
                style={{ marginRight: "5px" }}
              />
            </span>
            <span>Claim Summary</span>
          </Button>
        )}

        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />

        <CustomDialog
          open={openDeleteModal}
          isWarning={true}
          handleClose={() => setOpenDeleteModal(false)}
          handleConfirm={handleDeleteClaim}
          handleCancel={() => setOpenDeleteModal(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
        {/* alert model */}

        <CustomDialog
          title={`Alerts for claim ${singleClaimData?.patientFirstName}, ${singleClaimData?.patientLastName}`}
          open={openAlertDialog}
          handleClose={() => setOpenAlertDialog(false)}
          confirmButtonTitle={"Ok"}
          handleConfirm={() => setOpenAlertDialog(false)}
        >
          <Stack gap="5px">
            {singleClaimData?.alertMain?.alertDto
              .filter((alertData) => {
                const alertStartDate = new Date(alertData?.alertStartDate);
                const today = new Date();
                return alertStartDate <= today; // Only include alerts where the start date is <= today
              })
              .map((alertData) => (
                <Paper
                  elevation={3}
                  key={alertData?.alertId}
                  sx={{ padding: "8px 12px", minWidth: "400px" }}
                >
                  <Typography component={"h4"}>
                    {parse(alertData?.message)}
                  </Typography>

                  <Stack>
                    <Typography
                      component="p"
                      fontSize={"1.3rem"}
                      fontWeight={300}
                    >
                      By: {alertData?.userName} on {alertData?.createdDate}
                    </Typography>
                    <Typography
                      component="p"
                      fontSize={"1.3rem"}
                      fontWeight={300}
                    >
                      Patient: {singleClaimData?.patientFirstName},{" "}
                      {singleClaimData?.patientLastName}
                    </Typography>
                  </Stack>
                </Paper>
              ))}
          </Stack>
        </CustomDialog>

        {/* alert model */}
      </>
      {/* )} */}
    </>
  );
};

export default UpdateClaim;
