import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const createPaymentAction = createAsyncThunk(
  "createPayment",
  async (data) => {
    try {
      const response = await postData(`${path}/depositSlip`, data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const getAllPayment = createAsyncThunk(
  "getAllPayments",
  async (data) => {
    try {
      const response = await getData(`${path}/Updatedpayment`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "action error");
      throw new Error(error);
    }
  }
);

export const getAllPaymentBySearchQuery = createAsyncThunk(
  "getAllPayments",
  async (query) => {
    try {
      const response = await getData(`${path}/payment/SearchPayment?${query}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "action error");
      throw new Error(error);
    }
  }
);
