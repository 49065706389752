// export default TextEditor;
// import React, { useState } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

// const TextEditor = ({ value, onChange }) => {
//   return (
//     <div className="editor-container">
//       <ReactQuill
//         value={value}
//         onChange={onChange}
//         modules={TextEditor.modules}
//         formats={TextEditor.formats}
//       />
//     </div>
//   );
// };

// TextEditor.modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["bold", "italic", "underline"],
//     ["link"],
//     [{ align: [] }],
//     ["clean"],
//   ],
// };

// TextEditor.formats = [
//   "header",
//   "font",
//   "list",
//   "bullet",
//   "bold",
//   "italic",
//   "underline",
//   "link",
//   "align",
// ];

// export default TextEditor;
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ value, onChange,error }) => {
  const quillRef = useRef(null);

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      // Attach any custom logic here if needed
    }
  }, []);

  return (
    <div className="editor-container">
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
      />
      <p style={{color:"red"}}>{error}</p>
    </div>
  );
};

TextEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    ["link"],
    [{ align: [] }],
    ["clean"],
  ],
};

TextEditor.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "align",
];

export default TextEditor;
