import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getAdjustmentAction = createAsyncThunk(
  "getAdjustments",
  async (page) => {
    try {
      console.log(page);
      
      const newPage = page.page+1
      const response = await getData(`${path}/AdjustmentCode?page=${newPage}&pageSize=20`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const newAdjustmentAction = createAsyncThunk(
  "createAdjustment",
  async (data) => {
    try {
      const response = await postData(`${path}/AdjustmentCode`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getAdjustmentByIdAction = createAsyncThunk(
  "getAdjustmentById",
  async (id) => {
    try {

      const response = await getData(`${path}/AdjustmentCode/AdjustmentCodeById?Id=${id}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const getAdjustmentBySearchTermAction = createAsyncThunk(
  "getAdjustments",
  async ({ searchTerm, page }) => {
    console.log("search", searchTerm);
    console.log("checkpage", page);

    try {
      const response = await getData(
        `${path}/AdjustmentCode/SearchAdjustmentCode?searchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      console.log(response, "action adjustment by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateAdjustmentAction = createAsyncThunk(
  "updateAdjustment",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/AdjustmentCode/${data.adjustmentCodeId}`,
        data
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);
export const getSingleAdjustmentAction = createAsyncThunk(
  "getSingleAdjustment",
  async (id) => {
    console.log(id);
    try {
      const response = await getData(
        `${path}/AdjustmentCode/AdjustmentCodeById?Id=${id}`,
        
      );
      if (response) {
        console.log(response);
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);