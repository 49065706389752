import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import path from "../../../config/apiUrl";
import { Box, CircularProgress } from "@mui/material";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { DataGrid } from "@mui/x-data-grid";
import { tosCodeCols } from "../../../utils/gridCols";
import CustomPreview from "../../../layout/CustomPreview";
import { useSelector } from "react-redux";
import { getAllTosCodesActios } from "../../../features/actions/tosAction";

const TosCodes = ({ handleClose, handleCellClick }) => {
  const {getAllTos} = useSelector((state) => state.tosCodes);
  console.log(getAllTos,"tosCode")
  const [tosDetail, setTosDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const fetchTosCodes = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${path}/ct-tosCode`);
  //     if (response.status === 200) {
  //       const data = await response.data;
  //       setTosDetail(data.result);
  //     } else {
  //       setTosDetail([]);
  //     }
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const rows = tosDetail.map((el) => ({
  //   id: el.tosCodeId,
  //   tosCode: el.tosCode,
  //   tosDescription: el.tosDescription,
  // }));
  

  // const columns = [
  //   {
  //     field: "tosCode",
  //     headerName: "Codes",
  //     minWidth: 100,
  //     flex: 2,
  //     headerAlign: "center",
  //     align: "center",
  //     headerClassName: "header-bg",
  //     cellClassName: "cell-content",
  //   },
  //   {
  //     field: "tosDescription",
  //     headerName: "Description",
  //     minWidth: 100,
  //     flex: 3,
  //     headerAlign: "left",
  //     align: "left",
  //     headerClassName: "header-bg",
  //     cellClassName: "cell-content",
  //   },
  // ];


  // useEffect(() => {
  //   fetchTosCodes();
  // }, []);

  const rows =
  getAllTos?.result?.data && getAllTos?.result?.data?.length > 0
    ? getAllTos?.result?.data?.map((el) => ({
        id: el.tosCodeId
        ,
        ...el,
      }))
    : [];

  return (
    // <Box sx={{ width: "100%" }}>
    //   {isLoading ? (
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: "50%",
    //         left: "50%",
    //         transform: "translate(-50%, -50%)",
    //       }}
    //     >
    //       <CircularProgress />
    //     </Box>
    //   ) : (
      
    //     <CustomDataGrid
    //       rows={rows}
    //       columns={columns}
    //       handleCellClick={handleCellClick}
    //     />
    //   )}
    // </Box>
    <CustomPreview
    rows={rows}
    columns={tosCodeCols}
    handleGetAll={getAllTosCodesActios}
    paginationDetail={getAllTosCodesActios}
    // handleBySearch={}
    moduleHeading={"Pos Codes"}
    handleCell={(params) => handleCellClick(params)}
    searchLabel="Search by Codes"
  ></CustomPreview>
  );
};

export default TosCodes;




