import React, { useCallback, useEffect, useState } from "react";
import PatientInfo from "./PatientInfo";
import { useFormik } from "formik";
import { Box, Stack, Tab, Tabs, Typography, Button, Link } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { newPatientAction } from "../../features/actions/createPatientAction";
import { Dropdown } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import "./createpatient.css";
import { patientInitValues } from "../../utils/formikInitValues";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import BillingInfo from "./BillingInfo";
import ClaimDefaults from "./ClaimDefaults";
import MainInsurance from "./MainInsurance";
import MainHeading from "../../components/MainHeading";
import { useTheme } from "@mui/material";
import { patientSchema } from "../../schemas/patientSchema";
import { getData } from "../../config/axiosFunctions";
import { handleCancel } from "../../utils/customFunctions";
import CustomSummary from "../../components/CustomSummary";
import AccountSummary from "../../components/AccountSummary";
import path from "../../config/apiUrl";
import fingerTouchLogo from "../../assets/finger_touch.png";
import { Done, Info, Warning } from "@mui/icons-material";
import CheckBox from "../../components/CheckBox";
import { getDuplicatePatientsAction } from "../../features/actions/duplicatePatientAction";
import CustomModal from "../../components/CustomModal";
import { DataGrid } from "@mui/x-data-grid";
import { patientCols } from "../../utils/gridCols";
import { toast } from "react-toastify";

const CreatePatient = () => {
  const confirm = useConfirm();
  const { loading } = useSelector((state) => state.patient);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const dispatch = useDispatch();
  const [openAccountSummary, setOpenAccountSummary] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [disable,setDisable] = useState(false)
  const [duplicatePatData, setDuplicatePatData] = useState([]);
  const [openDuplicateMod, setOpenDuplicateMod] = useState(false);
  const handleTabChange = (event, newValue) => {
    setIsFormVisible(false);
    setTimeout(() => {
      setTabValue(newValue);
      setIsFormVisible(true);
    }, 400);
  };

  const handleFormSubmit = (formValues,action) => {
    try {
      console.log(formValues, "gettinFormVals")
      console.log(action, "action")
      dispatch(newPatientAction(formValues)).then((response) => {
        console.log(response,"response patient")
        if (response.type === "createPatient/fulfilled") {
          setDisable(true)
          navigate("/patients");
        }
      });
      // action.resetForm();
    } catch (error) {
      console.error("Error creating patient:", error);
    }
    // try {
    //   console.log(formValues, "gettinFormVals");
    //   dispatch(newPatientAction(formValues));
    // } catch (error) {
    //   console.error("Error creating patient:", error);
    // }
  };

  const formik = useFormik({
    initialValues: patientInitValues,
    validationSchema: patientSchema,
    onSubmit: (values, action) => {
      const newValues = {
        ...values,
        practiceId: values?.claim_DefaultDto?.practiceId,
        // practiceSequenceNo: values?.claim_DefaultDto?.practiceSequenceNo,
      };
      if (
        newValues.claim_DefaultDto?.defaultProviderFirstName === "" &&
        newValues.claim_DefaultDto?.defaultProviderLastName === "" &&
        newValues.claim_DefaultDto?.defaultProviderId === 0
      ) {
        toast.error("Add Provider");
        return;
      }
      console.log(newValues, "newValues");
        console.log(newValues, "newValues")
      try {
        handleFormSubmit(newValues,action);
      } catch (error) {
        console.error("Error creating patient:", error);
      }
      // action.resetForm();
      // navigate("/patients");
    },
  });
  
// const formik = useFormik({
//   initialValues: patientInitValues,
//   validationSchema: patientSchema,
//   onSubmit: (values, action) => {
//     console.log(values, "patientValssss");

//     // Initialize newValues with values from the form
//     let newValues = {
//       ...values,
//       practiceId: values?.claim_DefaultDto?.practiceId,
//       // practiceSequenceNo: values?.claim_DefaultDto?.practiceSequenceNo,
//     };

//     // Check if the provider data is missing in newValues
//     if (
//       newValues.claim_DefaultDto?.defaultProviderFirstName === "" &&
//       newValues.claim_DefaultDto?.defaultProviderLastName === "" &&
//       newValues.claim_DefaultDto?.defaultProviderId === 0
//     ) {
//       console.log("No provider");
//       toast.error("No default provider");
//       return;
//     }

//     console.log(newValues, "newValues");

//     // If provider data is valid, proceed with form submission
//     try {
//       handleFormSubmit(newValues, action);
//     } catch (error) {
//       console.error("Error creating patient:", error);
//     }
//   },
// });




const theme = useTheme();
  const {
    firstName,
    lastName,
    dateOfBirth,
    address,
    cityName,
    stateName,
    cellPhone,
    zipCode,
    genderIdentityStatusId,
    genderIdentityStatusName,
  } = formik.values;
  // patient status
  const patientStatus =
    firstName &&
    lastName &&
    dateOfBirth &&
    cityName &&
    zipCode &&
    genderIdentityStatusId &&
    address &&
    cellPhone &&
    stateName;
  const handleMoreClick = () => {
    confirm({
      title: "You must save the patient in order to perform this action.",
      confirmationText: "Save",
    })
      .then(() => {
        formik.handleSubmit();
      })
      .catch(() => {});
  };

  // set default provider
let flage = false
  useEffect(() => {
    if(!flage){
flage = true
      const setDefaultProviders = async () => {
        try {
          const response = await getData(`${path}/provider?page=1&pageSize=20`);
        if (
          response?.result?.providers?.length > 0 &&
          formik.values.claim_DefaultDto.defaultProviderId === 0
        ) {
          const defaultProvider = response.result.providers[0];
          formik.setValues((prevValues) => ({
            ...prevValues,
            claim_DefaultDto: {
              ...prevValues.claim_DefaultDto,
              defaultProviderId: defaultProvider.providerId,
              defaultProviderFirstName: `${defaultProvider.providerFirstName}`,
              defaultProviderLastName: `${
                defaultProvider.providerLastName
                  ? defaultProvider.providerLastName
                  : ""
                  }`,
              // defaultProviderSeqNo: defaultProvider.providerSequenceNo,
              providerId: defaultProvider.providerId,
              practiceAddress: defaultProvider.practiceAddress,
              npiNo: defaultProvider.providerNPINo,
              practiceName: defaultProvider.practiceName,
              // practiceSequenceNo: defaultProvider.practiceSequenceNo,
              practiceId: defaultProvider.practiceId,
            },
          }));
        }
      } catch (e) {
        console.log(e, ": check default provider");
      }
    };
    
    setDefaultProviders();
  }
  }, []);


  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/patients");
  };
  const accountSummaryProps = useSpring({
    width: openAccountSummary ? 450 : 0,
    right: openAccountSummary ? 0 : -70,
    from: { right: -30 },
    config: { duration: 600 },
  });
  const handleAccountSummary = useCallback(() => {
    setOpenAccountSummary(true);
  }, []);

  // check duplicate patient
  useEffect(() => {
    if (firstName && lastName && dateOfBirth && genderIdentityStatusName) {
      const checkingValues = {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        genderName: genderIdentityStatusName,
      };
      dispatch(getDuplicatePatientsAction(checkingValues)).then(({ payload }) =>
        setDuplicatePatData(payload?.result)
      );
    }
  }, [firstName, lastName, dateOfBirth, genderIdentityStatusName]);
  return (
    <>
      <Box margin="20px">
        {/* <MainHeading mainheading={"Create Patient"} /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            marginBottom="15px"
          >
            <Typography fontWeight={"700 !important"} component={"h5"}>
              {patientStatus ? (
                <Done color="success" fontSize="large" />
              ) : (
                <Warning color="warning" fontSize="large" />
              )}{" "}
              Patient is {patientStatus ? "complete" : "incomplete"}
            </Typography>
          </Stack>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            marginBottom="15px"
            columnGap={"5px"}
          >
            {/* <Typography fontWeight={"700 !important"} component={"h5"}>{patientStatus ? <Done color="success" fontSize="large" /> : <Warning color="warning" fontSize="large" />} Patient is {patientStatus ? "complete" : "incomplete"}</Typography> */}

            <Typography fontWeight={"600 !important"} component={"span"}>
              {duplicatePatData?.length > 0 ? (
                <Info color="error" fontSize="large" />
              ) : (
                <Done color="success" fontSize="large" />
              )}
            </Typography>
            {duplicatePatData?.length > 0 ? (
              <Link
                onClick={() => setOpenDuplicateMod(true)}
                fontWeight={"600 !important"}
                sx={{ cursor: "pointer" }}
                component={"h5"}
              >
                View duplicate patient
              </Link>
            ) : (
              <Typography fontWeight={"600 !important"} component={"h5"}>
                No duplicate patient found!
              </Typography>
            )}
          </Stack>
        </Box>
        <Stack
          sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
          justifyContent="space-between"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              order: { xs: 2, sm: 2, md: 1 },
              marginBottom: "10px",
            }}
          >
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Patient Info"
              value={0}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Insurance Information"
              value={1}
            />

            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Billing Info"
              value={2}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Claim Defaults"
              value={3}
            />
          </Tabs>

          <Stack
            direction="row"
            alignContent="center"
            columnGap={2}
            sx={{
              order: { xs: 1, sm: 1, md: 4 },
              alignItems: "center",
              // margin: "15px",
            }}
          >
            <CustomButton
              type="reset"
              color="error"
              padding={"7px"}
              isBlue={false}
              isCancel={true}
              sx={{
                marginRight: "15px",
              }}
              handleClick={handleCancel}
            >
              Cancel
            </CustomButton>

            <CustomButton
          disabled={disable}
              type="submit"
              isBlue={true}
              isSave={true}
              padding={"7px"}
              margin={"0 10px 0"}
              formId="patientForm"
              // disabled={loading}
              sx={{
                marginRight: "30px",
              }}
            >
              {loading ? "Saving..." : "Save"}
            </CustomButton>
            <Dropdown
              style={
                {
                  // color: theme.palette.btnCancelText.primary,
                  // backgroundColor: theme.palette.cancelBtn.primary,
                }
              }
              className="print"
            >
              <Dropdown.Toggle
                className="printDropDown"
                id="dropdown-basic"
                style={{ padding: "10px" }}
              >
                <PersonIcon />
                Eligibility
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                // onClick={handleSaveAndPreviewClick}
                >
                  New Eligibility Check
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              style={
                {
                  // color: theme.palette.btnCancelText.primary,
                  // backgroundColor: theme.palette.cancelBtn.primary,
                }
              }
              className="print"
            >
              <Dropdown.Toggle
                className="printDropDown"
                id="dropdown-basic"
                style={{ padding: "10px" }}
              >
                <MoreVertIcon />
                More
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Professional Claim for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Institutional Claim for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Appointment for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Post Payment for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Payment Plan
                </Dropdown.Item>
                <Dropdown.Item>Open in Manage Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Stack>
        </Stack>

        <form
          id="patientForm"
          onSubmit={formik.handleSubmit}
          className={`formContainer ${!isFormVisible ? "hidden" : ""}`}
        >
          {tabValue === 0 && <PatientInfo formik={formik} />}
          {tabValue === 1 && <MainInsurance formik={formik} />}
          {tabValue === 2 && <BillingInfo formik={formik} />}
          {tabValue === 3 && <ClaimDefaults formik={formik} />}
        </form>
      </Box>

      {/* {openAccountSummary ? (
        <animated.div
          style={{
            width: "30%",
            position: "fixed",
            top: "40%",
            overflow: "hidden",
            zIndex: 1,
            ...accountSummaryProps, // Apply animated styles
          }}
        >
          <CustomSummary
            formik={formik}
            handleClose={() => setOpenAccountSummary(false)}
            isAlert={true}
            isNotes={true}
          />
        </animated.div>
      ) : (
        <Button
          style={{ background: theme.palette.accSummary.primary }}
          className="accountSummaryBtn"
          onClick={handleAccountSummary}
        >
          <span>
            <img
              src={fingerTouchLogo}
              alt="finger-touch-logo"
              style={{ marginRight: "5px" }}
            />
          </span>
          <span>Account Summary</span>
        </Button>
      )} */}

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />

      {/* duplicate patients modal */}
      <CustomModal
        heading={"Duplicate Patient"}
        height={"auto"}
        open={openDuplicateMod}
        handleClose={() => setOpenDuplicateMod(false)}
      >
        <DataGrid
          rows={
            duplicatePatData?.length > 0
              ? duplicatePatData?.map((el) => ({
                  id: el?.patientId,
                  ...el,
                }))
              : []
          }
          columns={patientCols}
          disableSelectionOnClick
          showCellRightBorder={true}
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
            "& .MuiTablePagination-root .MuiTablePagination-selectLabel, & .MuiTablePagination-root .MuiTablePagination-select":
              {
                display: "none",
              },
          }}
          autoHeight
          rowHeight={35}
        />
      </CustomModal>
    </>
  );
};

export default CreatePatient;
