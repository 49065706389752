import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

// export const  getAllTosCodesActios = createAsyncThunk("getTos" , async(page)=>{
//     try {
//         console.log(page);
        
//         const newPage = page?.page+1
//         const response = await getData(`${path}/ct-tosCode?page=${newPage}&pageSize=5`);
// console.log(response);

//     if (response) {
//       return response;
//     } 
//     } catch (error) {
//         console.log(error, "action error");
//         throw new Error(error);
//     }
// })


export const  getAllTosCodesActios = createAsyncThunk("getTos" , async(page)=>{
    try {
        console.log(page);
        
        const newPage = page?.page+1
        const response = await getData(`${path}/ct-tosCode?page=${newPage}&pageSize=5`);
console.log(response);

    if (response) {
      return response;
    } 
    } catch (error) {
        console.log(error, "action error");
        throw new Error(error);
    }
})



