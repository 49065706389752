import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomDataGrid from "../../../components/CustomDataGrid";
import path from "../../../config/apiUrl";
import axios from "axios";
import { getData } from "../../../config/axiosFunctions";
import { useParams } from "react-router-dom";
import { esES } from "@mui/x-data-grid";
const ClaimPayer = ({
  formik,
  handlePayer,
  openInsuredParty,
  handleClosePayer,
  claimInsurancesUpdate,
}) => {
  const { claimId } = useParams();
  console.log(claimId);

  console.log(formik.values, "payerClaims");
  // const { getAllPayer } = useSelector((state) => state.payer);
  // const payerInfoLoading = useSelector((state) => state.payerInfo.loading);
  const { primaryPayers, secondaryPayer, tertiaryPayer, claimInsurances } =
    useSelector((state) => state.claim);
  const [tabValue, setTabValue] = useState(0);
  console.log(claimInsurances, "claimInsurances");
  // this state for only update
  console.log(claimInsurancesUpdate);

  // useEffect(() => {
  //     if (formik.values?.patientId === null) {
  //         setTabValue(1)
  //     }
  // }, [])
  const groupedInsurances = {
    primary: claimId
      ? claimInsurancesUpdate?.filter(
          (item) => item.payerInfoPriorityTypeId === 1
        )
      : claimInsurances?.filter((item) => item.payerInfoPriorityTypeId === 1),
    secondary: claimId
      ? claimInsurancesUpdate?.filter(
          (item) => item.payerInfoPriorityTypeId === 2
        )
      : claimInsurances?.filter((item) => item.payerInfoPriorityTypeId === 2),
    tertiary: claimId
      ? claimInsurancesUpdate?.filter(
          (item) => item.payerInfoPriorityTypeId === 3
        )
      : claimInsurances?.filter((item) => item.payerInfoPriorityTypeId === 3),
  };
  console.log(groupedInsurances);

  // const groupedInsurances = {
  //   primary: formik?.values?.policyTypeDto?.filter(
  //     (item) => item.priorityId === 1
  //   ),

  //   secondary: formik?.values?.policyTypeDto?.filter(
  //     (item) => item.priorityId === 2
  //   ),

  //   tertiary: formik?.values?.policyTypeDto?.filter(
  //     (item) => item.priorityId === 3
  //   ),
  // };

  const handleSelectPayer = (policy) => {
    console.log(policy);
    console.log(formik.values.policyTypeDto);

    // Find the index of the existing entry with the same priorityId

    

    const existingInd1 = claimInsurancesUpdate.findIndex(
      (v) => v.payerInfoPriorityTypeId === policy.payerInfoPriorityTypeId
    );
    const existingInd = formik.values.policyTypeDto.findIndex(
      (v) => v.priorityId === policy.payerInfoPriorityTypeId
    );
    console.log(existingInd);

    if (existingInd !== -1 || existingInd1 !== -1) {
      // Replace the existing entry with the selected policy details
      formik.setFieldValue(`policyTypeDto[${existingInd}]`, {
        insuranceId: policy?.insuranceId || 0,
        insuranceName: policy?.insuranceName || "",
        insuranceSequenceNo: policy?.insuranceSequenceNo || 0,
        payerInfoId: policy?.payerInfoId || 0,
        priorityId: policy?.payerInfoPriorityTypeId,
        insuredPartyId: policy?.insuredId,
        priorityName: policy?.payerInfoPriorityType || "",
        memberId: policy?.payerInfoMemberId || "",
        groupId: policy?.payerInfoGroupId || "",
        policyTypeId: policy?.payerInfoPolicyTypeId,
        policyTypeName: policy?.payerInfoPolicyType,
        copayAmount: policy?.payerInfoCopayDue,
        claimControlNumber: "",
        authorizationNumber: "",
        referralTypeId: 1,
        referralType: "",
        // claimNumber: 0,
        claimId: 0,
        claimInfoId: 0,
      });
    } else {
      // Optionally handle the case if no matching priorityId is found
      alert("No matching priorityId found to replace.");
    }
    handleClosePayer();
  };

  return (
    <Box margin={"15px"}>
      {formik.values?.patientId !== null && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            sx={{
              marginBottom: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Tab
              label="Patient Payer"
              style={{ fontSize: "18px", fontWeight: "bold" }}
              className="tabsdesigning"
              value={0}
            />
            {/* <Tab
              label="All Payers"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
              className="tabsdesigning"
              value={1}
            /> */}
          </Tabs>
        </Stack>
      )}
      {/* || claimInsurances?.length > 0 */}
      {/* {tabValue === 1 && <h1>Please Select Patient First</h1>} */}
      {tabValue === 0 &&
        (claimInsurances?.length > 0 || claimInsurancesUpdate?.length > 0) && (
          <Stack gap={"20px"}>
            {Object.entries(groupedInsurances).map(([type, items]) => {
              console.log(items);

              return (
                <Stack key={type} spacing={1}>
                  <Typography component="h2" fontWeight={700}>
                    {type} Insurance
                  </Typography>
                  {items.map((item, index) => (
                    <Card
                      sx={{
                        width: "100%",
                        cursor: "default",
                        "&:hover": {
                          transition: "all .3s ease-in-out",
                          bgcolor: "#EDF2F6",
                        },
                      }}
                      key={index}
                      onClick={() => handleSelectPayer(item)}
                    >
                      <CardContent>
                        <Typography
                          fontWeight={700}
                          marginBottom="3px"
                          component="h4"
                        >
                          {item.insuranceName} (
                          {
                            // item.insuranceSequenceNo ||
                            item.insuranceId
                          }
                          )
                        </Typography>
                        <Divider />
                        <Stack direction="row" marginTop="10px">
                          <Stack width="40%">
                            <Typography component="h4">
                              Insured: {item.insuredFirstName}{" "}
                              {item.insuredLastName}
                            </Typography>
                            <Typography component="h4">
                              Member ID:{" "}
                              {item.memberId || item?.payerInfoMemberId}
                            </Typography>
                            <Typography component="h4">
                              Group ID: {item.groupId || item?.payerInfoGroupId}
                            </Typography>
                          </Stack>
                          <Stack width="35%">
                            <Typography component="h4">
                              Copay:{" "}
                              {item.copayAmount || item?.payerInfoCopayDue}
                            </Typography>
                            {/* <Typography component="h4">
                            Member ID: {0.0}
                          </Typography>
                          <Typography component="h4">
                            Group ID: {0.0}
                          </Typography> */}
                          </Stack>
                          {item?.isDefault && (
                            <Typography component={"h4"} fontWeight={700}>
                              {type} Default
                            </Typography>
                          )}
                        </Stack>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              );
            })}
          </Stack>
        )}
    </Box>
  );
};
export default ClaimPayer;
