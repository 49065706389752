import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getFeeScheduleAction = createAsyncThunk(
  "getFeeSchedule",
  async (page) => {
    try {
      console.log(page);
      const newPages = page?.page + 1;
      const response = await getData(
        `${path}/updatedFeeSchedule?page=${newPages}&pageSize=20`
      );

      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "action error");
      throw new Error(error);
    }
  }
);
export const getFeeScheduleBySeqAction = createAsyncThunk(
  "getFeeScheduleBySeq",
  async (feeScheduleId) => {
    try {
      const response = await getData(
        `${path}/updatedFeeSchedule/getFeeScheduleById?feescheduleId=${feeScheduleId}`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "action error");
      throw new Error(error);
    }
  }
);

export const getBlankScheduleAction = createAsyncThunk(
  "getBlankSchedule",
  async () => {
    try {
      const response = await getData(
        `${path}/updatedFeeSchedule/blankScheduleList`
      );

      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "action error");
      throw new Error(error);
    }
  }
);

// fee schedule configuration
export const getFeeScheduleConfigAction = createAsyncThunk(
  "getFeeScheduleConfig",
  async () => {
    try {
      const response = await getData(
        `${path}/CTFeesConfiguration/CompleteFeeConfiguration`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err, "action error getting configuration");
      throw new Error(err);
    }
  }
);

export const postFeeScheduleTypeAction = createAsyncThunk(
  "postFeeScheduleType",
  async (data) => {
    try {
      const response = await postData(
        `${path}/updatedFeeSchedule/feeScheduleList`,
        data
      );
      if (response) {
        console.log(response, "checkResponseInAction");
        return response;
      }
    } catch (err) {
      console.log(err, "action error in fee schedule type");
      throw new Error(err);
    }
  }
);

export const createFeeScheduleAction = createAsyncThunk(
  "createFeeSchedule",
  async (data) => {
    try {
      const response = await postData(`${path}/updatedFeeSchedule`, data);
      if (response) {
        return response;
      }
    } catch (err) {
      console.log(err, "action error in create new schedule");
      throw new Error(err);
    }
  }
);

export const updateFeeScheduleAction = createAsyncThunk(
  "updateFeeSchedule",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/updatedFeeSchedule?feescheduleId=${data.feeSchedulesId}`,
        data
      );
      if (response) {
        return response;
      }
    } catch (err) {
      console.log(err, "action error in update schedule");
      throw new Error(err);
    }
  }
);
