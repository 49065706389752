import {
  Box,
  // Stack
} from "@mui/material";
import CustomPreview from "../../../layout/CustomPreview";
import { useSelector } from "react-redux";
// import CustomButton from "../../../components/CustomButton";
import { patientCols } from "../../../utils/gridCols";
import { getPatientAction } from "../../../features/actions/createPatientAction";
import { useNavigate } from "react-router-dom";
// import CustomDataGrid from "../../../components/CustomDataGrid";

const ManageAccount = () => {
  const {
    getAllPatients,
    //  loading
  } = useSelector((state) => state.patient);
  const navigate = useNavigate();
  const rows =
    getAllPatients && getAllPatients?.result?.patients?.length > 0
      ? getAllPatients.result?.patients?.map((el) => ({
          // id: el.accountNo,
          id: el.patientId,
          insuredName: el?.getInsuredParty?.insuredName,
          ...el,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={patientCols}
        paginationDetail={getAllPatients}
        searchLabel="Search by DOB,Account #,Phone #"
        moduleHeading="Manage Account"
        handleCell={(params) =>
          // navigate(`/patients/manage-account/${params?.accountNo}`)
          navigate(`/patients/manage-account/${params?.patientId}`)
        }
        handleGetAll={getPatientAction}
      ></CustomPreview>
    </Box>
  );
};

export default ManageAccount;
