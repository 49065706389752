import React, { useCallback, useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import { useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSearchField from "../../components/CustomSearchField";
import CustomButton from "../../components/CustomButton";
import { useFormik } from "formik";
import { paymentInitVal3 } from "../../utils/formikInitValues";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectBox from "../../components/CustomSelectBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PostPayment from "../payment/PostPayment";
import { createPaymentAction } from "../../features/actions/PaymentAction";
import {
  paymentSchemaForInsurance,
  paymentSchemaForPatient,
} from "../../schemas/patientSchema";
import path from "../../config/apiUrl";
import CustomPreview from "../../layout/CustomPreview";
import {
  getClaimAction,
  getClaimByNumberAction,
  getClaimBySearchTermAction,
} from "../../features/actions/claimAction";
import {
  claimCols,
  patientColsForPayment,
  payerCol,
} from "../../utils/gridCols";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../features/actions/payerAction";
// import { getData } from "../../config/axiosFunctions";
import {
  getPatientAction,
  getPatientBySearchTermAction,
} from "../../features/actions/createPatientAction";
import fingerTouchLogo from "../../assets/finger_touch.png";
import AccountSummary from "../../components/AccountSummary";
import { useLocation } from "react-router-dom";
import PaymentInsurances from "./PaymentInsurances";

const NewPayment = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openAccountSummary, setOpenAccountSummary] = useState(false);
  const [openMultiInsModal, setOpenMultiInsModal] = useState(false);
  const [claimInsurances, setClaimInsurances] = useState(null);
  const [openPayerModal, setOpenPayerModal] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [showPostPay, setShowPostPay] = useState(false);
  const [applyEob, setApplyEob] = useState(false);
  const [creditCardOptions, setCreditCardOptions] = useState([]);
  // useSelectors
  const { getClaims, loading } = useSelector((state) => state.claim);
  const { getAllPayer } = useSelector((state) => state.payer);
  const payerLoading = useSelector((state) => state.payer.loading);
  const { getAllPatients } = useSelector((state) => state.patient);
  const patientLoading = useSelector((state) => state.patient.loading);
  // const sumAppliedValue = () => {
  //   let total = 0;
  //   if (formik.values?.paymentClaimDto) {
  //     for (let i = 0; i < formik.values?.paymentClaimDto?.length; i++) {
  //       const element = formik.values?.paymentClaimDto[i];
  //       let totalofSingleClaim = element?.paymentDetailDto?.reduce(
  //         (sum, current) => sum + current?.allowed,
  //         0
  //       );
  //       total += totalofSingleClaim;
  //     }
  //   }
  //   return total;
  // };

  //   formik logic here
  const formik = useFormik({
    initialValues: paymentInitVal3,
    onSubmit: (values) => {
      try {
        dispatch(createPaymentAction(values)).then(({ payload }) => {
          console.log(payload, "payload in payment");
          if (payload.status === "Success") {
            toast.success("Payment created successfully!");
            // setShowPostPay(true);
            formik.resetForm();
            setApplyEob(false);
            setShowPostPay(false);
          }
        });
      } catch (error) {
        console.log(error, "error in onSubmit in payment");
        throw new Error(error);
      }
    },
  });
  //   handling payer type
  const handlePaymentBy = async (selectedRow) => {
    // set values for by patient
    if (formik.values.paymentByTypeId === 1) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        patientId: selectedRow?.patientId,
        patientFirstName: selectedRow?.firstName,
        patientLastName: selectedRow?.lastName,
      }));
      setOpenPatientModal(false);
    }
    // by claim
    else if (formik.values.paymentByTypeId === 2 && formik.values.isClaim) {
      await dispatch(getClaimByNumberAction(selectedRow?.claimId)).then(
        ({ payload }) => {
          formik.setFieldValue("claimId", payload.result?.claimId);
          if (payload?.result?.policyTypeDto?.length > 1) {
            setClaimInsurances(payload?.result);
            setOpenMultiInsModal(true);
          } else {
            setOpenMultiInsModal(false);
            formik.setFieldValue(
              "insuranceDescription",
              payload.result?.policyTypeDto[0]?.insuranceName
            );
            formik.setFieldValue(
              "insuranceDescriptionId",
              payload.result?.policyTypeDto[0]?.insuranceId
            );
            setOpenClaimModal(false);
            setOpenPayerModal(false);
          }
        }
      );
    } else {
      formik.setValues((preValues) => ({
        ...preValues,
        insuranceDescription: selectedRow?.insuranceDescriptionName,
        insuranceDescriptionId: selectedRow?.insuranceDescriptionId,
      }));
      setOpenClaimModal(false);
      setOpenPayerModal(false);
    }
  };

  console.log(formik.values, "formik_values");
  const renderSourceOptions = () => {
    if (formik.values.paymentMethodId === 1) {
      return (
        <>
          <CustomField
            value={formik.values.checkNumber}
            type="text"
            label="Check/EFT #"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="checkNumber"
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 3) {
      return (
        <>
          <CustomSelectBox
            value={formik.values.creditCardTypeId || 5}
            name="creditCardTypeId"
            dropdownOptions={creditCardOptions?.map((opt) => ({
              value: opt?.creditCardType,
              id: opt?.creditCardTypeId,
            }))}
            formik={formik}
            label="Credit Card Type"
            //  handleChange={formik.handleChange}
            handleChange={(e) => {
              formik.setFieldValue("creditCardTypeId", Number(e.target.value));
            }}
            handleBlur={formik.handleBlur}
            // showDefaultOpt={true}
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 4) {
      return (
        <CustomField
          value={formik.values.checkNumber}
          type="text"
          label="Check/EFT #"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name="checkNumber"
        />
      );
    } else {
      return null;
    }
  };

  // apply eob handle
  const handleApplyEOB = () => {
    validatePayment(
      formik.values,
      formik.values.paymentByTypeId === 1
        ? paymentSchemaForPatient
        : paymentSchemaForInsurance
    ).then((error) => {
      let toastError;
      if (error) {
        const { checkNumber, ...remaining } = error;
        toastError =
          formik.values.paymentMethodId !== 1 &&
          formik.values.paymentMethodId !== 4
            ? remaining
            : formik.values.paymentByTypeId === 1
            ? remaining
            : error;

        if (Object.keys(toastError).length > 0) {
          toast.info(Object.values(toastError).join("\n"));
        }
        console.log("JSON.stringify(error)", JSON.stringify(toastError));
        if (
          error["checkNumber"] &&
          Object.keys(error).length === 1 &&
          formik.values.paymentByTypeId === 1
        ) {
          const loadingBtn = setTimeout(() => {
            setShowPostPay(true);
          }, 1500);
          loadingBtn && setApplyEob(true);
        } else if (Object.keys(toastError).length === 0) {
          const loadingBtn = setTimeout(() => {
            setShowPostPay(true);
          }, 1500);
          loadingBtn && setApplyEob(true);
        }
      } else {
        const loadingBtn = setTimeout(() => {
          setShowPostPay(true);
        }, 1500);
        loadingBtn && setApplyEob(true);
      }
    });
  };

  const validatePayment = async (values, schema) => {
    try {
      // This will throw an error if validation fails
      await schema.validate(values, { abortEarly: false });
      // If validation passes, return null
      return null;
    } catch (error) {
      // If validation fails, return an object containing errors
      return error.inner.reduce((errors, err) => {
        return {
          ...errors,
          [err.path]: err.message,
        };
      }, {});
    }
  };

  const fetchCreditCardOptions = async () => {
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(`${path}/ct-creditCardType`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCreditCardOptions(response.data?.result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchCreditCardOptions();
  }, []);
  const paymentBy =
    formik.values?.patientId === 0 &&
    formik.values?.insuranceDescriptionId === 0
      ? ""
      : formik.values?.patientId !== 0
      ? `${formik.values.patientFirstName} ${formik.values.patientLastName} (${formik.values.patientId})`
      : `${formik.values.insuranceDescription} (${formik.values.insuranceDescriptionId})`;
  return (
    <>
      <Box>
        {/* {!openAccountSummary &&
        formik.values.paymentByTypeId === 1 &&

        formik.values.patientId !== null ? (
          <Button
            style={{ background: theme.palette.accSummary.primary, zIndex: 9 }}
            className="accountSummaryBtn"
            onClick={handleAccountSummary}
          >
            <span>
              <img
                src={fingerTouchLogo}
                alt="finger-touch-logo"
                style={{ marginRight: "5px" }}
              />
            </span>
            <span>Account Summary</span>
          </Button>
        ) : openAccountSummary ? (
          <animated.div
            style={{
              width: "30%",
              position: "fixed",
              top: "40%",
              overflow: "hidden",
              zIndex: 9,
              ...accountSummaryProps, // Apply animated styles
            }}
          >
            <AccountSummary
              formik={formik}
              // accountNo={Number(formik.values.accountNo)}
              patientId={Number(formik.values.patientId)}
              handleClose={() => setOpenAccountSummary(false)}
            />
          </animated.div>
        ) : null} */}
        {/* <br />
        <br />
        <br /> */}
        <Box>
          {showPostPay ? (
            <PostPayment
              formik={formik}
              setShowPostPay={setShowPostPay}
              setApplyEob={setApplyEob}
            />
          ) : (
            <>
              <Box sx={{ width: "95%", margin: "0 12px 0" }}>
                {/* <MainHeading mainheading={"New Payment"} /> */}
                <CustomButton
                  variant="contained"
                  padding={"7px"}
                  margin="10px 20px 0"
                  isBlue={true}
                  handleClick={handleApplyEOB}
                  isAdd={true}
                >
                  {applyEob ? (
                    <CircularProgress />
                  ) : formik.values.paymentByTypeId === 2 ? (
                    "Apply As EOB"
                  ) : (
                    "Apply Manually"
                  )}
                </CustomButton>
              </Box>

              <Stack
                sx={{
                  width: { xs: "95%", sm: "75%", md: "95%" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  padding: "15px",
                  margin: "15px",
                  // afte theme
                  // background: "white",
                }}
              >
                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(1, minmax(0, 1fr))",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="h2"
                      fontWeight={"600"}
                      sx={{
                        marginLeft: "10px",
                        marginBottom: "10px",
                        marginTop: "-10px",
                      }}
                    >
                      Add Payment
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <FormControlLabel
                        label={
                          <Typography fontSize={"1.5rem"} fontWeight="600">
                            Patient Payment
                          </Typography>
                        }
                        control={
                          <Radio
                            value="claim"
                            variant="soft"
                            name="claim"
                            checked={formik.values.paymentByTypeId === 1}
                            onChange={() => {
                              formik.setFieldValue("insuranceDescriptionId", 0);
                              formik.setFieldValue("paymentByTypeId", 1);
                              formik.setFieldValue("isClaim", null);
                              formik.setFieldValue("paymentTypeId", 1);
                            }}
                            style={{
                              color: theme.palette.radioButtons.primary,
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography fontSize={"1.5rem"} fontWeight="600">
                            Insurance Payment
                          </Typography>
                        }
                        control={
                          <Radio
                            value="payer"
                            variant="soft"
                            name="payer"
                            checked={formik.values.paymentByTypeId === 2}
                            onChange={() => {
                              formik.setFieldValue("patientId", 0);
                              formik.setFieldValue("paymentByTypeId", 2);
                              formik.setFieldValue("isClaim", true);
                              formik.setFieldValue("paymentTypeId", 0);

                            }}
                            style={{
                              color: theme.palette.radioButtons.primary,
                            }}
                          />
                        }
                      />
                    </Box>
                    {formik.values.paymentByTypeId === 2 && (
                      <Tabs
                        sx={{
                          padding: "0",
                          minHeight: "1rem !important",
                          order: { xs: 2, sm: 2, md: 1 },
                        }}
                        value={formik.values.isClaim}
                        onChange={(e, v) => {
                          formik.resetForm();
                          formik.setFieldValue("paymentByTypeId", 2);
                          formik.setFieldValue("isClaim", v);
                        }}
                        indicatorColor="transparent"
                      >
                        {" "}
                        <Tab
                          style={{
                            borderRadius: "10px 0 0 10px",
                            background: "#b8b4b4",
                          }}
                          className="paymenttabstwo"
                          label="Claim"
                          value={true}
                        />
                        <Tab
                          style={{
                            background: "#b8b4b4",
                            borderRadius: "0 10px 10px 0",
                          }}
                          className="paymenttabstwo"
                          label="Payer"
                          value={false}
                        />{" "}
                      </Tabs>
                    )}
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  margin={"5px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  <CustomSearchField
                    type="text"
                    label="Payment By"
                    // touched={formik.touched}
                    // isRequired={true}
                    // error={formik.errors}
                    handleModalOpen={() => {
                      if (
                        !formik.values.isClaim &&
                        formik.values.paymentByTypeId === 2
                      ) {
                        setOpenPayerModal(true);
                      } else if (formik.values.paymentByTypeId === 1) {
                        setOpenPatientModal(true);
                      } else {
                        setOpenClaimModal(true);
                      }
                    }}
                    name="insuranceDescription"
                    fieldVal={paymentBy}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    formik={formik}
                    resetField={{
                      insuranceDescription: "",
                      insuranceDescriptionId: 0,
                    }}
                  />
                </Box>
                {formik.values.paymentByTypeId === 2 && (
                  <Box
                    display="grid"
                    gap="30px"
                    margin={"5px 0"}
                    sx={{
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(1, minmax(0, 1fr))",
                        md: "repeat(2, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <CustomField
                      type="text"
                      label="Payment From"
                      value={
                        formik.values.insuranceDescription &&
                        formik.values.insuranceDescription
                      }
                      name="insuranceDescription"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                    />
                  </Box>
                )}

                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(4, minmax(0, 1fr))",
                    },
                  }}
                >
                  {/* {formik.values.paymentByTypeId === 2 && (
                    <CustomField
                      type="text"
                      label="Payment From"
                      disable={true}
                      value={formik.values.paymentFromName}
                      name="paymentFromName"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                    />
                  )} */}
                  <CustomField
                    type="number"
                    name="paymentAmount"
                    isRequired={true}
                    error={formik.errors}
                    touched={formik.touched}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    label="Payment Amount"
                    value={formik.values.paymentAmount}
                  />
                  {/* <CustomDatePicker
                      formik={formik}
                      name="checkDate"
                      dateValue={formik.values.checkDate}
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    /> */}
                  <CustomDatePicker
                    formik={formik}
                    dateLabel="Receive Date"
                    name="receiveDate"
                    dateValue={formik.values.receiveDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />

                  {/* <CustomDatePicker
                      formik={formik}
                      name="checkDate"
                      dateValue={formik.values.checkDate}
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    /> */}
                  <CustomDatePicker
                    formik={formik}
                    dateLabel="Deposit Date"
                    name="depositDate"
                    dateValue={formik.values.depositDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </Box>

                <Stack flexDirection={"row"} alignItems={"center"}>
                  {formik.values.paymentByTypeId === 1 && (
                    <Box>
                      {" "}
                      <Stack flexDirection={"row"} alignItems={"center"}>
                        <Typography
                          fontSize={"1.3rem"}
                          fontWeight={"600"}
                          marginRight={"20px"}
                        >
                          Type:
                        </Typography>
                        <FormControlLabel
                          label={
                            <Typography fontSize={"1.3rem"} fontWeight="600">
                              Copay
                            </Typography>
                          }
                          control={
                            <Radio
                              value="1"
                              variant="soft"
                              name="paymentTypeId"
                              checked={formik.values.paymentTypeId === 1}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "paymentTypeId",
                                  Number(e.target.value)
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography fontSize={"1.3rem"} fontWeight="600">
                              Payment
                            </Typography>
                          }
                          control={
                            <Radio
                              value="2"
                              variant="soft"
                              name="paymentTypeId"
                              checked={formik.values.paymentTypeId === 2}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "paymentTypeId",
                                  Number(e.target.value)
                                );
                              }}
                            />
                          }
                        />
                      </Stack>
                      {formik.values.paymentTypeId === 1 && (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale="en"
                        >
                          <CustomDatePicker
                            name="copayServiceDate"
                            dateValue={formik.values.copayServiceDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                            formik={formik}
                            dateLabel="Copay Date of Service"
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  )}
                </Stack>
                <Stack flexDirection={"row"} alignItems={"center"} marginTop={"10px"}>
                  <Typography
                    sx={{ fontSize: "1.3rem", fontWeight: "600" }}
                    marginRight={"10px"}
                  >
                    Source:
                  </Typography>
                  <FormControlLabel
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Check
                      </Typography>
                    }
                    control={
                      <Radio
                        value="1"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 1}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 1 && (
                    <FormControlLabel
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          Cash
                        </Typography>
                      }
                      control={
                        <Radio
                          value="2"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 2}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                          style={{ color: theme.palette.radioButtons.primary }}
                        />
                      }
                    />
                  )}
                  <FormControlLabel
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Credit Card
                      </Typography>
                    }
                    control={
                      <Radio
                        value="3"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 3}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 2 && (
                    <FormControlLabel
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          Electronic Fund Transfer
                        </Typography>
                      }
                      control={
                        <Radio
                          value="4"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 4}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                          style={{ color: theme.palette.radioButtons.primary }}
                        />
                      }
                    />
                  )}
                </Stack>

                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(2, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  {renderSourceOptions()}
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  margin={"5px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  {formik.values.paymentByTypeId === 1 && (
                    <CustomField
                      type="text"
                      name="memo"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      label="Memo"
                      value={formik.values.memo}
                    />
                  )}
                </Box>
              </Stack>
            </>
          )}
        </Box>
      </Box>
      {/* modals here */}
      <CustomModal
        open={openClaimModal}
        handleClose={() => setOpenClaimModal(false)}
        heading="All Claims"
        width="70%"
      >
        <CustomPreview
          searchLabel="Search by Claim Id,Patient Name,Dos"
          rows={
            getClaims && getClaims?.result?.claims.length > 0
              ? getClaims?.result?.claims?.map((el) => ({
                  id: el?.claimId,
                  fromDate: el?.getClaimChargesDto
                    ? new Date(el?.getClaimChargesDto?.fromDate)?.toString()
                    : "",
                  ...el,
                }))
              : []
          }
          columns={claimCols}
          handleGetAll={getClaimAction}
          loading={loading}
          handleBySearch={getClaimBySearchTermAction}
          isModal={true}
          handleCell={handlePaymentBy}
          paginationDetail={getClaims}
        />
      </CustomModal>
      {/* payer modal */}
      <CustomModal
        open={openPayerModal}
        handleClose={() => setOpenPayerModal(false)}
        heading="Payers"
        width="70%"
      >
        <CustomPreview
          isModal={true}
          rows={
            getAllPayer && getAllPayer?.result?.insurance?.length > 0
              ? getAllPayer?.result?.insurance?.map((el) => ({
                  id: el?.insuranceDescriptionId,
                  ...el,
                }))
              : []
          }
          columns={payerCol}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
          paginationDetail={getAllPayer}
          loading={payerLoading}
          handleCell={handlePaymentBy}
          searchLabel="Search by Name or Address"
        />
      </CustomModal>
      {/* patient modal */}
      <CustomModal
        open={openPatientModal}
        handleClose={() => setOpenPatientModal(false)}
        heading="Patients"
        width="70%"
      >
        <CustomPreview
          columns={patientColsForPayment}
          rows={
            getAllPatients && getAllPatients?.result?.patients?.length > 0
              ? getAllPatients.result?.patients?.map((el) => ({
                  // id: el.accountNo,
                  id: el.patientId,
                  insuredName: el?.getInsuredParty?.insuredName,
                  ...el,
                }))
              : []
          }
          handleGetAll={getPatientAction}
          paginationDetail={getAllPatients}
          handleBySearch={getPatientBySearchTermAction}
          loading={patientLoading}
          handleCell={handlePaymentBy}
          isModal={true}
          searchLabel="Search by Account #, Name, Account Type and Date Of Birth"
        />
      </CustomModal>
      {/* multi insurance modal */}
      <CustomModal
        open={openMultiInsModal}
        heading="Payment Source"
        width="70%"
        handleClose={() => {
          formik.setFieldValue("insuranceDescription", "");
          formik.setFieldValue("insuranceDescriptionId", 0);
          setOpenMultiInsModal(false);
        }}
      >
        <PaymentInsurances
          claimInsurances={claimInsurances}
          formik={formik}
          handleClose={() => {
            setOpenMultiInsModal(false);
            setOpenClaimModal(false);
          }}
        />
      </CustomModal>
    </>
  );
};
export default NewPayment;
