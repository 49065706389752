import React, { useEffect, useState } from "react";
import { Box, Typography, FormControl,
  //  Select 
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";
import "../../components/Custom-styling/CustomSelectBox.css";
// import { useNavigate } from "react-router-dom";
// import MainHeading from "../../components/MainHeading";
import Loading from "../../components/Loading";
import CustomDataGrid from "../../components/CustomDataGrid";
import {
  getAllPayment,
  getAllPaymentBySearchQuery,
} from "../../features/actions/PaymentAction";
// import { MultiSelect } from "react-multi-select-component";
import CustomButton from "../../components/CustomButton";
import CheckBox from "../../components/CheckBox";
import CustomField from "../../components/CustomField";
import CustomSearchField from "../../components/CustomSearchField";
import CustomModal from "../../components/CustomModal";
import CustomPreview from "../../layout/CustomPreview";
import { patientClaimDetailCols, payerCol } from "../../utils/gridCols";
import { getPatientClaimsDetailAction } from "../../features/actions/claimAction";
import CustomMultiSelect from "../../components/CustomMultiSelect/CustomMultiSelect";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../features/actions/payerAction";
import { useTheme } from "@mui/material";
import { useFormik } from "formik";
import { searchPayment } from "../../utils/formikInitValues";
import CustomDatePicker from "../../components/CustomDatePicker";
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
const dateOption = {
  1: {
    fromDate: "",
    toDate: "",
  },
  2: {
    fromDate: "",
    toDate: "",
  },
  3: {
    fromDate: formatDate(new Date()),
    toDate: formatDate(new Date()),
  },
  4: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
    toDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
  },
  5: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff)));
    })(),
    toDate: formatDate(new Date()),
  },
  6: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    toDate: formatDate(new Date()),
  },
  7: {
    fromDate: formatDate(new Date(new Date().getFullYear(), 0, 1)),
    toDate: formatDate(new Date()),
  },
  8: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 7)));
    })(),
    toDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 1)));
    })(),
  },
  9: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    ),
    toDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    ),
  },
  10: {
    fromDate: formatDate(new Date(new Date().getFullYear() - 1, 0, 1)),
    toDate: formatDate(new Date(new Date().getFullYear() - 1, 12, 0)),
  },
  11: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    ),
    toDate: formatDate(new Date()),
  },
  12: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 30
      )
    ),
    toDate: formatDate(new Date()),
  },
  13: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 60
      )
    ),
    toDate: formatDate(new Date()),
  },
  14: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 90
      )
    ),
    toDate: formatDate(new Date()),
  },
  15: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      )
    ),
    toDate: formatDate(new Date()),
  },
};
const ViewPayment = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAllPayments, loading } = useSelector((state) => state.payment);
  /// get all claims
  const { patientClaimsData, patientModalLoading = loading } = useSelector(
    (state) => state.claim
  );
  /// get all payers
  const { getAllPayer, payerModalLoading = loading } = useSelector(
    (state) => state.payer
  );
  // states
  const [patientModel, setPatientModel] = useState(false);
  const [payertModel, setPayerModel] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState([]);
  const [dosList, setDosList] = useState([]);
  const [paymentIdList, setPaymentIdList] = useState([]);

  const generateQuery = () => {
    const { paymentTypeId, searchTerm, number, ctdosId, fromDate, toDate } =
      formik.values;
    let query = [];
    if (paymentTypeId) query.push(`paymentTypeId=${paymentTypeId}`);
    if (searchTerm) query.push(`searchTerm=${searchTerm}`);
    if (number)
      query.push(
        // `${paymentTypeId === 1 ? "patientAccountNo" : "payerSeqNo"}=${number}`
        `${paymentTypeId === 1 ? "patientId" : "payerSeqNo"}=${number}`
      );
    if (ctdosId) query.push(`ctdosId=${ctdosId}`);
    if (ctdosId === "2" && fromDate) query.push(`fromDate=${fromDate}`);
    if (ctdosId === "2" && toDate) query.push(`toDate=${toDate}`);
    if (selectedPaymentId.length === 1 && paymentTypeId === "1")
      query.push(`paymentId=${selectedPaymentId[0].value}`);
    const queryString = query.join("&");
    return queryString;
  };

  const formik = useFormik({
    initialValues: searchPayment,
    onSubmit: (val, act) => {
      const query = generateQuery();
      dispatch(getAllPaymentBySearchQuery(query));
    },
  });

  let rows =
    getAllPayments?.result && getAllPayments?.result.length > 0
      ? getAllPayments?.result?.map((item) => ({
          ...item,
          id: item?.paymentId,
          paymentByTypeId:
            item?.paymentByTypeId === 1 ? "Payment" : "Insurance",
          checkDate: new Date(item?.checkDate).toDateString(),
        }))
      : [];

  const dataSource = {
    dos: "/ct-dos",
    paymentType: "/ct-paymentType",
  };
  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      console.log("paymentIdList", response.result);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(dataSource.dos, setDosList);
    fetchData(dataSource.paymentType, setPaymentIdList);
    dispatch(getAllPayment());
  }, []);

  const transformedOptions = paymentIdList.map((item) => ({
    label: item.paymentType,
    value: item.paymentTypeId,
  }));

  // rows
  const columns = [
    {
      field: "paymentByTypeId",
      headerName: "Source",
      flex: 3,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "payerName",
      headerName: "Payment From",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "checkDate",
      headerName: "Received Date",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "checkNumber",
      headerName: "Check #",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalBilled",
      headerName: "Amount",
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalAllowed",
      headerName: "Applied",
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalAdjusted",
      headerName: "Provider Adjusment",
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalUnpaid",
      headerName: "Remaining",
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  const handleAddNameAndNo = (val) => {
    if (formik.values.paymentTypeId === 1) {
      formik.setValues((value) => ({
        ...value,
        // number: val.patientAccountNo,
        number: val.patientId,
        // name: `${val.patientFirstName} ${val.patientLastName} #(${val.patientAccountNo})`,
        name: `${val.patientFirstName} ${val.patientLastName} #(${val.patientId})`,
      }));
      setPatientModel(false);
    } else {
      formik.setValues((value) => ({
        ...value,
        number: val.payerSequenceNo,
        name: `${val.payerName} #(${val.payerSequenceNo})`,
      }));
      setPayerModel(false);
    }
  };

  const handleDosChange = (e) => {
    let { fromDate, toDate } = dateOption[e.target.value];
    formik.setValues((value) => ({
      ...value,
      ctdosId: e.target.value,
      fromDate: fromDate,
      toDate: toDate,
    }));
  };
  return (
    <>
      {console.log(transformedOptions)}
      <CustomModal
        open={patientModel}
        handleClose={() => setPatientModel(false)}
        heading="Patients"
      >
        <CustomPreview
        isModal={true}
          rows={
            patientClaimsData && patientClaimsData?.result?.patientClaim.length > 0
              ? patientClaimsData?.result?.patientClaim?.map((row) => ({
                  id: row?.patientId,
                  ...row,
                }))
              : []
          }
          searchLabel="Search by Account #, Name, Account Type, Phone No and Date Of Birth"
          columns={patientClaimDetailCols}
          loading={patientModalLoading}
          handleGetAll={getPatientClaimsDetailAction}
          handleCell={handleAddNameAndNo}
        />
      </CustomModal>
      <CustomModal
        open={payertModel}
        handleClose={() => setPayerModel(false)}
        heading={"All Payer"}
      >
        <CustomPreview
          rows={
            getAllPayer && getAllPayer?.result?.payers?.length > 0
            ? getAllPayer?.result?.payers?.map((el) => ({
                id: el?.payerId,
                ...el,
              }))
            : []
          }
          columns={payerCol}
          loading={payerModalLoading}
          handleGetAll={getPayerAction}
          handleCell={handleAddNameAndNo}
          searchLabel={"Search by Payer Name, Plan Name, Address"}
          isModal={true}
          handleBySearch={getPayerBySearchTermAction}
        />
      </CustomModal>

      <Box>
        <div style={{ padding: "10px", width: "98%", marginLeft: "17px" }}>
          {/* <MainHeading mainheading={"View Payment"} /> */}
        </div>
        <Box sx={{ padding: "20px" }}>
          {loading ? (
            <Loading />
          ) : (
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <CustomButton
                  isSearch={true}
                  type={"submit"}
                  children={"Search"}
                  key={"Search"}
                  isBlue={true}
                />
                <Box
                  sx={{ width: { sx: "100%", md: "65%" }, margin: "5px 0px" }}
                >
                  <Box sx={{ width: "fit-content" }}>
                    <CheckBox
                      label={"Patient Payment"}
                      CheckedValue={formik.values.paymentTypeId === 1}
                      handleChange={() =>
                        formik.setValues((val) => ({
                          ...val,
                          searchTerm: "",
                          paymentTypeId: 1,
                          number: "",
                          name: "",
                          ctdosId: "",
                          paymentId: 1,
                          fromDate: "",
                          toDate: "",
                        }))
                      }
                    />
                    <CheckBox
                      label={"Insurance Payment"}
                      CheckedValue={formik.values.paymentTypeId === 2}
                      handleChange={() =>
                        formik.setValues((val) => ({
                          ...val,
                          searchTerm: "",
                          paymentTypeId: 2,
                          number: "",
                          name: "",
                          ctdosId: "",
                          paymentId: 1,
                          fromDate: "",
                          toDate: "",
                        }))
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "1px solid black",
                      margin: "20px 0px 30px",
                      borderRadius: "5px",
                      padding:'15px'
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      variant="h4"
                    >
                      Additional Option
                    </Typography>
                    {formik.values.paymentTypeId === 2 ? (
                      <Box sx={{ padding: "6px" }}>
                        <Box>
                          <CustomSearchField
                            type="text"
                            label="Payer"
                            fieldVal={formik.values.name}
                            name="payerInfoPayerName"
                            handleModalOpen={() => setPayerModel(true)}
                            isRequired={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            formik={formik}
                            resetField={{
                              payerInfoPayerName:"",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            display: "grid",
                            gap: "30px",
                            gridTemplateColumns: {
                              xs: "repeat(1, minmax(0, 1fr))",
                              sm: "repeat(3, minmax(0, 300px))",
                              md: "repeat(3, minmax(0, 1fr))",
                            },
                          }}
                        >
                          <FormControl fullWidth>
                            <label
                              className="customLabel"
                              style={{ color: theme.palette.Labeltext.primary }}
                              htmlFor=""
                            >
                              Received/Check Date
                            </label>
                            <select
                              className={"customSelectBox"}
                              style={{
                                background:
                                  theme.palette.customFieldsBackground.primary,
                                border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                color: theme.palette.fieldsText.primary,
                              }}
                              value={formik.values.ctdosId}
                              onChange={handleDosChange}
                              id="dropdowns"
                            >
                              {dosList.map((opt) => {
                                return (
                                  <>
                                    <option
                                      style={{
                                        background:
                                          theme.palette.fieldsDDown.primary,
                                      }}
                                      itemType="number"
                                      key={opt.dosId}
                                      value={opt.dosId}
                                    >
                                      {opt.dos}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </FormControl>
                          <CustomDatePicker
                            formik={formik}
                            disable={formik.values.ctdosId !== "2"}
                            dateLabel="From"
                            name="fromDate"
                            dateValue={formik.values.fromDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                          />
                          <CustomDatePicker
                            formik={formik}
                            dateLabel="To"
                            name="toDate"
                            disable={formik.values.ctdosId !== "2"}
                            dateValue={formik.values.toDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ padding: "6px" }}>
                        <Box>
                          <CustomSearchField
                            label="Patient"
                            type="text"
                            handleModalOpen={() => setPatientModel(true)}
                            fieldVal={formik.values.name}
                            name="patientName"
                            isRequired={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            formik={formik}
                            resetField={{
                              patientName:"",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            display: "grid",
                            gap: "30px",
                            gridTemplateColumns: {
                              xs: "repeat(1, minmax(0, 1fr))",
                              sm: "repeat(3, minmax(0, 300px))",
                              md: "repeat(3, minmax(0, 1fr))",
                            },
                          }}
                        >
                          <FormControl fullWidth>
                            <label
                              className="customLabel"
                              style={{ color: theme.palette.Labeltext.primary }}
                              htmlFor=""
                            >
                              Received/Check Date
                            </label>
                            <select
                              className={"customSelectBox"}
                              style={{
                                background:
                                  theme.palette.customFieldsBackground.primary,
                                border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                color: theme.palette.fieldsText.primary,
                              }}
                              value={formik.values.ctdosId}
                              onChange={handleDosChange}
                              id="dropdowns"
                            >
                              {dosList.map((opt) => {
                                return (
                                  <>
                                    <option
                                      style={{
                                        background:
                                          theme.palette.fieldsDDown.primary,
                                      }}
                                      itemType="number"
                                      key={opt.dosId}
                                      value={opt.dosId}
                                    >
                                      {opt.dos}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </FormControl>
                          <CustomDatePicker
                            formik={formik}
                            disable={formik.values.ctdosId !== "2"}
                            dateLabel="Form"
                            name="fromDate"
                            dateValue={formik.values.fromDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                          />
                          <CustomDatePicker
                            formik={formik}
                            dateLabel="To"
                            name="toDate"
                            disable={formik.values.ctdosId !== "2"}
                            dateValue={formik.values.toDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                          />
                        </Box>
                        <FormControl fullWidth>
                          {/* <MultiSelect
                            hasSelectAll={true}
                            options={transformedOptions}
                            value={selectedPaymentId}
                            onChange={setSelectedPaymentId}
                            labelledBy="Select"
                          /> */}
                             <CustomMultiSelect
            hasSelectAll={true}
            options={transformedOptions}
            value={selectedPaymentId}
            handleChange={setSelectedPaymentId}
           handleSelectAll={true}
         />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  <CustomField
                    label={
                      formik.values.paymentTypeId === 1
                        ? "Search by check / receipt #, payment amount, or claim ID"
                        : "Search by check / ref. # (full or partial), payment amount, or claim/patient/TCN ID"
                    }
                    value={formik.values.searchTerm}
                    handleChange={(e) =>
                      formik.setValues((val) => ({
                        ...val,
                        searchTerm: e.target.value,
                      }))
                    }
                    name={"searchTerm"}
                    type={"text"}
                  />
                </Box>
              </form>

              <CustomDataGrid
                rows={rows}
                columns={columns}
                handleCellClick={(e) => {
                  console.log(e.row.id);
                  // navigate(`/editPayment/${e.row.id}`)
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ViewPayment;
