import {
  Box,
  FormControl,
  Select,
  useTheme,
  // TextField, Typography
} from "@mui/material";
import React, { useEffect } from "react"; // { useEffect }
import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomField from "../../components/CustomField";
import "../../components/Custom-styling/CustomSelectBox.css";
// import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import { useFormik } from "formik";

const EditPayDetail = ({ selectedProcedure, onSave, handleClose, formik }) => {
  const theme = useTheme();
  const [editedData, setEditedData] = useState({ ...selectedProcedure });
  const [statusOpts, setStatusOpts] = useState([]);
  console.log(selectedProcedure, "selectedProcedure");
  // edit detailformik
  const detailFormik = useFormik({
    initialValues: { ...selectedProcedure },
    onSubmit: (values) => {
      console.log(values, "editDetailFormik");
      if (formik.values.paymentByTypeId === 1) {
        let allowed = values.allowedAmount;
        const updatedData = {
          ...values,
          allowedAmount: allowed,
          paid: allowed,
          endBalance:
            allowed <= values?.startBalance ? values.startBalance - allowed : 0,
        };
        onSave(updatedData);
        handleClose();
      }else {
        const updatedData = {
          ...values,
          endBalance: values.paid > values.startBalance ? 0 : (values.startBalance - values.paid) - values.adjusted,
          otherCredits: values.paid > values.startBalance && values.adjusted === 0 ? values.startBalance - values.paid : 0,
        }
        console.log(updatedData, "updatedData");
        onSave(updatedData);
        handleClose();
      }
    },
  });
  console.log(detailFormik.values, "detailFormik.values");

  const dataFetchUrls = {
    claimStatus: `${path}/ct-claimStatus`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      console.log(response, "checking response options");
      setter(response.result);
    } catch (error) {
      console.log("Fething DD Options: ", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.claimStatus, setStatusOpts);
  }, []);
  return (
    <>
      <Box
        display="grid"
        padding="20px"
        gap="30px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          type="number"
          size="small"
          label="Amount"
          disable={true}
          // touched={formik.touched}
          // error={formik.errors}
          value={detailFormik.values.amount}
          name="amount"
          handleChange={detailFormik.handleChange}
          handleBlur={detailFormik.handleBlur}
        />
        <CustomField
          type="number"
          size="small"
          label="Start Balance"
          disable={true}
          // touched={formik.touched}
          // error={formik.errors}
          value={detailFormik.values.startBalance}
          name="startBalance"
          handleChange={detailFormik.handleChange}
          handleBlur={detailFormik.handleBlur}
        />
        <CustomField
          type="number"
          size="small"
          // touched={formik.touched}
          // error={formik.errors}
          label={
            formik.values.paymentByTypeId !== 1 ? "Allowed" : "Apply Payment"
          }
          value={detailFormik.values.allowedAmount}
          name="allowedAmount"
          handleBlur={detailFormik.handleBlur}
          handleChange={
            formik.values.paymentByTypeId === 1
              ? detailFormik.handleChange
              : (e) => {
                  let numericValue = Number(e.target.value);
                  detailFormik.setFieldValue("allowedAmount", numericValue);
                  detailFormik.setFieldValue(
                    "adjusted",
                    detailFormik.values.amount - numericValue
                  );
                  if (
                    detailFormik.values.paid === 0 ||
                    detailFormik.values.paid === "" ||
                    detailFormik.values.paid ===
                      detailFormik.values.allowedAmount
                  ) {
                    detailFormik.setFieldValue("paid", numericValue);
                  }
                }
          }
        />
        <CustomSelectBox2
          dropdownOptions={statusOpts?.map((item) => ({
            id: item.claimStatusId,
            value: item.claimStatusType,
          }))}
          label="Claim Status"
          value={detailFormik.values.claimStatusId}
          name="claimStatusId"
          formik={detailFormik}
          handleBlur={detailFormik.handleBlur}
          setValName={"claimStatus"}
          handleChange={detailFormik.handleChange}
          isSetName={true}
        />
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Paid"
            value={detailFormik.values.paid}
            disable={formik.values.paymentByTypeId === 1}
            name="paid"
            handleChange={ (e) => {
              const numericValue = Number(e.target.value);
              detailFormik.setFieldValue("paid", numericValue);
              if(numericValue < detailFormik.values.allowedAmount) {
                detailFormik.setFieldValue("unpaid", detailFormik.values.allowedAmount - numericValue);
              }else {
                detailFormik.setFieldValue("unpaid", 0);
              }
            }}
            handleBlur={detailFormik.handleBlur}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Adjusted"
            value={detailFormik.values.adjusted}
            // touched={formik.touched}
            // error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="adjusted"
            handleChange={detailFormik.handleChange}
            handleBlur={detailFormik.handleBlur}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Unpaid"
            // touched={formik.touched}
            // error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="unpaid"
            value={detailFormik.values.unpaid}
            handleChange={detailFormik.handleChange}
            handleBlur={detailFormik.handleBlur}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Deductible"
            // touched={formik.touched}
            // error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="deductible"
            value={detailFormik.values.deductible}
            handleChange={detailFormik.handleChange}
            handleBlur={detailFormik.handleBlur}
          />
        )}
      </Box>
      <div>
        <Box
          display="grid"
          padding="20px"
          gap="30px"
          sx={{
            gridTemplateColumns: {
              xs: "repeat(4, minmax(0, 1fr))",
              sm: "repeat(4, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
            },
          }}
        >
          <CustomButton
            isBlue={true}
            isSave={true}
            handleClick={detailFormik.handleSubmit}
          >
            Save
          </CustomButton>
          {/* <CustomButton isBlue={true} isCancel={true} handleClick={handleClose}>
            Cancel
          </CustomButton> */}
        </Box>
      </div>
    </>
  );
};

export default EditPayDetail;
