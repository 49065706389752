import { Box, Modal, Stack, useTheme, Typography, Grid } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const CustomModal = ({
  children,
  open,
  handleClose,
  width,
  showButton,
  isLoading = false,
  height,
  heading,
  btnLabel,
  handleClick,
}) => {
  const theme = useTheme();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: width,
    minWidth: { xs: "80%", sm: "450px", md: "50%" },
    // bgcolor: theme.palette.background.paper,
    background: theme.palette.modalBackground.primary,
    border: "2px solid grey",
    boxShadow: 24,
    overflowY: "scroll", 
    zIndex:444,
    maxHeight:"80%",
    ...(height && {height: `${height} !important`}),
  };

  return (
    <Modal onClose={handleClose} open={open}  sx={{ zIndex: "4444",}}>
      <Box sx={modalStyle}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          // marginRight="14px"
          padding="10px"
          sx={{
            position: "sticky",
            top: "0",
            zIndex: "666",
            width: "100%",
            background: theme.palette.modalHeading.primary,
            // background: "#f0f5f7",
          }}
        >
          <Grid container>
            <Grid item lg={10} md={10}>
              <Typography
                variant="h2"
                component="h3"
                fontWeight="600"
                margin="7px"
              >
                {heading}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              {!isLoading && (
                <IconButton style={{color:theme.palette.searchIcon.primary }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            <hr />
          </Grid>
        </Stack>

        <Box>{children}</Box>
        {showButton && (
          <Stack
            sx={{
              width:"100%",
              alignItems: "center",
              flexDirection: "row",
              position: "fixed",
              bottom: 0,
              padding: "12px",
              justifyContent: "flex-end",
              background: "#f0f5f7",
              zIndex: "666",
            }}
            gap={2}
          >
            <CustomButton isBlue={true} handleClick={handleClick}>
              {btnLabel}
            </CustomButton>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;