import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClaimAcivityActionMultiple,
  getClaimAcivityActionSingle,
} from "../../../features/actions/claimAction";
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Loading from "./../../../components/Loading";
import CustomAccordion from "../../../components/CustomAccordion";

const ClaimActivity = () => {
  // const { claimNumber, patientId, type } = useParams();
  const { claimId, patientId, type } = useParams();
  
  const params = useParams();
  console.log("paramsn", params);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = theme.palette;
  const { getClaimActiviy, loading } = useSelector((state) => state.claim);

  console.log(getClaimActiviy, "getClaimActiviy");
  const handleClaimActivity = useCallback(() => {
    try {
      if (type === "singleclaim") {
        // dispatch(getClaimAcivityActionSingle(Number(claimNumber)));
        dispatch(getClaimAcivityActionSingle(Number(claimId)));
      } else {
        dispatch(getClaimAcivityActionMultiple(Number(patientId)));
      }
    } catch (e) {
      console.log(e, ": Error in getting single claim activity");
    }
  // }, [claimNumber, patientId, type, dispatch]);
}, [claimId, patientId, type, dispatch]);


  useEffect(() => {
    handleClaimActivity();
  }, [handleClaimActivity]);
  
  return (
    <Grid container spacing={2}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{fontSize:'1.5rem',margin:'0 10px',}}>
                Activity Listing for
                <strong>
                  {" "}
                  {getClaimActiviy.length > 0
                    ? getClaimActiviy[0]?.patientFirstName
                    : ""}
                  {getClaimActiviy.length > 0
                    ? getClaimActiviy[0]?.patientLastName
                    : ""}
                </strong>{" "}
                <span style={{color:'#0066b2'}}>  (# 
                {getClaimActiviy.length > 0
                  // ? getClaimActiviy[0]?.claimNumber
                  ? getClaimActiviy[0]?.claimId
                  : ""}
                )</span>
                {/* (# 
                {getClaimActiviy.length > 0
                  ? getClaimActiviy[0]?.claimNumber
                  : ""}
                ) */}
              </Typography>
            </Stack>
          </Grid>
          {/* details */}
          <Grid item md={12}>
            <CustomAccordion
              heading={`Claims (Count: ${
                getClaimActiviy.length
              }, Amount: $${getClaimActiviy.reduce((total, num) => {
                return total + num["totalCharges"];
              }, 0)}, Balance: $${getClaimActiviy.reduce((total, num) => {
                return total + num["totalBalance"];
              }, 0)})`}
            >
              {getClaimActiviy &&
                getClaimActiviy.length > 0 &&
                getClaimActiviy.map((value, index) => {
                  return (
                    <CustomAccordion
                      heading={
                        <Grid container sx={{ display:'flex',justifyContent:'space-between',gap: 5 }}>
                          <Grid sx={{display:'flex'}}>
                            <Typography
                              sx={{ margin: "4px 10px" ,fontSize:'1.5rem',fontWeight:'bold'}}
                            >
                              Claim #:
                            </Typography>
                            {/* <Typography sx={{ margin: "6px 0",fontSize:'1.3rem',color:'#0066b2' }}
                              > {value?.claimNumber} </Typography> */}
                                     <Typography sx={{ margin: "6px 0",fontSize:'1.3rem',color:'#0066b2' }}
                              > {value?.claimId} </Typography>
                          </Grid>
                          
                          <Grid sx={{display:'flex'}}>
                            <Typography
                              sx={{ margin: "4px 10px" ,fontSize:'1.5rem',fontWeight:'bold'}}
                            
                            >
                              DOS:{" "}
                            </Typography>
                            <Typography  sx={{ margin: "6px 0",fontSize:'1.3rem' }}
                              >  {value?.claimAndPaymentDetailDto &&
                                value?.claimAndPaymentDetailDto.length > 0
                                  ? new Date(
                                      value?.claimAndPaymentDetailDto[0]?.dateOfService
                                    ).toLocaleString(undefined, {
                                      dateStyle: "short",
                                    })
                                  : ""}</Typography>
                          </Grid>

                          <Grid sx={{display:'flex'}}>
                            <Typography
                           sx={{ margin: "4px 10px" ,fontSize:'1.5rem',fontWeight:'bold'}}
                            >
                              Amount:
                            </Typography>
                            <Typography sx={{ margin: "6px 0",fontSize:'1.3rem' }}> ${value?.totalCharges}</Typography>
                          </Grid>

                          <Grid display={'flex'}>
                            <Typography
                            sx={{ margin: "4px 10px" ,fontSize:'1.5rem',fontWeight:'bold'}}
                            >
                              Balance:
                            </Typography>
                            <Typography sx={{ margin: "6px 0",fontSize:'1.3rem' }}>${value?.totalBalance}</Typography>
                          </Grid>
                          <Grid sx={{display:'flex'}}>
                            <Typography
                            sx={{ margin: "4px 10px" ,fontSize:'1.5rem',fontWeight:'bold'}}
                            >
                              Status:{" "}
                            
                            </Typography>
                            <Typography sx={{ margin: "6px 0",fontSize:'1.2rem' }}>  {value?.claimAndPaymentDetailDto &&
                              value?.claimAndPaymentDetailDto.length > 0
                                ? value?.claimAndPaymentDetailDto[0]
                                    ?.claimStatus
                                : ""}</Typography>
                          </Grid>
                        </Grid>
                      }
                    >
                      <Grid sx={{display:'flex',margin:'0 10px'}}> 
                        <Typography  sx={{fontSize:'1.5rem',fontWeight:'bold'}}>
                        Rendering Provider:
                      </Typography>
                      <Typography sx={{ margin: "2px 5px",fontSize:'1.3rem' }}>{value?.renderingProviderFirstName}{" "}
                      {value?.renderingProviderLastName}</Typography>
                      </Grid>
                      <Grid sx={{display:'flex',margin:'0 10px'}}>
                      <Typography sx={{fontSize:'1.5rem',fontWeight:'bold'}}>
                        ICD Codes:{" "}
                      </Typography>
                      <Typography sx={{ margin: "2px 5px",fontSize:'1.3rem' }}>
                      {value?.icD_DiagnosisDetailDto &&
                        value?.icD_DiagnosisDetailDto?.length > 0
                          ? value?.icD_DiagnosisDetailDto?.map((el) => {
                              return el?.diagnosisCode + ",";
                            })
                          : ""}
                      </Typography>
                      </Grid>
                      <Grid>
                        {/* <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{
                                background:
                                  theme.palette.modalHeading.primary,
                                // color: theme.palette.sidebarDDownText.primary,
                                color: theme.palette.textMainHeading.primary,
                              }}
                              // id="t-header"
                            >
                              <TableRow>
                                <TableCell id="description">
                                  Procedure
                                </TableCell>

                                <TableCell id="description">
                                  DOS/Received
                                </TableCell>

                                <TableCell id="description">
                                  Description
                                </TableCell>

                                <TableCell id="description">Unit</TableCell>

                                <TableCell id="description">Charge</TableCell>
                                <TableCell id="description">Payment</TableCell>
                                <TableCell id="description">
                                  Adjusment
                                </TableCell>
                                <TableCell id="description">Balance</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {value?.claimAndPaymentDetailDto &&
                                value?.claimAndPaymentDetailDto.length > 0 &&
                                value?.claimAndPaymentDetailDto.map(
                                  (val, ind) => {
                                    return (
                                      <>
                                        <TableRow key={ind}>
                                          <TableCell id="description">
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "240px",
                                              }}
                                            >
                                              {val?.procedureCode}
                                            </div>
                                          </TableCell>

                                          <TableCell id="description">
                                            {new Date(
                                              val?.dateOfService
                                            ).toLocaleString(undefined, {
                                              dateStyle: "short",
                                            })}
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.procedureCodeDes}
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.units}
                                          </TableCell>

                                          <TableCell id="description">
                                            ${val?.charge}
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.balance}
                                          </TableCell>
                                          <TableCell id="description">
                                            
                                          </TableCell>
                                          <TableCell id="description">
                                            
                                          </TableCell>
                                        </TableRow>
                                        {val?.claimAndPaymentsDto &&
                                          val?.claimAndPaymentsDto.length > 0 &&
                                          val?.claimAndPaymentsDto.map(
                                            (v, i) => {
                                              return (
                                                <TableRow key={i}>
                                                  <TableCell id="description"></TableCell>
                                                  <TableCell id="description">
                                                    {new Date(
                                                      v?.dateOfService
                                                    ).toLocaleString(
                                                      undefined,
                                                      {
                                                        dateStyle: "short",
                                                      }
                                                    )}
                                                  </TableCell>
                                                  <TableCell id="description">
                                                    {v?.paymentDes}
                                                  </TableCell>
                                                  <TableCell id="description"></TableCell>
                                                  <TableCell id="description"></TableCell>
                                                  <TableCell id="description">
                                                    ${v?.paid}
                                                  </TableCell>
                                                  <TableCell id="description">
                                                    ${v?.adjusted}
                                                  </TableCell>
                                                  <TableCell id="description"></TableCell>
                                                </TableRow>
                                              );
                                            }
                                          )}
                                        <TableRow>
                                          <TableCell id="description"></TableCell>

                                          <TableCell
                                            id="description"
                                            sx={{ textOverflow: "ellipsis" }}
                                          >
                                            <strong>
                                              {" "}
                                              {val?.claimStatus} as{" "}
                                              {new Date(
                                                val?.toDate
                                              ).toLocaleString(undefined, {
                                                dateStyle: "short",
                                              })}{" "}
                                            </strong>
                                          </TableCell>

                                          <TableCell id="description"></TableCell>

                                          <TableCell id="description"></TableCell>

                                          <TableCell id="description"></TableCell>

                                          <TableCell id="description">
                                            {" "}
                                            <strong>
                                              {" "}
                                              $
                                              {val?.claimAndPaymentsDto.reduce(
                                                (total, num) => {
                                                  return total + num["paid"];
                                                },
                                                0
                                              )}
                                            </strong>
                                          </TableCell>
                                          <TableCell id="description">
                                            <strong>
                                              {" "}
                                              $
                                              {val?.claimAndPaymentsDto.reduce(
                                                (total, num) => {
                                                  return (
                                                    total + num["adjusted"]
                                                  );
                                                },
                                                0
                                              )}
                                            </strong>
                                          </TableCell>

                                          <TableCell id="description">
                                            {
                                              <strong>
                                                $
                                                {val.charge -
                                                  (val?.claimAndPaymentsDto.reduce(
                                                    (total, num) => {
                                                      return (
                                                        total + num["paid"]
                                                      );
                                                    },
                                                    0
                                                  ) +
                                                    val?.claimAndPaymentsDto.reduce(
                                                      (total, num) => {
                                                        return (
                                                          total +
                                                          num["adjusted"]
                                                        );
                                                      },
                                                      0
                                                    ))}
                                              </strong>
                                            }
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer> */}

<TableContainer component={Paper} sx={{margin:'15px 0'}}>
        <Table sx={{ border: 1, borderColor: 'lightgrey' }}>
          <TableHead
            style={{
              background: theme.palette.modalHeading.primary,
              color: theme.palette.textMainHeading.primary,
            }}
          >
            <TableRow sx={{ border: 1, borderColor: 'lightgrey' }}>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Procedure</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>DOS/Received</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Description</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Unit</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Charge</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Payment</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Adjustment</TableCell>
              <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value?.claimAndPaymentDetailDto &&
              value?.claimAndPaymentDetailDto.length > 0 &&
              value?.claimAndPaymentDetailDto.map((val, ind) => (
                <React.Fragment key={ind}>
                  <TableRow sx={{ border: 1, borderColor: 'lightgrey' }}>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      <div style={{ display: 'flex', width: '60px' }}>
                        {val?.procedureCode}
                      </div>
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      {new Date(val?.dateOfService).toLocaleString(undefined, { dateStyle: 'short' })}
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      {val?.procedureCodeDes}
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      {val?.units}
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      ${val?.charge}
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      {val?.balance}
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                  </TableRow>
                  {val?.claimAndPaymentsDto &&
                    val?.claimAndPaymentsDto.length > 0 &&
                    val?.claimAndPaymentsDto.map((v, i) => (
                      <TableRow key={i} sx={{ border: 1, borderColor: 'lightgrey' }}>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                          {new Date(v?.dateOfService).toLocaleString(undefined, { dateStyle: 'short' })}
                        </TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                          {v?.paymentDes}
                        </TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                          ${v?.paid}
                        </TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                          ${v?.adjusted}
                        </TableCell>
                        <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                      </TableRow>
                    ))}
                  <TableRow sx={{ border: 1, borderColor: 'lightgrey' }}>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                    <TableCell id="description" sx={{ textOverflow: 'ellipsis', border: 1, borderColor: 'lightgrey' }}>
                      <strong>
                        {val?.claimStatus} as {new Date(val?.toDate).toLocaleString(undefined, { dateStyle: 'short' })}
                      </strong>
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}></TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      <strong>
                        ${val?.claimAndPaymentsDto.reduce((total, num) => total + num['paid'], 0)}
                      </strong>
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      <strong>
                        ${val?.claimAndPaymentsDto.reduce((total, num) => total + num['adjusted'], 0)}
                      </strong>
                    </TableCell>
                    <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                      <strong>
                        ${val.charge - (val?.claimAndPaymentsDto.reduce((total, num) => total + num['paid'], 0) + val?.claimAndPaymentsDto.reduce((total, num) => total + num['adjusted'], 0))}
                      </strong>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
<TableRow sx={{ border: 1, borderColor: 'lightgrey', backgroundColor: theme.palette.modalHeading.primary, color: theme.palette.textMainHeading.primary }}>
        <TableCell colSpan={4} sx={{ border: 1, borderColor: 'lightgrey',fontSize:'1.1rem' }}>Claim Totals</TableCell>
        {/* total charge */}
        <TableCell id="description"  sx={{ border: 1, borderColor: 'lightgrey' }}>
          <strong>
            ${value?.claimAndPaymentDetailDto.reduce((total, val) => total + val.charge, 0)}
          </strong>
        </TableCell>
        {/*total payment */}
        <TableCell id="description"  sx={{ border: 1, borderColor: 'lightgrey' }}>
          <strong>
            ${value?.claimAndPaymentDetailDto.reduce((total, val) => total + val?.claimAndPaymentsDto.reduce((t, num) => t + num['paid'], 0), 0)}
          </strong>
        </TableCell>
        {/* total adjustment */}
        <TableCell id="description"  sx={{ border: 1, borderColor: 'lightgrey' }}>
          <strong>
            ${value?.claimAndPaymentDetailDto.reduce((total, val) => total + val?.claimAndPaymentsDto.reduce((t, num) => t + num['adjusted'], 0), 0)}
          </strong>
        </TableCell>
        {/* total balance */}
        <TableCell id="description"  sx={{ border: 1, borderColor: 'lightgrey' }}>
          <strong>
            ${value?.claimAndPaymentDetailDto.reduce((total, val) => total + (val.charge - (val?.claimAndPaymentsDto.reduce((t, num) => t + num['paid'], 0) + val?.claimAndPaymentsDto.reduce((t, num) => t + num['adjusted'], 0))), 0)}
          </strong>
        </TableCell>
      </TableRow>
          </TableBody>
        </Table>
      </TableContainer>



                        {/* <table id="table">
                          <thead
                            style={{
                              background: theme.palette.infocodesHeader.primary,
                              color: theme.palette.sidebarDDownText.primary,
                            }}
                            id="t-header"
                          >
                            <tr>
                              <th th className="th">
                                Procedure
                              </th>
                              <th th className="th">
                                DOS/Received
                              </th>
                              <th th className="th">
                                Description
                              </th>
                              <th th className="th">
                                Unit
                              </th>
                              <th th className="th">
                                Charge
                              </th>
                              <th th className="th">
                                Payment
                              </th>
                              <th th className="th">
                                Adjusment
                              </th>

                              <th className="th">Balance</th>
                            </tr>
                          </thead>

                          <tbody>
                            {value?.claimAndPaymentDetailDto &&
                              value?.claimAndPaymentDetailDto.length > 0 &&
                              value?.claimAndPaymentDetailDto.map(
                                (val, ind) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "240px",
                                            }}
                                          >
                                            <h6> {val?.procedureCode} </h6>
                                          </div>
                                        </td>

                                        <td className="td-date">
                                          <h6>
                                            {new Date(
                                              val?.dateOfService
                                            ).toLocaleString(undefined, {
                                              dateStyle: "short",
                                            })}
                                          </h6>
                                        </td>

                                        <td
                                          style={{ border: "1px solid black" }}
                                        >
                                          {" "}
                                          <h6>{val?.procedureCodeDes}</h6>
                                        </td>

                                        <td
                                          style={{ border: "1px solid black" }}
                                        >
                                          <h6>{val?.units}</h6>
                                        </td>

                                        <td
                                          style={{ border: "1px solid black" }}
                                          id="description"
                                        >
                                          {" "}
                                          <h6>${val?.charge}</h6>
                                        </td>

                                        <td
                                          style={{ border: "1px solid black" }}
                                        ></td>
                                        <td
                                          style={{ border: "1px solid black" }}
                                        ></td>

                                        <td
                                          style={{
                                            cursor: "pointer",
                                            border: "1px solid black",
                                            padding: "5px",
                                          }}
                                        ></td>
                                      </tr>
                                      {val?.claimAndPaymentsDto &&
                                        val?.claimAndPaymentsDto.length > 0 &&
                                        val?.claimAndPaymentsDto.map((v, i) => {
                                          return (
                                            <tr>
                                              <td></td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              >
                                                {" "}
                                                <h6>
                                                  {new Date(
                                                    v?.dateOfService
                                                  ).toLocaleString(undefined, {
                                                    dateStyle: "short",
                                                  })}
                                                </h6>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              >
                                                <h6>{v?.paymentDes}</h6>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              >
                                                <h6>${v?.paid}</h6>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              >
                                                <h6>${v?.adjusted}</h6>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                }}
                                              ></td>
                                            </tr>
                                          );
                                        })}
                                      <tr>
                                        <td
                                          style={{
                                            borderBottom: "1px solid black",
                                          }}
                                        ></td>

                                        <td
                                          style={{
                                            borderLeft: "1px solid black",
                                            width: "fit-content",
                                          }}
                                        >
                                          <h6
                                            style={{ textOverflow: "ellipsis" }}
                                          >
                                            <strong>
                                              {" "}
                                              {val?.claimStatus} as{" "}
                                              {new Date(
                                                val?.toDate
                                              ).toLocaleString(undefined, {
                                                dateStyle: "short",
                                              })}{" "}
                                            </strong>
                                          </h6>
                                        </td>

                                        <td></td>

                                        <td></td>

                                        <td></td>

                                        <td
                                          style={{ border: "1px solid black" }}
                                        >
                                          {" "}
                                          <h6>
                                            <strong>
                                              {" "}
                                              $
                                              {val?.claimAndPaymentsDto.reduce(
                                                (total, num) => {
                                                  return total + num["paid"];
                                                },
                                                0
                                              )}
                                            </strong>
                                          </h6>
                                        </td>
                                        <td
                                          style={{ border: "1px solid black" }}
                                        >
                                          <h6>
                                            <strong>
                                              {" "}
                                              $
                                              {val?.claimAndPaymentsDto.reduce(
                                                (total, num) => {
                                                  return (
                                                    total + num["adjusted"]
                                                  );
                                                },
                                                0
                                              )}
                                            </strong>
                                          </h6>
                                        </td>

                                        <td
                                          style={{
                                            cursor: "pointer",
                                            border: "1px solid black",
                                            padding: "5px",
                                          }}
                                        >
                                          {
                                            <h6>
                                              <strong>
                                                $
                                                {val.charge -
                                                  (val?.claimAndPaymentsDto.reduce(
                                                    (total, num) => {
                                                      return (
                                                        total + num["paid"]
                                                      );
                                                    },
                                                    0
                                                  ) +
                                                    val?.claimAndPaymentsDto.reduce(
                                                      (total, num) => {
                                                        return (
                                                          total +
                                                          num["adjusted"]
                                                        );
                                                      },
                                                      0
                                                    ))}
                                              </strong>
                                            </h6>
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </table> */}
                      </Grid>
                    </CustomAccordion>
                  );
                })}
            </CustomAccordion>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ClaimActivity;
