import * as yup from "yup";

// export const userSechema = yup.object().shape({
//   username: yup.string().trim().nullable().required("name is required"),
//   email: yup.string().trim().nullable().required("email is required"),
//   confirmPassword: yup
//     .string()
//     .trim()
//     .nullable()
//     .oneOf([yup.ref('password')], 'Passwords must match') 
//     // .matches(
//     //   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
//     //   "One capital , special , number required and minmun len"
//     // )
//     .required("Confirm Password is required"),
//   password: yup
//     .string()
//     .trim()
//     .nullable()
//     .matches(
//       /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
//       "One capital , special , number required"
//     )
//     .required("Password is required"),
//   // roleName: yup.string().nullable().required("User role is required"),
// });


export const userSechema = yup.object().shape({
  username: yup.string().trim().required("User Name is required"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().trim().required("Last Name is required"),
  });

export const updateUserSechema = yup.object().shape({
  username: yup.string().trim().nullable().required("name is required"),
  email: yup.string().trim().nullable().required("email is required"),
  roleName: yup.string().nullable().required("User role is required"),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .nullable()
    .matches(
     /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{6,}$/,
      "One capital , special , number required and minmun len"
    )
    .required("Old Password is required"),
  newPassword: yup
    .string()
    .trim()
    .nullable()
    .matches(
/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{6,}$/,
      "One capital , special , number required"
    )
    .required("New Password is required"),

  confirmPassword: yup
    .string()
    .trim()
    .nullable()
    .oneOf([yup.ref('newPassword')], 'Passwords must match') 
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //   "One capital , special , number required"
    // )
    .required("Confirm Password is required"),
});


export const registerSchema = yup.object().shape({
  username: yup.string().trim().required("User Name is required"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().trim().required("Last Name is required"),
  email: yup.string().trim().required("Email is required"),
  password: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{6,}$/,
      "Password must have at least one capital letter, one special character, and one number, and be 6 characters long"
    )
    .required("Password is required"),
    confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password')], 'Password must match')
    .required("Confirm Password is required"),
});


export const companySchema = yup.object().shape({
  companyName: yup.string().trim().required("Name is required"),
  address: yup.string().trim().required("Address is required"),
  email: yup.string().trim().nullable().required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  zipCode: yup.string().trim().required("Zipcode"),
});