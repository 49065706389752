import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { postData } from "../../config/axiosFunctions";

export const getClaimBatchPrint = createAsyncThunk(
  "getClaimBatchPrint",
  async (data) => {
    try {
      const response = await postData(`${path}/claimBatchPrint`, data);
      if (response) {
        return response?.result;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getClaimBatchPrintPdfData = async (pdfType, data) => {
  try {
    const response = await postData(`${path}/${pdfType}`, {
      // claimNumbers: data,
      claimId: data,
    });
    if (response) {
      return response?.result;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error);
  }
};
