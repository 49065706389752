import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPreview from "./../../../layout/CustomPreview";
import { feeSchedulesCols } from "../../../utils/gridCols";
import { getFeeScheduleAction } from "../../../features/actions/feeSchedulesAction";
import CustomModal from "../../../components/CustomModal";
import { feeScheduleVal } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import FeeScheduleType from "./FeeScheduleType";

const FeeSchedules = () => {
  const navigate = useNavigate();
  const [openNewFeeScheduleModal, setOpenNewFeeScheduleModal] = useState(false);
  const { getFeeScheduleData, loading } = useSelector(
    (state) => state.feeSchedule
  );
  // rows
  const rows =
    getFeeScheduleData?.result &&
    getFeeScheduleData.result?.feeSchedule?.length > 0
      ? getFeeScheduleData.result?.feeSchedule?.map((el) => ({
          id: el.feeSchedulesId,
          ...el,
        }))
      : [];
  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={feeSchedulesCols}
          handleGetAll={getFeeScheduleAction}
          moduleHeading={"Fee Schedules"}
          searchLabel="Search by First Name, Phone ,or Sequence Number"
          showNew={false}
          loading={loading.getAllSchedulesLoading}
          handleCell={(params) =>
            navigate(`/fee-schedule/update/${params.feeSchedulesId}`)
          }
        >
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => setOpenNewFeeScheduleModal(true)}
          >
            Add New
          </CustomButton>
        </CustomPreview>
      </Box>

      {/* modals here */}
      <CustomModal
        height={"auto"}
        open={openNewFeeScheduleModal}
        handleClose={() => setOpenNewFeeScheduleModal(false)}
        heading="New Fee Schedule"
      >
        {/* <CustomNewFeeScheduleFunction formik={formik} /> */}
        <FeeScheduleType />
        {/* <Typography component={"h2"}>Add Fee Schedule</Typography> */}
      </CustomModal>
    </>
  );
};

export default FeeSchedules;
