import React, { useState } from "react";
import { FormGroup } from "@mui/material";
import CustomSearchField from "./CustomSearchField";
import { Paper, Stack } from "@mui/material";
import { getData } from "../config/axiosFunctions";
import { Done } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import path from "../config/apiUrl";
const TypeableSearchField = ({
  label,
  handleModalOpen,
  name,
  formik,
  isRequired,
  apiUrl,
  len,
  resetField,
  keyName,
  disable,
  handleCustomSelect,
  fieldVal,
  icd = false,
}) => {
  // console.log(icd ? formik.values[name][0][keyName] : formik.values[name]);
console.log(fieldVal);

  const [CustomOpenPaper, setCustomOpenPaper] = useState(false);
  const [CustomResultData, setCustomResultData] = useState([]);

  const handleChange = async (e) => {
    const val = e.target.value;
    // formik.setFieldValue(name, val);
    // console.log(val);
    formik.setFieldValue(name, val);
console.log(val);

    if (val?.length >= len) {
      try {
        const response = await getData(`${path}${apiUrl}${val}`);
        if (response.result.length > 0) {
          setCustomResultData(response.result);
          setCustomOpenPaper(true);
        }
      } catch (e) {
        console.log(e, ": Error in fetching data");
        throw e;
      }
    } else {
      setCustomOpenPaper(false);
    }
  };

  const onItemClick = (item) => {
    console.log(item);

    if (handleCustomSelect) {
      handleCustomSelect(item);
      if (icd) {
        console.log("save ");

        formik.setFieldValue(name, item[keyName]);
      }
      setCustomOpenPaper(false);
    } else {
      formik.setFieldValue(name, item[keyName]);
      setCustomOpenPaper(false);
    }
  };

  return (
    <>
      <FormGroup sx={{ position: "relative" }}>
        <CustomSearchField
          isRequired={isRequired}
          // old
          // fieldVal={formik.values[name]}
          // adding new field value for icd
          fieldVal={icd ? fieldVal : formik.values[name]}
          name={name}
          handleChange={handleChange}
          handleBlur={formik.handleBlur}
          label={label}
          formik={formik}
          resetField={resetField}
          isTypeAble={true}
          disable={disable}
          handleModalOpen={handleModalOpen}
        />
        {CustomOpenPaper && (
          <Paper
            sx={{
              position: "absolute",
              top: "100%",
              left: 0,
              zIndex: 111,
              boxShadow: 3,
              width: "100%",
              maxHeight: "200px",
              overflowY: "auto",
              // background:'#7CB9E8',
              padding: "10px",
              width: "300px",
            }}
          >
            {CustomResultData?.map((item) => (
              <Stack sx={{ margin: "4px" }}>
                <CustomButton handleClick={() => onItemClick(item)}>
                  <Done fontSize="large" sx={{ marginRight: "5px" }} />
                  {item[keyName]}
                </CustomButton>
              </Stack>
            ))}
          </Paper>
        )}
      </FormGroup>
    </>
  );
};
export default TypeableSearchField;
