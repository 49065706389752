// import React, { useEffect, useState } from "react";
// import { Box, Typography } from "@mui/material";
// import CustomField from "../../../components/CustomField";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
// import CustomButton from "../../../components/CustomButton";
// import InsuranceAddress from "./InsuranceAddress";
// import { getData } from "../../../config/axiosFunctions";
// import path from "../../../config/apiUrl";
// import CustomAccordion from "../../../components/CustomAccordion";
// import CheckBox from "../../../components/CheckBox";
// import CustomSearchField from "../../../components/CustomSearchField";
// import CustomModal from "../../../components/CustomModal";
// import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
// import SearchNpi from "../practices/npi/SearchNpi";
// import { useConfirm } from "material-ui-confirm";
// import { payerIntialVal } from "../../../utils/formikInitValues";
// import { toast } from "react-toastify";


// const PayerForm = ({ formik }) => {
//   console.log("formik", formik);
//   const [openTaxonomyListModal, setOpenTaxonomyListModal] = useState(false);
//   const [searchNpiModal, setSearchNpiModal] = useState(false);
//   const [payerTypeOpt, setPayerTypeOpt] = useState([]);
//   const [networkTypeOpt, setNetworkTypeOpt] = useState([]);
//   const [insuranceTypeOpt, setInsuranceTypeOpt] = useState([]);
//   console.log(insuranceTypeOpt,"insuranceType")
//   const [clearingHouseModeOpt, setClearingHouseModeOpt] = useState([]);
//   const [defaultChargeStatusOpt, setDefaultChargeStatusOpt] = useState([]);
//   const [insuranceGroupOpt, setInsuranceGroupOpt] = useState([]);
//   const [openAddressMod,setOpenAddressMod]=useState('');
//   const [editIndex,setEditIndex]=useState(null);
//   const [editData,setEditData]=useState(null);
//   const [stateOpt, setStateOpt] = useState([]);
//   const [stateOptforAlternate, setStateOptforAlternate] = useState([]);
//   const [cityOpt, setCityOpt] = useState([]);
//   const [cityOptforAlternate, setCityOptforAlternate] = useState([]);
//   const DateSource = {
//     payerType: "/ct-payerType",
//     stateType: "/state",
//     cityType: "/city",
//     networkStatus:"/ct-networkStatus",
//     insuranceType:`/ct-insuranceType`,
//     insuranceGroup:'/ct-insuranceGroup',
//     clearingHouseMode:'/ct-clearinghouseProcessingMode',
//     defaultChargeStatus:'/ct-defaultChargeStatus',

//   };
//   const fetchData = async (url, setValues) => {
//     try {
//       let response = await getData(`${path}${url}`);
//       setValues(response.result);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleSelectTaxonomy = (taxonomyCode) => {
//     formik.setFieldValue(
//       "payerAlternatePracticeDto.payerAlternatePracticeTaxonomySpecialtyCode",
//       taxonomyCode
//     );
//   };
//   const handleSelectNpi = (npi) => {
//     formik.setFieldValue(
//       "payerAlternatePracticeDto.alternatePracticeNpiNo",
//       npi?.npiNo
//     );
//     formik.setFieldValue(
//       "payerAlternatePracticeDto.payerAlternatePracticeNpiId",
//       npi?.id
//     );
//   };

// useEffect(() => {
//   fetchData(DateSource.payerType, setPayerTypeOpt);
//   fetchData(DateSource.stateType, setStateOpt);
//   fetchData(DateSource.stateType, setStateOptforAlternate);
//   fetchData(DateSource.insuranceType, setInsuranceTypeOpt);
//   fetchData(DateSource.networkStatus, setNetworkTypeOpt);
//   fetchData(DateSource.insuranceGroup, setInsuranceGroupOpt);
//   fetchData(DateSource.clearingHouseMode, setClearingHouseModeOpt);  
//   fetchData(DateSource.defaultChargeStatus, setDefaultChargeStatusOpt);  

//   if (formik.values?.payerStateId) {
//     fetchData(`/city?stateId=${formik.values?.payerStateId}`, setCityOpt);
//   }
//   if (formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId) {
//     fetchData(`/city?stateId=${formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId}`, setCityOptforAlternate);
//   }
//   if (formik.values.insuranceGroupId) {
//     fetchData(`/ct-insuranceType/${formik.values.insuranceGroupId}`,setInsuranceTypeOpt);
//   }
// }, [
//   formik.values?.payerStateId,
//   formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId,
//   formik.values?.insuranceGroupId,
// ]);

//    const confirm = useConfirm();

 
//   return (
//     <Box>
//     {/* Taxonomy Model */}
//     <CustomModal
//       open={openTaxonomyListModal}
//       handleClose={() => setOpenTaxonomyListModal(false)}
//       height={"20px"}
//     >
//       <TaxonomyCategories
//         handleSelectTaxonomy={handleSelectTaxonomy}
//         handleClose={() => setOpenTaxonomyListModal(false)}
//       />
//     </CustomModal>
//     {/* npi modal */}
//     <CustomModal
//       open={searchNpiModal}
//       handleClose={() => setSearchNpiModal(false)}
//     >
//       <SearchNpi
//         //   setFieldValue={setFieldValue}
//         handleSelectNpi={handleSelectNpi}
//         handleClose={() => setSearchNpiModal(false)}
//         setSearchNpiModal={setSearchNpiModal}
//       />
//     </CustomModal>
//     <Box sx={{background:"white",borderRadius:"10px",padding:"15px",margin:"20px"}}>
//     <Box>
//       <CustomField
//         handleBlur={formik.handleBlur}
//         handleChange={formik.handleChange}
//         label="Name"
//         isRequired={true}
//         error={formik.errors}
//         touched={formik.touched}
//         name="insuranceDescriptionName"
//         type="text"
//         value={formik.values?.insuranceDescriptionName}
//       />
//     </Box>
//     <Box
//       display="grid"
//       gap="30px"
//       margin={"20px 0"}
//       sx={{
//         gridTemplateColumns: {
//           xs: "repeat(1, minmax(0, 1fr))",
//           sm: "repeat(1, minmax(0, 1fr))",
//           md: "repeat(2, minmax(0, 1fr))",
//         },
//       }}
//     >
//       <CustomField
//         handleBlur={formik.handleBlur}
//         handleChange={formik.handleChange}
//         label="Plan Name"
//         name="planName"
//         type="text"
//         value={formik.values?.planName}
//         isRequired={true}
//         error={formik.errors}
//         touched={formik.touched}
//       />
//       <CustomSelectBox2
//         dropdownOptions={networkTypeOpt.map((val) => {
//           return {
//             value: val.networkStatusName,
//             id: val.networkStatusId,
//           };
//         })}
//         label={"Network Status"}
//         name={"networkStatusId"}
//         value={formik.values?.networkStatusId}
//         handleChange={formik.handleChange}
//         handleBlur={formik.handleBlur}
//         formik={formik}
//       />
//     </Box>
   

//     <Box
//       display="grid"
//       gap="30px"
//       margin={"20px 0"}
//       sx={{
//         gridTemplateColumns: {
//           xs: "repeat(1, minmax(0, 1fr))",
//           sm: "repeat(1, minmax(0, 1fr))",
//           md: "repeat(2, minmax(0, 1fr))",
//         },
//       }}
//     >
//         <CustomSelectBox2
//         dropdownOptions={insuranceGroupOpt.map((val) => {
//           return {
//             value: val.insuranceGroupName,
//             id: val.insuranceGroupId,
//           };
//         })}
//         label={"Insurance Group"}
//         name={"insuranceGroupId"}
//         value={formik.values?.insuranceGroupId}
//         handleChange={formik.handleChange}
//         handleBlur={formik.handleBlur}
//         formik={formik}
//         showDefaultOpt={true}
//       />
//         <CustomSelectBox2
//         dropdownOptions={insuranceTypeOpt.map((val) => {
//           return {
//             value: val.insuranceTypeName,
//             id: val.insuranceTypeId,
//           };
//         })}
//         label={"Insurance Type"}
//         name={"insuranceTypeId"}
//         // disable={!formik.values.insuranceGroupId}
//         disable={formik.values.insuranceGroupId < 0}
//         value={formik.values?.insuranceTypeId}
//         handleChange={formik.handleChange}
//         handleBlur={formik.handleBlur}
//         formik={formik}
//       />
//     </Box>
//     <Box margin={"10px 0"}>
//       <CustomSelectBox2
//         dropdownOptions={defaultChargeStatusOpt.map((val) => {
//           return {
//             value: val.defaultChargeStatusName,
//             id: val.defaultChargeStatusId,
//           };
//         })}
//         label={"Default Charge Status"}
//         name={"defaultChargeStatusId"}
//         value={formik.values?.defaultChargeStatusId}
//         handleChange={formik.handleChange}
//         handleBlur={formik.handleBlur}
//         formik={formik}
//         showDefaultOpt={true}
//       />
//     </Box>
//     <Box margin={"10px 0"}>
//       <CustomSelectBox2
//         dropdownOptions={clearingHouseModeOpt.map((val) => {
//           return {
//             value: val.clearinghouseProcessingModeName,
//             id: val.clearinghouseProcessingModeId,
//           };
//         })}
//         label={"Clearinghouse Processing Mode"}
//         name={"clearinghouseProcessingModeId"}
//         value={formik.values?.clearinghouseProcessingModeId}
//         handleChange={formik.handleChange}
//         handleBlur={formik.handleBlur}
//         formik={formik}
//         showDefaultOpt={true}
//       />
//     </Box>
//     <Box
//       display="grid"
//       gap="30px"
//       margin={"20px 0"}
//       sx={{
//         gridTemplateColumns: {
//           xs: "repeat(1, minmax(0, 1fr))",
//           sm: "repeat(1, minmax(0, 1fr))",
//           md: "repeat(2, minmax(0, 1fr))",
//         },
//       }}
//     >
//       <CustomField
//         handleBlur={formik.handleBlur}
//         handleChange={formik.handleChange}
//         label="Reference #"
//         name="referenceNo"
//         type="text"
//         value={formik.values?.referenceNo}
//       />
//        <CustomField
//         handleBlur={formik.handleBlur}
//         handleChange={formik.handleChange}
//         label="Sequence #"
//         name="sequenceNo"
//         type="text"
//         disable={true}
//         value={formik.values?.sequenceNo}
//       />
//     </Box>
//     </Box>

//     <CustomAccordion heading={"ID Number"} defaultExpanded={true} margin={'20px'}>
//     <Box
//         display="grid"
//         gap="30px"
//         sx={{
//           gridTemplateColumns: {
//             xs: "repeat(1, minmax(0, 1fr))",
//             sm: "repeat(1, minmax(0, 1fr))",
//             md: "repeat(2, minmax(0, 1fr))",
//           },
//         }}
//       >
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="Insurance 835 ID"
//           name="insurance835Id"
//           type="text"
//           value={formik.values?.insurance835Id}
//         />
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="Insurance 837 ID"
//           name="insurance837Id"
//           type="text"
//           value={formik.values?.insurance837Id}
//         />
//       </Box>
//       <Box
//         display="grid"
//         gap="30px"
//         margin={"20px 0"}
//         sx={{
//           gridTemplateColumns: {
//             xs: "repeat(1, minmax(0, 1fr))",
//             sm: "repeat(1, minmax(0, 1fr))",
//             md: "repeat(2, minmax(0, 1fr))",
//           },
//         }}
//       >
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="Group Number"
//           name="groupNumber"
//           type="text"
//           value={formik.values?.groupNumber}
//         />
//           <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="Claim Office Number"
//           name="claimOfficeNumber"
//           type="text"
//           value={formik.values?.claimOfficeNumber}
//         />
//       </Box>
//       <Box
//         display="grid"
//         gap="30px"
//         margin={"20px 0"}
//         sx={{
//           gridTemplateColumns: {
//             xs: "repeat(1, minmax(0, 1fr))",
//             sm: "repeat(1, minmax(0, 1fr))",
//             md: "repeat(2, minmax(0, 1fr))",
//           },
//         }}
//       >
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="Payer ID/Medigap#"
//           name="mediaGapNumber"
//           type="text"
//           value={formik.values?.mediaGapNumber}
//         />
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           label="OCNA"
//           name="ocna"
//           type="text"
//           value={formik.values?.ocna}
//         />
//       </Box>
//       <Box>
//       </Box>
//     </CustomAccordion>
    
// <CustomAccordion heading="Address" defaultExpanded={true}>
//       <Box sx={{ padding: '16px' }}>
        
//         {/* Add Address Button */}
     
//           <CustomButton  
//             isAdd={true}
//             isBlue={true}
//             handleClick={() => {
//               if (formik.values.insuranceAddressDtos.length < 4) {
//                 setEditData(null);
//                 setOpenAddressMod(true);
//               } else {
//                 confirm({
//                   title: "You cannot add more than 4 addresses!",
//                   confirmationText: "Ok",
//                 });
//               }
//             }}
//           >
//             Add Address
//           </CustomButton>

//         {/* Insurance Addresses Section */}
//         {formik.values?.insuranceAddressDtos?.map((address, index) => (
//           <Box 
//             key={index} 
//             sx={{
//               display: 'flex', 
//               justifyContent: 'space-between', 
//               alignItems: 'center', 
//               margin: '12px 0', 
//               padding: '12px',
//               border: '1px solid #ddd', 
//               borderRadius: '8px',
//               boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
//               transition: 'all 0.3s ease',
//               '&:hover': {
//                 boxShadow: '0px 5px 12px rgba(0,0,0,0.2)',
//               },
//             }}
//           >
//             <Typography variant="body1" sx={{ fontSize: '18px', color: '#333' }}>
//   {address?.zipCodeId}, {address?.department}, {address?.address}
// </Typography>

//             <CustomButton  
           
//             width={'17%'}
//               isEdit={true}
//               isBlue={true}
//               handleClick={() => {
//                 setEditData(address);
//                 setOpenAddressMod(true);
//                 setEditIndex(index);
//               }}
//             >
//               Edit
//             </CustomButton>
//           </Box>
//         ))}

//         {/* Modal for Adding/Editing Address */}
//         <CustomModal
//           open={openAddressMod}
//           handleClose={() => setOpenAddressMod(false)}
//           handleClick={() => console.log("Btn")} 
//           heading={'Add Address'}
//           width={'65%'}
//         >
//           <InsuranceAddress
//             editIndex={editIndex}
//             formik={formik}
//             editData={editData}
//             handleClose={() => setOpenAddressMod(false)}
//           />
//         </CustomModal>
//       </Box>
//     </CustomAccordion>
//     <CustomAccordion heading={"Contact Information"} defaultExpanded={true}>
//       <Box
//         display="grid"
//         gap="30px"
//         margin={"20px 0"}
//         sx={{
//           gridTemplateColumns: {
//             xs: "repeat(1 , minmax(0, 1fr))",
//             sm: "repeat(1, minmax(0, 1fr))",
//             md: "repeat(1, minmax(0, 1fr))",
//           },
//         }}
//       >
//         <CustomField
//           handleBlur={formik.handleBlur}
//           handleChange={formik.handleChange}
//           isRequired={true}
//           error={formik.errors}
//           touched={formik.touched}
//           label="Claim Office Address"
//           name="claimOfficeAddress"
//           type="text"
//           value={formik.values?.claimOfficeAddress}
//         />
//       </Box>
//     </CustomAccordion>
//   </Box>

//   );
// };

// export default PayerForm;



import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomButton from "../../../components/CustomButton";
import InsuranceAddress from "./InsuranceAddress";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomAccordion from "../../../components/CustomAccordion";
import CheckBox from "../../../components/CheckBox";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import SearchNpi from "../practices/npi/SearchNpi";
import { useConfirm } from "material-ui-confirm";
import { payerIntialVal } from "../../../utils/formikInitValues";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { handleIsActive } from "../../../utils/customFunctions";


const PayerForm = ({ formik }) => {
  const navigate=useNavigate();
  console.log("formik", formik);
  const [openTaxonomyListModal, setOpenTaxonomyListModal] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [payerTypeOpt, setPayerTypeOpt] = useState([]);
  const [networkTypeOpt, setNetworkTypeOpt] = useState([]);
  const [insuranceTypeOpt, setInsuranceTypeOpt] = useState([]);
  console.log(insuranceTypeOpt,"insuranceType")
  const [clearingHouseModeOpt, setClearingHouseModeOpt] = useState([]);
  const [defaultChargeStatusOpt, setDefaultChargeStatusOpt] = useState([]);
  const [insuranceGroupOpt, setInsuranceGroupOpt] = useState([]);
  const [openAddressMod,setOpenAddressMod]=useState('');
  const [editIndex,setEditIndex]=useState(null);
  const [editData,setEditData]=useState(null);
  const [stateOpt, setStateOpt] = useState([]);
  const [stateOptforAlternate, setStateOptforAlternate] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [cityOptforAlternate, setCityOptforAlternate] = useState([]);
  const DateSource = {
    payerType: "/ct-payerType",
    stateType: "/state",
    cityType: "/city",
    networkStatus:"/ct-networkStatus",
    insuranceType:`/ct-insuranceType`,
    insuranceGroup:'/ct-insuranceGroup',
    clearingHouseMode:'/ct-clearinghouseProcessingMode',
    defaultChargeStatus:'/ct-defaultChargeStatus',

  };
  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectTaxonomy = (taxonomyCode) => {
    formik.setFieldValue(
      "payerAlternatePracticeDto.payerAlternatePracticeTaxonomySpecialtyCode",
      taxonomyCode
    );
  };
  const handleSelectNpi = (npi) => {
    formik.setFieldValue(
      "payerAlternatePracticeDto.alternatePracticeNpiNo",
      npi?.npiNo
    );
    formik.setFieldValue(
      "payerAlternatePracticeDto.payerAlternatePracticeNpiId",
      npi?.id
    );
  };

useEffect(() => {
  fetchData(DateSource.payerType, setPayerTypeOpt);
  fetchData(DateSource.stateType, setStateOpt);
  fetchData(DateSource.stateType, setStateOptforAlternate);
  fetchData(DateSource.insuranceType, setInsuranceTypeOpt);
  fetchData(DateSource.networkStatus, setNetworkTypeOpt);
  fetchData(DateSource.insuranceGroup, setInsuranceGroupOpt);
  fetchData(DateSource.clearingHouseMode, setClearingHouseModeOpt);  
  fetchData(DateSource.defaultChargeStatus, setDefaultChargeStatusOpt);  

  if (formik.values?.payerStateId) {
    fetchData(`/city?stateId=${formik.values?.payerStateId}`, setCityOpt);
  }
  if (formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId) {
    fetchData(`/city?stateId=${formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId}`, setCityOptforAlternate);
  }
  if (formik.values.insuranceGroupId) {
    fetchData(`/ct-insuranceType/${formik.values.insuranceGroupId}`,setInsuranceTypeOpt);
  }
}, [
  formik.values?.payerStateId,
  formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId,
  formik.values?.insuranceGroupId,
]);

   const confirm = useConfirm();

   const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/payer");
  };
 
  return (
<>
<form id="payerForm" onSubmit={formik.handleSubmit}>
<Box sx={{display:'flex',justifyContent:'start',padding:'15px',gap:'10px'}}>
    <CustomButton
              type="submit"
              isBlue={true}
              isSave={true}
              id="payerForm"
            >
              Save
            </CustomButton>

            <CustomButton isCancel={true} handleClick={handleCancel}>
              Cancel
            </CustomButton>
            {/* {formik.values.sequenceNo && (
             */}
            {formik.values.insuranceDescriptionId && (
    <Box sx={{ marginLeft:'auto'}}> 
      <CheckBox
        CheckedValue={!formik.values.isActive}
        handleChange={() =>
          handleIsActive(formik)
          // formik.setFieldValue("isActive",!formik.values.isActive)
        }
        label="Make this Payer inactive"
      />
    </Box>
  )}
    </Box>
    <Box sx={{background:"white",borderRadius:"10px",padding:"15px",margin:"20px"}}>
    <Box>
      <CustomField
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        label="Name"
        isRequired={true}
        error={formik.errors}
        touched={formik.touched}
        name="insuranceDescriptionName"
        type="text"
        value={formik.values?.insuranceDescriptionName}
      />
    </Box>
    <Box
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(2, minmax(0, 1fr))",
        },
      }}
    >
      <CustomField
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        label="Plan Name"
        name="planName"
        type="text"
        value={formik.values?.planName}
        isRequired={true}
        error={formik.errors}
        touched={formik.touched}
      />
      <CustomSelectBox2
        dropdownOptions={networkTypeOpt.map((val) => {
          return {
            value: val.networkStatusName,
            id: val.networkStatusId,
          };
        })}
        label={"Network Status"}
        name={"networkStatusId"}
        value={formik.values?.networkStatusId}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
      />
    </Box>
   

    <Box
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(2, minmax(0, 1fr))",
        },
      }}
    >
        <CustomSelectBox2
        dropdownOptions={insuranceGroupOpt.map((val) => {
          return {
            value: val.insuranceGroupName,
            id: val.insuranceGroupId,
          };
        })}
        label={"Insurance Group"}
        name={"insuranceGroupId"}
        value={formik.values?.insuranceGroupId}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
        showDefaultOpt={true}
      />
        <CustomSelectBox2
        dropdownOptions={insuranceTypeOpt.map((val) => {
          return {
            value: val.insuranceTypeName,
            id: val.insuranceTypeId,
          };
        })}
        label={"Insurance Type"}
        name={"insuranceTypeId"}
        // disable={!formik.values.insuranceGroupId}
        disable={formik.values.insuranceGroupId < 0}
        value={formik.values?.insuranceTypeId}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
      />
    </Box>
    <Box margin={"10px 0"}>
      <CustomSelectBox2
        dropdownOptions={defaultChargeStatusOpt.map((val) => {
          return {
            value: val.defaultChargeStatusName,
            id: val.defaultChargeStatusId,
          };
        })}
        label={"Default Charge Status"}
        name={"defaultChargeStatusId"}
        value={formik.values?.defaultChargeStatusId}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
        showDefaultOpt={true}
      />
    </Box>
    <Box margin={"10px 0"}>
      <CustomSelectBox2
        dropdownOptions={clearingHouseModeOpt.map((val) => {
          return {
            value: val.clearinghouseProcessingModeName,
            id: val.clearinghouseProcessingModeId,
          };
        })}
        label={"Clearinghouse Processing Mode"}
        name={"clearinghouseProcessingModeId"}
        value={formik.values?.clearinghouseProcessingModeId}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        formik={formik}
        showDefaultOpt={true}
      />
    </Box>
    <Box
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(2, minmax(0, 1fr))",
        },
      }}
    >
      <CustomField
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        label="Reference #"
        name="referenceNo"
        type="text"
        value={formik.values?.referenceNo}
      />
       <CustomField
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        label="Insurance Description Id"
        // name="sequenceNo"
        name="insuranceDescriptionId"
        type="number"
        disable={true}
        // value={formik.values?.sequenceNo}
        value={formik.values?.insuranceDescriptionId}
      />
    </Box>
    </Box>
    <Box sx={{margin:'18px'}}>
    <CustomAccordion heading={"ID Number"} defaultExpanded={true} margin={'20px'}>
    <Box
        display="grid"
        gap="30px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Insurance 835 ID"
          name="insurance835Id"
          type="text"
          value={formik.values?.insurance835Id}
        />
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Insurance 837 ID"
          name="insurance837Id"
          type="text"
          value={formik.values?.insurance837Id}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Group Number"
          name="groupNumber"
          type="text"
          value={formik.values?.groupNumber}
        />
          <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Claim Office Number"
          name="claimOfficeNumber"
          type="text"
          value={formik.values?.claimOfficeNumber}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Payer ID/Medigap#"
          name="mediaGapNumber"
          type="text"
          value={formik.values?.mediaGapNumber}
        />
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="OCNA"
          name="ocna"
          type="text"
          value={formik.values?.ocna}
        />
      </Box>
      <Box>
      </Box>
    </CustomAccordion>
    </Box>
    
    <Box sx={{margin:'18px'}}>
<CustomAccordion heading="Address" defaultExpanded={true}>
      <Box sx={{ padding: '16px' }}>
        
        {/* Add Address Button */}
     
          <CustomButton  
            isAdd={true}
            isBlue={true}
            handleClick={() => {
              if (formik.values.insuranceAddressDtos.length < 4) {
                setEditData(null);
                setOpenAddressMod(true);
              } else {
                confirm({
                  title: "You cannot add more than 4 addresses!",
                  confirmationText: "Ok",
                });
              }
            }}
          >
            Add Address
          </CustomButton>

        {/* Insurance Addresses Section */}
        {formik.values?.insuranceAddressDtos?.map((address, index) => (
          <Box 
            key={index} 
            sx={{
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              margin: '12px 0', 
              padding: '12px',
              border: '1px solid #ddd', 
              borderRadius: '8px',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: '0px 5px 12px rgba(0,0,0,0.2)',
              },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: '18px', color: '#333' }}>
  {address?.zipCodeId}, {address?.department}, {address?.address}
</Typography>

            <CustomButton  
           
            width={'17%'}
              isEdit={true}
              isBlue={true}
              handleClick={() => {
                setEditData(address);
                setOpenAddressMod(true);
                setEditIndex(index);
              }}
            >
              Edit
            </CustomButton>
          </Box>
        ))}

        {/* Modal for Adding/Editing Address */}
        <CustomModal
          open={openAddressMod}
          handleClose={() => setOpenAddressMod(false)}
          handleClick={() => console.log("Btn")} 
          heading={'Add Address'}
          width={'65%'}
        >
          <InsuranceAddress
            editIndex={editIndex}
            formik={formik}
            editData={editData}
            handleClose={() => setOpenAddressMod(false)}
          />
        </CustomModal>
      </Box>
    </CustomAccordion>
    </Box>
    <Box sx={{margin:'18px'}}>
    <CustomAccordion heading={"Contact Information"} defaultExpanded={true}>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1 , minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          label="Claim Office Address"
          name="claimOfficeAddress"
          type="text"
          value={formik.values?.claimOfficeAddress}
        />
      </Box>
    </CustomAccordion>
    </Box>
   {/* Taxonomy Model */}
   <CustomModal
   open={openTaxonomyListModal}
   handleClose={() => setOpenTaxonomyListModal(false)}
   height={"20px"}
 >
   <TaxonomyCategories
     handleSelectTaxonomy={handleSelectTaxonomy}
     handleClose={() => setOpenTaxonomyListModal(false)}
   />
 </CustomModal>
 {/* npi modal */}
 <CustomModal
   open={searchNpiModal}
   handleClose={() => setSearchNpiModal(false)}
 >
   <SearchNpi
     //   setFieldValue={setFieldValue}
     handleSelectNpi={handleSelectNpi}
     handleClose={() => setSearchNpiModal(false)}
     setSearchNpiModal={setSearchNpiModal}
   />
 </CustomModal>
 <CustomDialog
open={openCancelDialog}
isWarning={true}
handleClose={() => setOpenCancelDialog(false)}
handleConfirm={handleConfirmCancel}
handleCancel={() => setOpenCancelDialog(false)}
confirmButtonTitle="OK"
cancelButtonTitle="Cancel"
isCancel={true}
/>

 </form>
</>
  );
};

export default PayerForm;












