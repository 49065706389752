import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData} from "../../config/axiosFunctions";

export const getReferringProviderAction = createAsyncThunk("getReferringProvider", async (page) => {
  try {
    const newPage = page?.page+1
    console.log(newPage);
    
    const response = await getData(`${path}/referringProvider?page=${newPage}&pageSize=20`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const newReferringProviderAction = createAsyncThunk(
  "createReferringProvider",
  async (data) => {
    try {
      const response = await postData(`${path}/referringProvider`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "check Error");
      throw new Error(error);
    }
  }
);
export const getReferringProviderBySearchTermAction = 
createAsyncThunk("getReferringProvider", async (searchVals) => {
  console.log(searchVals, "searchVals")
  try {
    const response = await getData(
      `${path}/referringProvider/searchReferringProvider?searchValue=${searchVals?.searchTerm}&page=${searchVals?.page}&pageSize=20&showInActive=${searchVals?.showInActive}`
    );
    console.log(response, "action Referring Provider by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});

export const updateRefferingProvider = createAsyncThunk(
  "updateReferringProvider",
  async (data) => {
    try {
      const response = await updateData(
        // `${path}/referringProvider/${data.referringProviderSequenceNo}`,data);
        `${path}/referringProvider/${data.referringProviderId}`,data);
      console.log(response, "update referring action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getReferringProviderByIdAction = createAsyncThunk(
  "getReferringProviderById",
  async (referringProviderId) => {
    try {

      const response = await getData(`${path}/referringProvider/GetReferringProviderById?seqNo=${referringProviderId}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);