import React, { useState, useEffect } from "react";
import TextEditor from "./TextEditor";
import { useTheme, Grid, Box, Typography } from "@mui/material";
import CustomDatePicker from "./CustomDatePicker";
import { getData } from "../config/axiosFunctions";
import path from "../config/apiUrl";
import CheckBox from "./CheckBox";

const AddAlerts = ({ alertFormik }) => {
  console.log(alertFormik.values, "alertFormik");
  
  const [displayOpt, setDisplayOpt] = useState([]);
  const theme = useTheme();

  const dataFetchUrls = {
    displayOptions: `${path}/ct-displayOptions`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.displayOptions, setDisplayOpt);
  }, []);

  const handleChangeAlertField = (value) => {
    let allVal = [...alertFormik?.values?.displayOptions];
    console.log(allVal.length);
    
    if (value.displayOptionsId === 1) {
      
      if (allVal.length === displayOpt.length) {
        allVal = [];
      } else {
        allVal = [...displayOpt];
      }
    } else {
      let found = allVal?.findIndex(
        (v) => v?.displayOptionsId === value?.displayOptionsId
      );
      console.log(found);
      
      if (found === -1) {
        console.log("test");
        
        allVal.push(value);
      } else {
        console.log("test2");
        allVal.splice(found, 1);
      }

      // If any option other than global is deselected, remove the global option
      allVal = allVal.filter((v) => v?.displayOptionsId !== 1);
      // If all options except the global option are selected, add the global option
      if (allVal.length === displayOpt.length - 1) {
        allVal.push(displayOpt.find((opt) => opt.displayOptionsId === 1));
      }
     
    }
    alertFormik.setFieldValue("displayOptions", allVal);
  };
  return (
    <>
    <Grid container padding={2}>
      <Grid item xs={12}>
        <TextEditor
          value={alertFormik.values.message}
          onChange={(content) => {
            alertFormik.setFieldValue("message", content);
          }}
          error={alertFormik?.errors?.message}
        />
      </Grid>
   
<Grid item xs={12} sx={{ marginTop: "30px",border: "1px solid #6e6b6b",
            borderRadius: "5px", }}>
          <Box
            sx={{
              background: theme.palette.revenueStatement.primary,
              height: "20%",
              borderRadius: "5px 5px 0 0",
              padding: "0 3px",
            }}
          >
            <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
              Display Options
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "10px",
            }}
          >
      
              {displayOpt.map((val, ind) => (
                <CheckBox
                  key={ind}
                  val={val}
                  handleChange={() => handleChangeAlertField(val)}
                  label={val?.displayOptionsName}
                  CheckedValue={alertFormik.values?.displayOptions.some(
                    (v) => v?.displayOptionsId === val?.displayOptionsId
                  )}
                />
              ))}
          </Box>

      </Grid>
      
         <Grid
        container
        rowGap={2}
        columnSpacing={"20px"}
        sx={{
          border: "1px solid #6e6b6b",
          borderRadius: "5px",
          marginTop: "30px",
        }}
        margin={'1px'}
      >
        <Grid
          item
          xs={12}
          sx={{
            background: theme.palette.revenueStatement.primary,
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
            Effective Date Range
          </Typography>
        </Grid>

        <Grid item md={6} xs={12} padding={'10px'}>
          <CustomDatePicker
            dateLabel="Alert Start Date"
            isRequired={true}
            name="alertStartDate"
            dateValue={alertFormik.values.alertStartDate}
            handleDateChange={alertFormik.handleChange}
            handleDateBlur={alertFormik.handleBlur}
            formik={alertFormik}
            isFromTo={true}
            currentDate={true}
          />
        </Grid>

        <Grid item md={6} xs={12} padding={'10px'}>
          <CustomDatePicker
            dateLabel="Alert End Date"
            isRequired={true}
            name="alertEndDate"
            dateValue={alertFormik.values.alertEndDate}
            handleDateChange={alertFormik.handleChange}
            handleDateBlur={alertFormik.handleBlur}
            formik={alertFormik}
            isFromTo={true}
            dateBySelectPrev={alertFormik?.values?.alertStartDate}
          />
        </Grid>
      </Grid>
    </Grid>

  </>
  );
};

export default AddAlerts;
