import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacilityAction } from "../../../features/actions/facilityAction";
import { Box, Link, Stack, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getFacilityBySearchTermAction } from "../../../features/actions/facilityAction";
import CustomPreview from "./../../../layout/CustomPreview";
import { facilityCols } from "../../../utils/gridCols";
import CustomDialog from "../../../components/CustomDialog";
import { switchPracticeAction } from "../../../features/actions/userAction";

const Facility = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { getFacilityData, loading } = useSelector((state) => state.facility);
  const [openSwitchWarning, setOpenSwitchWarning] = useState(false);
  const [practiceForSwitch, setPracticeForSwitch] = useState({
    id: null,
    name:""
  });
  // rows
  const rows =
    getFacilityData?.result && getFacilityData.result?.facilities?.length > 0
      ? getFacilityData.result?.facilities?.map((el) => ({
          id: el.facilityId,
         ...el
        }))
      : [];

      const handleCell = (params) => {
        console.log(params, "checkParams")
        // const practiceSeq = localStorage.getItem("practiceSequenceNo");
        const pracId= localStorage.getItem("practiceId");
        // if (Number(practiceSeq) === params?.practiceSequenceNo) {
          if (Number(pracId) === params?.practiceId) {
          setOpenSwitchWarning(false);
          setPracticeForSwitch({
            // seqno:null,
            id:null,
            name:""
          })
          // navigate(`/facility/update/${params?.sequenceNo}`)
          navigate(`/facility/update/${params?.facilityId}`)
        } else {
          setOpenSwitchWarning(true);
          setPracticeForSwitch({
            // seqno:params.practiceSequenceNo,
            id:params.practiceId,
            name:params?.practiceName
          })
        }
      };

      const handleSwitchPractice = async (getId) => {
        try {
            //  localStorage.setItem("practiceSequenceNo", getSeq)
            localStorage.setItem("practiceId", getId)
          // await dispatch(switchPracticeAction(getSeq)).then(({ payload }) =>
          await dispatch(switchPracticeAction(getId)).then(({ payload }) =>
            localStorage.setItem("token", payload.result.token)
          );
          window.location.reload();
        } catch (err) {
          console.log("Error switching practice", err);
        }
        return;
      };
  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={facilityCols}
        loading={loading}
        handleGetAll={getFacilityAction}
        paginationDetail={getFacilityData}
        handleBySearch={getFacilityBySearchTermAction}
        moduleHeading={'Facility'}
        handleCell={(params) => handleCell(params)}
        searchLabel="Search by First Name, Phone ,or Sequence Number"
        showAll={true}
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/facility/new")}
          >
            Add Facility
          </CustomButton>
        </Stack>
      </CustomPreview>

        {/* custo modal */}
        <CustomDialog
        open={openSwitchWarning}
        confirmButtonTitle={"Ok"}
        isWarning={true}
        handleConfirm={() => setOpenSwitchWarning(false)}
      >
        <Typography component={"h2"}>
          {/* You need to switch the practice <Link sx={{cursor:"pointer"}} onClick={() => handleSwitchPractice(practiceForSwitch?.seqno)}>{practiceForSwitch?.name} ({practiceForSwitch?.seqno})</Link>  to edit the facility */}
          You need to switch the practice <Link sx={{cursor:"pointer"}} onClick={() => handleSwitchPractice(practiceForSwitch?.id)}>{practiceForSwitch?.name} ({practiceForSwitch?.id})</Link>  to edit the facility
        </Typography>
      </CustomDialog>
    </Box>
  );
};

export default Facility;
