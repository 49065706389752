// import { Search, Close } from "@mui/icons-material";
// import { IconButton, useTheme } from "@mui/material";
// import React from "react";
// import { getIn } from "formik";

// const CustomSearchField = ({
//   label,
//   fieldVal,
//   handleChange,
//   handleBlur,
//   name,
//   isRequired,
//   resetField,
//   formik,
//   disable,
//   handleModalOpen,
//   isTypeAble = false,
// }) => {
//   const hasError =
//     isRequired && getIn(formik?.errors, name) && getIn(formik?.touched, name);
//   const theme = useTheme();
//   console.log(name, fieldVal);

//   const handleClear = () => {
//     console.log("Field Value before reset:", fieldVal);
//     console.log("Field Value before reset:", typeof fieldVal);
//     if (typeof resetField === "function") {
//       const updatedFields = resetField();
//       console.log(updatedFields);

//       formik.setValues({
//         ...formik.values,
//         ...updatedFields,
//       });
//     } else if (typeof resetField === "object") {
//       formik.setValues({
//         ...formik.values,
//         ...resetField,
//       });
//     console.log("Field Value after reset:", resetField );

//     }
//   };

//   return (
//     <div>
//       <label
//         className="customLabel"
//         style={{ color: theme.palette.Labeltext.primary }}
//         htmlFor={name}
//       >
//         {label}
//       </label>

//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           position: "relative",
//           pointerEvents: disable ? "none" : "auto",
//           opacity: disable ? 0.6 : 1,
//           border: hasError
//             ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//             : `1px solid ${theme.palette.fieldsBorder.primary}`,
//           background: theme.palette.customFieldsBackground.primary,
//           color: theme.palette.btnCancelText.primary,
//         }}
//       >
//         <input
//           style={{
//             background: "transparent",
//             border: "none",
//             outline: "none",
//             color: "inherit",
//             flex: 1,
//             fontSize: "1.5rem",
//             padding: "0 10px",
//             whiteSpace: "nowrap",
//             overflow: "hidden",
//             textOverflow: "ellipsis",
//             letterSpacing: "-0.05em",
//             cursor: isTypeAble ? "text" : "pointer",
//             width: "calc(100% - 50px)",
//             marginRight: "10px",
//             textTransform: "uppercase",
//           }}
//           className="customSearchField"
//           type="text"
//           autoComplete="off"
//           value={fieldVal !== null ? fieldVal : ""}
//           name={name}
//           onChange={isTypeAble && handleChange}
//           onClick={isTypeAble === false && handleModalOpen}
//           onBlur={handleBlur}
//           spellCheck={false}
//           disabled={disable}
//         />
//         <div style={{ display: "flex" }}>
//           {(Array.isArray(fieldVal) && fieldVal.length > 0) ||
//           (fieldVal && !Array.isArray(fieldVal)) ? (
//             <IconButton
//               onClick={handleClear}
//               aria-label="clear input"
//               edge="end"
//             >
//               <Close />
//             </IconButton>
//           ) : null}

//           <IconButton onClick={handleModalOpen}>
//             <Search />
//           </IconButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CustomSearchField;





import { Search, Close } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import React from "react";
import { getIn } from "formik";

const CustomSearchField = ({
  label,
  fieldVal,
  handleChange,
  handleBlur,
  name,
  isRequired,
  resetField,
  formik,
  disable,
  handleModalOpen,
  isTypeAble = false,
}) => {
  const hasError =
    isRequired && getIn(formik?.errors, name) && getIn(formik?.touched, name);
  const theme = useTheme();

  const handleClear = () => {
    console.log("Field Value before reset:", fieldVal);
    console.log("Field Value before reset:", typeof fieldVal);

    if (typeof resetField === "function") {
      const updatedFields = resetField();
      console.log(updatedFields);

 console.log(updatedFields,"updated fields")
      Object.keys(updatedFields).forEach(field => {
        formik.setFieldValue(field, updatedFields[field]);
      });
    } else if (typeof resetField === "object") {
      Object.keys(resetField).forEach(field => {
        formik.setFieldValue(field, resetField[field]);
      });
    }
  };

  return (
    <div>
      <label
        className="customLabel"
        style={{ color: theme.palette.Labeltext.primary }}
        htmlFor={name}
      >
        {label}
      </label>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          pointerEvents: disable ? "none" : "auto",
          opacity: disable ? 0.6 : 1,
          border: hasError
            ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
            : `1px solid ${theme.palette.fieldsBorder.primary}`,
          background: theme.palette.customFieldsBackground.primary,
          color: theme.palette.btnCancelText.primary,
        }}
      >
        <input
          style={{
            background: "transparent",
            border: "none",
            outline: "none",
            color: "inherit",
            flex: 1,
            fontSize: "1.5rem",
            padding: "0 10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            letterSpacing: "-0.05em",
            cursor: isTypeAble ? "text" : "pointer",
            width: "calc(100% - 50px)", // Adjusted width so the icon doesn't overlap
            marginRight: "10px",
            textTransform: "uppercase",
          }}
          className="customSearchField"
          type="text"
          autoComplete="off"
          value={fieldVal !== null ? fieldVal : ""}
          name={name}
          onChange={isTypeAble && handleChange}
          onClick={isTypeAble === false && handleModalOpen}
          onBlur={handleBlur}
          spellCheck={false}
          disabled={disable}
        />
        <div style={{ display: "flex" }}>
          {(Array.isArray(fieldVal) && fieldVal.length > 0) ||
          (fieldVal && !Array.isArray(fieldVal)) ? (
            <IconButton
              onClick={handleClear}
              aria-label="clear input"
              edge="end"
            >
              <Close />
            </IconButton>
          ) : null}

          <IconButton onClick={handleModalOpen}>
            <Search />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchField;

