import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import PostPayGrid from "./PostPayGrid";
import PostPayDetail from "./PostPayDetail";
import path from "../../config/apiUrl";
import axios from "axios";
import CustomModal from "../../components/CustomModal";
import MultipleClaims from "./MultipleClaims";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import logo from "../../../src/assets/Applied.png";
// import MainHeading from "../../components/MainHeading";
import CustomDialog from "../../components/CustomDialog";
import "./Payment.css";
import { toast } from "react-toastify";

const PostPayment = ({ setApplyEob, setShowPostPay, formik }) => {
  const theme = useTheme(); // Access the current theme
  // const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [multipleClaimData, setMultipleClaimData] = useState([]);
  const [openMultiClaimMod, setOpenMultiClaimMod] = useState(false);
  const [appliedValue, setAppliedValue] = useState(0);

  const handleCancel = () => {
    const conform = window.confirm("Are you sure you want to cancel?");
    if (conform) {
      formik.resetForm();
      setShowPostPay(false);
      setApplyEob(false);
    }
  };

  // const handleGetMultiClaims = async () => {
  //   const payerId = formik.values.insuranceDescriptionId;
  //   try {
  //     const response = await axios.get(`${path}/insuranceClaimDetail/InsuranceId?InsuranceId=${payerId}`)
  //   }catch (error) {
  //     console.log("Error in getting multiple claims", error);
  //   }
  // };

  useEffect(() => {
    const sumAppliedValue = () => {
      let sum = 0;
      for (let i = 0; i < formik.values._PaymentClaimDto.length; i++) {
        const element = formik.values._PaymentClaimDto[i];
        const totalApplied = element._PaymentDetailDto.reduce(
          (acc, current) => acc + current.allowedAmount,
          0
        );
        sum += totalApplied;
        console.log("sum", sum);
      }
      setAppliedValue(sum);
    };

    sumAppliedValue();
  }, [formik.values._PaymentClaimDto]);
  // const handleCancel = () => {
  //   setOpenCancelDialog(true);
  // };

  const handleConfirmCancel = () => {
    formik.resetForm();
    setShowPostPay(false);
    setApplyEob(false);
  };

  return (
    <>
      <div style={{ padding: "10px", width: "98%", marginLeft: "-10px" }}>
        {/* <MainHeading mainheading={"New Payment"} /> */}
      </div>

      <Box margin="20px">
        {showDetail ? (
          <Box sx={{ width: "100%" }}>
            <PostPayDetail
              formik={formik}
              setShowDetail={setShowDetail}
              selectedClaim={selectedClaim}
            />
          </Box>
        ) : (
          <div>
            <Stack flexDirection={"row"} alignItems="center">
              <CustomButton
                variant="contained"
                fontSize="1.1rem"
                width="200px"
                isBlue={true}
                isSave={true}
                handleClick={() => {
                  if (appliedValue > formik.values.paymentAmount) {
                    toast.info("Applied amount cannot be greater than amount");
                  } else {
                    formik.handleSubmit();
                  }
                }}
                disabled={appliedValue === 0}
              >
                Save Payment
              </CustomButton>
              {/* <CustomButton
                fontSize="1.1rem"
                margin="0 5px 0"
                isBlue={false}
                width="200px"
                variant="contained"
                color="error"
                isCancel={true}
                handleClick={handleCancel}
              >
                Cancel
              </CustomButton> */}
              <CustomButton
                fontSize="1.1rem"
                margin="0 5px 0"
                width="200px"
                variant="contained"
                color="error"
                isCancel={true}
                handleClick={handleCancel}
              >
                Cancel
              </CustomButton>
              {/* {formik.values.paymentByTypeId !== 1 &&
                !formik.values.isClaim && (
                  <CustomButton
                    fontSize="1.1rem"
                    margin="0 5px 0"
                    variant="contained"
                    isBlue={false}
                    isSearch={true}
                  >
                    Claims at {formik.values.insuranceDescription}
                  </CustomButton>
                )} */}

              {/* {formik.values.paymentByTypeId === 2 && (
                <Stack flexDirection="row" alignItems="center">
                  <CustomButton
                    // margin="15px 0 "
                    variant="contained"
                    width="200px"
                    fontSize="1.1rem"
                    margin="0 3px 0"
                    isBlue={false}
                    isAdd={true}
                    handleClick={handleGetMultiClaims}
                  >
                    Add Claims
                  </CustomButton>
                </Stack>
              )} */}
            </Stack>

            {/* payment */}
            <Stack direction="column">
              <Stack margin="30px 0 0">
                <Typography component="h4" sx={{ fontWeight: 500 }}>
                  Payment -{" "}
                  {formik.values.paymentMethodId === 1
                    ? "Check"
                    : formik.values.paymentMethodId === 2
                    ? "EFT"
                    : "Card"}{" "}
                  from{" "}
                  <span style={{ color: "blue" }}>
                    {formik.values.paymentByTypeId === 1
                      ? `${formik.values.patientFirstName} ${formik.values.patientLastName} (${formik.values.patientId})`
                      : `${formik.values.insuranceDescription} (${formik.values.insuranceDescriptionId})`}
                  </span>{" "}
                  received on {formik.values.receiveDate}
                </Typography>

                {formik.values.paymentMethodId === 1 && (
                  <Typography component={"h4"} sx={{ fontWeight: 500 }}>
                    Check #{" "}
                    <span style={{ fontWeight: 500 }}>
                      {formik.values.checkNumber}
                    </span>
                  </Typography>
                )}
              </Stack>

              <Stack direction="row">
                <Box
                  className="InnnerBox"
                  sx={{
                    width: { md: "170px" },
                    height: { md: "80px" },
                    padding: "5px",
                    background: theme.palette.customFieldsBackground.primary,
                  }}
                >
                  <Box className="amountInnnerBox1">
                    <img src={logo} alt="" />
                  </Box>
                  <Box
                    sx={{
                      margin: "20px 13px 0",
                    }}
                  >
                    <Typography variant="h5" component="span">
                      <strong
                        style={{
                          color: theme.palette.textMainHeading.primary,
                        }}
                      >
                        Amount:
                      </strong>{" "}
                      <br />
                      <div
                        style={{
                          color: theme.palette.textMainHeading.primary,
                        }}
                        className="text-center"
                      >
                        {" "}
                        $ {formik.values.paymentAmount}
                      </div>
                      <br />
                    </Typography>
                  </Box>
                </Box>

                {/* Applied */}
                <Box
                  className="InnnerBox"
                  sx={{
                    width: { md: "170px" },
                    height: { md: "80px" },
                    padding: "5px",
                    background: theme.palette.customFieldsBackground.primary,
                  }}
                >
                  <Box className="appliedInnerBox1">
                    <img src={logo} alt="" />
                  </Box>
                  <Box
                    sx={{
                      margin: "20px 13px 0",
                    }}
                  >
                    <Typography variant="h5" component="span">
                      <strong
                        style={{
                          margin: "0A 10px 0",
                          color: theme.palette.textMainHeading.primary,
                        }}
                      >
                        Applied:
                      </strong>{" "}
                      <br />
                      <div
                        style={{
                          color: theme.palette.textMainHeading.primary,
                        }}
                        className="text-center"
                      >
                        {" "}
                        $ {appliedValue}
                      </div>
                      <br />
                    </Typography>
                  </Box>
                </Box>
                {/* Unapplied */}

                <Box
                  className="InnnerBox"
                  sx={{
                    width: { md: "170px" },
                    height: { md: "80px" },
                    padding: "5px",
                    background: theme.palette.customFieldsBackground.primary,
                  }}
                >
                  <Box className="unappliedInnerBox1">
                    <img src={logo} alt="" />
                  </Box>

                  <Box
                    sx={{
                      margin: "20px 5px 0",
                    }}
                  >
                    <Typography variant="h5" component="span">
                      <strong
                        style={{
                          color: theme.palette.textMainHeading.primary,
                        }}
                      >
                        Unapplied:
                      </strong>{" "}
                      <br />
                      <div
                        style={{
                          color: theme.palette.textMainHeading.primary,
                        }}
                        className="text-center"
                      >
                        $ {formik.values.paymentAmount - appliedValue}
                      </div>
                      <br />
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Stack>
            <PostPayGrid
              setShowDetail={setShowDetail}
              formik={formik}
              setSelectedClaim={setSelectedClaim}
            />
          </div>
        )}
      </Box>
      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
      {/* custom modal for multiple claims */}
      <CustomModal
        open={openMultiClaimMod}
        handleClose={() => {
          setOpenMultiClaimMod(false);
        }}
      >
        <MultipleClaims
          formik={formik}
          multipleClaimData={multipleClaimData}
          handleClose={() => setOpenMultiClaimMod(false)}
        />
      </CustomModal>
    </>
  );
};

export default PostPayment;
