import React from "react";
import { useState } from "react";
import path from "../../../config/apiUrl";
import axios from "axios";
import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { useSelector } from "react-redux";
import { posCols } from "../../../utils/gridCols";
import CustomPreview from "../../../layout/CustomPreview";
import { getAllPosCodesActios } from "../../../features/actions/posCodeAction";

const PosCodes = ({ handleCellClick }) => {
  const { getAllPos } = useSelector((state) => state.posCodes);
  console.log(getAllPos, "posCode");
  const [posDetail, setPosDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const rows =
    getAllPos?.result?.data && getAllPos?.result?.data?.length > 0
      ? getAllPos?.result?.data?.map((el) => ({
          id: el.posCodeId,
          ...el,
        }))
      : [];
  // const fetchPosCodes = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${path}/PosCode`,{
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     if (response.status === 200) {
  //       const data = await response.data;
  //       setPosDetail(data.result);
  //     } else {
  //       setPosDetail([]);
  //     }
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const rows = posDetail.map((el) => ({
  //   id: el.posCodeId,
  //   posCode: el.posCode,
  //   posDescription: el.posDescription,
  // }));

  // useEffect(() => {
  //   fetchPosCodes();
  // }, []);

  return (
    <CustomPreview
      rows={rows}
      columns={posCols}
      handleGetAll={getAllPosCodesActios}
      paginationDetail={getAllPosCodesActios}
      // handleBySearch={}
      moduleHeading={"Pos Codes"}
      handleCell={(params) => handleCellClick(params)}
      searchLabel="Search by Codes"
    ></CustomPreview>
    // <Box sx={{ width: "100%" }}>
    //   {isLoading ? (
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: "50%",
    //         left: "50%",
    //         transform: "translate(-50%, -50%)",
    //       }}
    //     >
    //       <CircularProgress />
    //     </Box>
    //   ) : (
    //     <CustomDataGrid
    //       rows={rows}
    //       columns={columns}
    //       handleCellClick={(params) => handleCellClick(params)}
    //     />
    //   )}
    // </Box>
  );
};

export default PosCodes;
