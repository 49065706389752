import React, { useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
// import MainHeading from "../../../components/MainHeading";
import {
  AccordionDetails,
  Box,
  Select,
  Stack,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
// import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";
import "./ManageAccountDetails.css";
import DebitAccount from "./DebitAccount";
import CustomModal from "../../../components/CustomModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CreditAccount } from "./CreditAccount";
// import { handleCancel } from "../../../utils/customFunctions";
import RefundAccount from "./RefundAccount";
import path from "../../../config/apiUrl";
import { getData, 
  // postData,
   updateData } from "../../../config/axiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getPatientByAccountNoAction, getPatientByIdAction } from "../../../features/actions/createPatientAction";
import { useFormik } from "formik";
import Loading from "../../../components/Loading";
import CustomAccordion from "../../../components/CustomAccordion";
import {
  creditInitialValueManageAccount,
  debitInitialValueManageAccount,
  refundIntValManageAccount,
} from "../../../utils/formikInitValues";
// import { debitSchemaForDebit } from "../../../schemas/patientSchema";
import { toast } from "react-toastify";
// import { useConfirm } from "material-ui-confirm";

const ManageAccountDetails = () => {
  // const { accountNo } = useParams();
  const { patientId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // let accountNo = "1614719518";
  const [openDebitAcc, setOpenDebitAcc] = useState(false);
  const [openCreditAcc, setOpenCreditAcc] = useState(false);
  const [openRefundAcc, setOpenRefundAcc] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const { singlePatientData, singlePatientLoading } = useSelector(
    (state) => state.patient
  );
  const patientData = singlePatientData?.result;

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, action) => {
      try {
        const response = await updateData(
          // `${path}/manageAccount/${accountNo}`,
          `${path}/manageAccount/${patientId}`,
          values
        );
        if (response) {
          toast.success("Successfully");
          navigate("/patients/manage-account");
        }
      } catch (error) {
        console.log("error", error);
        toast.error("Error in post");
      }
    },
  });

  // debitFormFormik

  const debitFormik = useFormik({
    initialValues: debitInitialValueManageAccount,
    // validationSchema : debitSchemaForDebit,
    onSubmit: (values, action) => {
      let val = {
        ...values,
        // patientAccountNo: accountNo,
        patientId: patientData?.patientId,
      };
      let debitList = formik.values?.debitAccountDto;
      debitList.push(val);
      formik.setFieldValue("debitAccountDto", debitList);
      action.resetForm();
      setOpenDebitAcc(false);
    },
  });
  // credit Form Formik
  const creditFormik = useFormik({
    initialValues: creditInitialValueManageAccount,
    onSubmit: (values, action) => {
      let val = {
        ...values,
        // patientAccountNo: accountNo,
        patientId: patientData?.patientId,
      };
      let creditList = formik.values?.creditAccountDto;
      creditList.push(val);
      formik.setFieldValue("creditAccountDto", creditList);
      action.resetForm();
      setOpenCreditAcc(false);
    },
  });

  const handleRefundCalculation = (list, currentRefundAmount) => {
    let availableCredit = formik.values.creditAccountDto.reduce(
      (prev, current) => prev + current.amount,
      0
    );

    if (
      currentRefundAmount >
      formik.values.patientCredit + formik.values.insuranceCredit
    ) {
      toast.info(
        "Refund amount should be less than or equal to available credit"
      );
      refundFormik.resetForm();
      setOpenRefundAcc(false);
      return;
    } else {
      let creditAccountFormik = formik.values.creditAccountDto;
      let updateCreditAccount = [];
      for (let index = 0; index < creditAccountFormik.length; index++) {
        const element = creditAccountFormik[index];

        if (
          currentRefundAmount > element.amount &&
          element.amount < currentRefundAmount
        ) {
          let current = element.amount;
          element.amount = 0;
          element.remaining = 0;
          currentRefundAmount -= current;
        } else if (currentRefundAmount > 0) {
          let current = element.amount;
          element.amount = current - currentRefundAmount;
          element.remaining = current - currentRefundAmount;
          currentRefundAmount = 0;
        }
        if (element.amount > 0) {
          updateCreditAccount.push(element);
        }
      }
      formik.setFieldValue("creditAccountDto", updateCreditAccount);
      formik.setFieldValue("refundAccountDto", list);
      setOpenRefundAcc(false);
    }
  };

  const refundFormik = useFormik({
    initialValues: refundIntValManageAccount,
    onSubmit: (values, action) => {
      let val = {
        ...values,
        // patientAccountNo: accountNo,
        patientId: patientData?.patientId,
      };
      let refundList = formik.values?.refundAccountDto;
      refundList.push(val);
      handleRefundCalculation(refundList, values.amount);
      action.resetForm();
    },
  });

  const dataSources = {
    status: `${path}/ct-claimStatus`,
  };

  const fetchData = async (setState, path) => {
    try {
      const { result } = await getData(path);
      setState(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(setStatusList, dataSources.status);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        // const { result } = await getData(`${path}/manageAccount/${accountNo}`);
        const { result } = await getData(`${path}/manageAccount/${patientId}`);
        // console.log("response", result);
        formik.setValues((prevVal) => ({
          ...prevVal,
          ...result,
        }));
      } catch (error) {
        console.log(error);
      }
    })();
    // dispatch(getPatientByAccountNoAction(accountNo));
    // dispatch(getPatientByIdAction(accountNo));
  // }, [accountNo, dispatch]);
    dispatch(getPatientByIdAction(patientId));
  }, [patientId, dispatch]);


  // const handleClaimChargesStatusChange = (chargesId, claimNumber, e) => {
  const handleClaimChargesStatusChange = (chargesId, claimId, e) => {
    let value = e.target.value;
    let claimManageAccountDto = formik.values?.claimManageAccountDto;
    let findClaim = claimManageAccountDto.find(
      // (e) => e?.claimNumber === claimNumber
      (e) => e?.claimId === claimId
    );
    console.log(claimManageAccountDto, "findCharges above");
    let findCharge = findClaim?.claimChargesManageAccountDto.find(
      (v) => v?.claimChargeId === chargesId
    );
    findCharge.claimStatusId = Number(value);
    formik.setFieldValue("claimManageAccountDto", claimManageAccountDto);
  };

  // handle Arrows
  // const handleArrow = (step, claimNumber, claimChargeId) => {
  const handleArrow = (step, claimId, claimChargeId) => {
    if (step === "step1") {
      console.log("first if");
      let claimManageAccountDto = formik.values?.claimManageAccountDto;
      let findClaim = claimManageAccountDto.find(
        // (e) => e?.claimNumber === claimNumber
        (e) => e?.claimId === claimId
      );
      findClaim.isFalse = !findClaim.isFalse;
      console.log("findClaim", findClaim);
      formik.setFieldValue("claimManageAccountDto", claimManageAccountDto);
    } else if (step === "step2") {
      let claimManageAccountDto = formik.values?.claimManageAccountDto;
      let findClaim = claimManageAccountDto.find(
        // (e) => e?.claimNumber === claimNumber
        (e) => e?.claimId === claimId
      );
      console.log(claimManageAccountDto, "findCharges above");
      let findCharge = findClaim?.claimChargesManageAccountDto.find(
        (v) => v?.claimChargeId === claimChargeId
      );
      findCharge.isFalse = !findCharge.isFalse;
      formik.setFieldValue("claimManageAccountDto", claimManageAccountDto);
    }
  };

  return (
    <>
      <Box margin="20px" paddingBottom="10px">
        {/* <MainHeading mainheading={"Manage Account Details"} /> */}
        {singlePatientLoading ? (
          <Loading />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                direction="row"
                alignContent="center"
                columnGap={2}
                sx={{
                  order: { xs: 1, sm: 1, md: 3 },
                  marginBottom: 2,
                }}
              >
                <CustomButton type="submit" isBlue={true} isEdit={true}>
                  Save
                </CustomButton>
                <CustomButton
                  isCancel={true}
                  handleClick={() => {
                    formik.resetForm();
                    navigate("/patients/manage-account");
                    // handleCancel(
                    //   // formik,
                    //   "/patients/manage-account",
                    //   navigate
                    // );
                  }}
                >
                  Cancel
                </CustomButton>
              </Stack>
              <Stack
                direction="row"
                alignContent="center"
                columnGap={2}
                marginBottom={2}
                sx={{
                  order: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Typography variant="h3" fontWeight={600}>
                  {patientData?.firstName} {patientData?.lastName}
                </Typography>
                <Typography variant="h3" fontWeight={600}>
                  {/* {accountNo} */}
                  {patientId}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignContent="center"
                columnGap={2}
                marginBottom={2}
                sx={{
                  order: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Typography variant="h3" fontWeight={600}>
                  Patient Balance: {formik.values?.patientBalance}
                </Typography>
                <Typography variant="h3" fontWeight={600}>
                  Patient Credit: {formik.values?.patientCredit}{" "}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignContent="center"
                columnGap={2}
                marginBottom={2}
                sx={{
                  order: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Typography variant="h3" fontWeight={600}>
                  Insurance Balance: {formik.values?.insuranceBalance}
                </Typography>
                <Typography variant="h3" fontWeight={600}>
                  Insurance Credit: {formik.values?.insuranceCredit}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignContent="center"
                columnGap={2}
                marginBottom={2}
                sx={{
                  order: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <CustomButton
                  isBlue={true}
                  handleClick={() => setOpenDebitAcc(true)}
                >
                  Debit Account
                </CustomButton>
                <CustomButton
                  isBlue={true}
                  handleClick={() => setOpenCreditAcc(true)}
                >
                  Credit Account
                </CustomButton>
                <CustomButton
                  isBlue={true}
                  handleClick={() => setOpenRefundAcc(true)}
                >
                  Refund Credit
                </CustomButton>
              </Stack>
              <CustomAccordion
                defaultExpanded={true}
                heading="Transaction Listing"
              >
                <AccordionDetails>
                  {formik?.values?.claimManageAccountDto &&
                  formik?.values?.claimManageAccountDto.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead
                          style={{
                            background: theme.palette.infocodesHeader.primary,
                            color: theme.palette.sidebarDDownText.primary,
                          }}
                          // id="t-header"
                        >
                          <TableRow>
                            <TableCell id="description">
                              DOS/Recived Date
                            </TableCell>

                            <TableCell id="description">Procedure</TableCell>

                            <TableCell id="description">Status/Memo</TableCell>

                            <TableCell id="description">Amount</TableCell>

                            <TableCell id="description">Applied</TableCell>
                            <TableCell id="description">Balance</TableCell>
                          </TableRow>
                        </TableHead>
                        {formik?.values?.claimManageAccountDto?.map(
                          (el, id) => {
                            return (
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <div
                                      style={{
                                        display: "flex",
                                        fontSize: "13px",
                                      }}
                                    >
                                      <div
                                        onClick={() =>
                                          // handleArrow("step1", el?.claimNumber)
                                          handleArrow("step1", el?.claimId)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {el?.isFalse ? (
                                          <ArrowDropDownIcon fontSize="large" />
                                        ) : (
                                          <ArrowRightIcon fontSize="large" />
                                        )}
                                      </div>
                                      <div>
                                        {new Date(el?.dos).toDateString()}
                                      </div>
                                    </div>
                                  </TableCell>

                                  <TableCell></TableCell>

                                  <TableCell></TableCell>

                                  <TableCell id="description">
                                    {el?.amount}
                                  </TableCell>

                                  <TableCell id="description">
                                    {el?.applied}
                                  </TableCell>

                                  <TableCell id="description">
                                    {el?.balance}
                                  </TableCell>
                                </TableRow>
                                {el?.isFalse &&
                                  el?.claimChargesManageAccountDto.length > 0 &&
                                  el?.claimChargesManageAccountDto.map(
                                    (val, ind) => {
                                      return (
                                        <TableRow>
                                          <TableCell>
                                            <div
                                              style={{
                                                display: "flex",
                                                fontSize: "13px",
                                                marginLeft: "13px",
                                              }}
                                            >
                                              <div
                                                onClick={() =>
                                                  handleArrow(
                                                    "step2",
                                                    // el?.claimNumber,
                                                    el?.claimId,
                                                    val?.claimChargeId
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val?.isFalse ? (
                                                  <ArrowDropDownIcon fontSize="large" />
                                                ) : (
                                                  <ArrowRightIcon fontSize="large" />
                                                )}
                                              </div>
                                              <div>
                                                {new Date(
                                                  val?.dos
                                                ).toDateString()}
                                              </div>
                                            </div>
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.procedureCode}
                                          </TableCell>

                                          <TableCell sx={{ padding: 0 }}>
                                            <Select
                                              sx={{
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                "& .MuiNativeSelect-icon": {
                                                  fontSize: "12px !important",
                                                },
                                              }}
                                              // className="customSelectBox"
                                              style={{
                                                background:
                                                  theme.palette
                                                    .customFieldsBackground
                                                    .primary,
                                                border: "1px solid gray",
                                                color:
                                                  theme.palette.fieldsText
                                                    .primary,
                                                height: "13px !important",
                                                fontSize: "12px !important",
                                              }}
                                              value={val?.claimStatusId}
                                              onChange={(e) =>
                                                handleClaimChargesStatusChange(
                                                  val?.claimChargeId,
                                                  // val?.claimNumber,
                                                  val?.claimId,
                                                  e
                                                )
                                              }
                                              native
                                              id="dropdowns"
                                            >
                                              <option
                                                key={0}
                                                value={null}
                                                style={{
                                                  color: "lightgray !important",
                                                }}
                                              >
                                                Select
                                              </option>

                                              {statusList.map((opt) => {
                                                return (
                                                  <>
                                                    <option
                                                      style={{
                                                        background:
                                                          theme.palette
                                                            .fieldsDDown
                                                            .primary,
                                                      }}
                                                      itemType="number"
                                                      key={opt.claimStatusId}
                                                      value={opt.claimStatusId}
                                                    >
                                                      {opt.claimStatusType}
                                                    </option>
                                                  </>
                                                );
                                              })}
                                            </Select>
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.amount}
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.applied}
                                          </TableCell>

                                          <TableCell id="description">
                                            {val?.balance}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                              </TableBody>
                            );
                          }
                        )}
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>No Record Found</Typography>
                  )}
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion
                defaultExpanded={false}
                heading="Account Debit , Credit & Refund Listing"
              >
                <AccordionDetails>
                  <CustomAccordion
                    defaultExpanded={false}
                    heading="Credit Detail"
                  >
                    <AccordionDetails>
                      {formik.values?.creditAccountDto &&
                      formik.values?.creditAccountDto.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{
                                background:
                                  theme.palette.infocodesHeader.primary,
                                color: theme.palette.sidebarDDownText.primary,
                              }}
                              id="t-header"
                            >
                              <TableRow>
                                <TableCell>DOS/Recived Date</TableCell>
                                <TableCell>Payment By</TableCell>

                                <TableCell>Status/Memo</TableCell>

                                <TableCell>Amount</TableCell>

                                <TableCell>Applied</TableCell>
                                <TableCell>Remaning</TableCell>
                              </TableRow>
                            </TableHead>
                            {formik?.values?.creditAccountDto?.map((el, id) => {
                              return (
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "240px",
                                        }}
                                      >
                                        <div>
                                          {new Date(
                                            el?.receivedDate
                                          ).toDateString()}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell id="description">
                                      {el?.paymentByTypeId === 1
                                        ? "Patient"
                                        : "Insurance"}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.memo}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.amount}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.applied || 0}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.remaining}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography>No Record Found</Typography>
                      )}
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    defaultExpanded={false}
                    heading="Debit Detail"
                  >
                    <AccordionDetails>
                      {formik.values?.debitAccountDto &&
                      formik.values?.debitAccountDto.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{
                                background:
                                  theme.palette.infocodesHeader.primary,
                                color: theme.palette.sidebarDDownText.primary,
                              }}
                              id="t-header"
                            >
                              <TableRow>
                                <TableCell>DOS/Recived Date</TableCell>

                                <TableCell>Status/Memo</TableCell>

                                <TableCell>Amount</TableCell>

                                <TableCell>Applied</TableCell>
                                <TableCell>Remaining</TableCell>
                              </TableRow>
                            </TableHead>
                            {formik?.values?.debitAccountDto?.map((el, id) => {
                              return (
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "240px",
                                        }}
                                      >
                                        <div>
                                          {new Date(
                                            el?.receivedDate
                                          ).toDateString()}
                                        </div>
                                      </div>
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.memo}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.amount}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.applied || 0}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.remaining}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography>No Record Found</Typography>
                      )}
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    defaultExpanded={false}
                    heading="Refund Detail"
                  >
                    <AccordionDetails>
                      {formik.values?.refundAccountDto &&
                      formik.values?.refundAccountDto.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{
                                background:
                                  theme.palette.infocodesHeader.primary,
                                color: theme.palette.sidebarDDownText.primary,
                              }}
                              id="t-header"
                            >
                              <TableRow>
                                <TableCell>DOS/Recived Date</TableCell>

                                <TableCell>Status/Memo</TableCell>

                                <TableCell>Amount</TableCell>

                                <TableCell>Applied</TableCell>
                                <TableCell>Remaining</TableCell>
                              </TableRow>
                            </TableHead>
                            {formik?.values?.refundAccountDto?.map((el, id) => {
                              return (
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "240px",
                                        }}
                                      >
                                        <div>
                                          {new Date(
                                            el?.refundDate
                                          ).toDateString()}
                                        </div>
                                      </div>
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.memo}
                                    </TableCell>

                                    <TableCell id="description">
                                      {el?.amount}
                                    </TableCell>

                                    <TableCell id="description">
                                      {/* {el?.applied || 0} */}
                                    </TableCell>

                                    <TableCell id="description">
                                      {/* {el?.remaining} */}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography>No Record Found</Typography>
                      )}
                    </AccordionDetails>
                  </CustomAccordion>
                </AccordionDetails>
              </CustomAccordion>
            </form>
          </>
        )}
        {/* modals here */}
        <CustomModal
          open={openDebitAcc}
          handleClose={() => {
            debitFormik.resetForm();
            setOpenDebitAcc(false);
          }}
          heading={"Add Debit"}
        >
          <DebitAccount formik={debitFormik} />
        </CustomModal>
        <CustomModal
          open={openCreditAcc}
          handleClose={() => {
            creditFormik.resetForm();
            setOpenCreditAcc(false);
          }}
          heading={"Credit Adjustment"}
        >
          <CreditAccount formik={creditFormik}/>
        </CustomModal>
        <CustomModal
          open={openRefundAcc}
          handleClose={() => setOpenRefundAcc(false)}
          heading={"Refund Credits"}
        >
          <RefundAccount
            availableCredit={
              formik.values?.patientCredit + formik.values?.insuranceCredit
            }
            handleClose={() => {
              debitFormik.resetForm();
              setOpenDebitAcc(false);
            }}
            formik={refundFormik}
          />
        </CustomModal>
      </Box>
    </>
  );
};

export default ManageAccountDetails;
