import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getPracticeByIdAction,
  getPracticeListAction,
  updatePracticeAction,
} from "../../../features/actions/practiceAction";
import { useNavigate, useParams } from "react-router-dom";
import PracticeInfo from "./PracticeInfo";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";
import { toast } from "react-toastify";
const UpdatePractice = () => {
  let flag = false
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { practiceId } = useParams();
  console.log("idscheck",practiceId)
  const { practiceById } = useSelector((state) => state.practices);
  const findPractice = practiceById?.result;

  const formik = useFormik({
    initialValues: findPractice || practiceInitVals,
    validationSchema: practiceSchema,
    enableReinitialize: true,
    onSubmit:  (values) => {
      try {
        if(!flag){
          flag = true
          dispatch(updatePracticeAction({
            practiceId: findPractice?.practiceId,
            ...values,
          }))
          dispatch(getPracticeListAction())
        }
        toast.success("Practice update successfully");
        navigate("/practice");
      } catch (error) {
        toast.error("Error creating Practice:", error);
      }
    },
  });

  useEffect(() => {
    dispatch(getPracticeByIdAction(practiceId));
  }, [dispatch]);
  return (
    <>
          <PracticeInfo formik={formik}/>
    </>
  );
};

export default UpdatePractice;
