import React, { useEffect, useState } from "react";
import FacilityInfo from "./FacilityInfo";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getFacilityByIdAction, updateFacilityAction } from "../../../features/actions/facilityAction";
import { useNavigate, useParams } from "react-router-dom";
import { facilityInitVals } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import { Box, Typography } from "@mui/material";
import { facilitySchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const UpdateFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { sequenceNo } = useParams();
  const {facilityId} = useParams();
  const { facilityByIdData, loading } = useSelector((state) => state.facility);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const findFacility = facilityByIdData?.result;
  console.log("findFacility", findFacility);

  const formik = useFormik({
    initialValues: findFacility || facilityInitVals,
    validationSchema: facilitySchema,
    enableReinitialize: true,
    onSubmit: (values,action) => {
      console.log("Submitting form with values:", values); 
      dispatch(
        updateFacilityAction({
          // sequenceNo: findFacility?.sequenceNo,
          facilityId: findFacility?.facilityId,
          ...values,
        })
      ).then((response) => {
        console.log("Update response:", response);
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/facility");
        } else {
          console.error("Update failed:", response.error.message);
        }
      });
    },
  });


  // useEffect(() => {
  //   dispatch(getFacilityByIdAction(facilityId));
  // }, [dispatch,facilityId]);
  useEffect(() => {
    dispatch(getFacilityByIdAction(facilityId));
  }, []);

  return (
    <>
          <FacilityInfo formik={formik} />
    </>
  );
};

export default UpdateFacility;
