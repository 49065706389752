import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../components/CustomButton";

const PaymentInsurances = ({ claimInsurances, formik, handleClose }) => {
  const handlePolicySelect = (policy) => {
    console.log("policy selected", policy);
    formik.setFieldValue("insuranceDescription", policy.insuranceName);
    formik.setFieldValue("insuranceDescriptionId", policy.insuranceId);
 
  };
  return (
    <Stack padding={"20px"}>
      <Typography component={"h3"}>Please select the payer to use for the payment.</Typography>
      <FormControl>
        <RadioGroup name="multi-insurances">
          {claimInsurances?.policyTypeDto?.map((policy) => (
            <FormControlLabel
              value={policy?.insuranceName}
              control={
                <Radio
                  checked={
                    formik.values.insuranceDescriptionId === policy.insuranceId
                  }
                  onChange={() => handlePolicySelect(policy)}
                />
              }
              label={policy?.insuranceName}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Stack direction="row" justifyContent="flex-end">
        <CustomButton isBlue={true} handleClick={ handleClose}>Done</CustomButton>
      </Stack>
    </Stack>
  );
};

export default PaymentInsurances;
