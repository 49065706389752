import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

// create patient action creator
export const newPatientAction = createAsyncThunk(
  "createPatient",
  async (data) => {
    try {
      const response = await postData(`${path}/patients`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      console.log("error", error?.response);
      throw error;
    }
  }
);

export const getPatientAction = createAsyncThunk("getPatient", async (data) => {
  console.log(data,"data")
  try {
    const newPage = data?.page + 1
    const response = await getData(`${path}/patients?page=${newPage}&pageSize=20`);
    if (response) {
      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
});

// fetch patient by account number
// export const getPatientByAccountNoAction = createAsyncThunk(
//   "getPatientByAccountNoAction",
//   async (accountNo) => {
//     try {
//       const response = await getData(`${path}/patients/${accountNo}`);
//       if (response) {
//         return response;
//       }
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );
export const getPatientByIdAction = createAsyncThunk(
  "getPatientByIdAction",
  async (patientId) => {
    try {
      const response = await getData(`${path}/patients/${patientId}`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// export const getSearchedPatient = createAsyncThunk(
//   "getSearchedPatient",
//   async (query) => {
//     try {
//       const response = await getData(
//         `${path}/patients/GetPatientById?${query}`
//       );
//       if (response) {
//         return response;
//       }
//     } catch (error) {
//       console.log(error, "Fetching Searched Patient Error:");
//     }
//   }
// );

export const getPatientBySearchTermAction = createAsyncThunk(
  "getPatient",
  async (data) => {
   console.log(data);
   
    try {
      const response = await getData(
        `${path}/patients/search?SearchValue=${data.searchTerm}&page=${data.page}&pageSize=${data.pageSize}&showInActive=${data.showInActive}&exactMatch=${data.exactMatch}&isPatientSearch=${data.isPatientSearch}&showAll=${data?.showAll}`
      );
      console.log(response, "action patient by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updatePatientAction = createAsyncThunk(
  "updatePatient",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/patients/${data.patientId}`,
        data
      );
      return response.result; // Return the updated patient data
    } catch (error) {
      throw error;
    }
  }
);

// export const getSinglePatientData = createAsyncThunk(
//   "singlePatientData",
//   async (patientAccountNo) => {
//     try {
//       const response = await getData(`${path}/patients/${patientAccountNo}`);
//       if (response) {
//         console.log("patient response: ", response);
//         return response;
//       }
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );

export const getSinglePatientData = createAsyncThunk(
  "singlePatientData",
  async (patientId) => {
    try {
      const response = await getData(`${path}/patients/${patientId}`);
      if (response) {
        console.log("patient response: ", response);
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// export const getPatientClaimsAction = createAsyncThunk(
//   "getPatientClaim",
//   async (accountNo) => {
//     console.log(accountNo, "checkAccountNo");
//     try {
//       const response = await getData(`${path}/claim/patient/${accountNo}`);
//       if (response) {
//         return response;
//       }
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );
export const getPatientClaimsAction = createAsyncThunk(
  "getPatientClaim",
  async (patientId) => {
    console.log(patientId, "checkPatientId");
    try {
      const response = await getData(`${path}/claim/patient/${patientId}`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// duplicate patient
export const checkDuplicatePatAction = createAsyncThunk(
  "duplicatePatient",
  async (queryArgs) => {
    console.log(queryArgs, "queryArgs");
    const { first, last, pacctno, birthdate } = queryArgs;
    try {
      const response = await getData(
        `${path}/patients/DuplicatePatient?pacctno=${pacctno}&last=${last}&first=${first}&birthdate=${birthdate}&page=1&pageSize=20`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
