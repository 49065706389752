export const postPayDetailPatientCols = [
    {
        field: "claimId",
        headerName: "Claim",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "dos",
        headerName: "DOS",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "procedureCode",
        headerName: "Procedure",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "amount",
        headerName: "Amount",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "startBalance",
        headerName: "Start Balance",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "allowedAmount",
        headerName: "Apply Payment",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "claimStatus",
        headerName: "Status",
        flex: 3,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
 
    {
        field: "endBalance",
        headerName: "End Balance",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
]
export const postPayDetailInsCols = [
    {
        field: "claimId",
        headerName: "Claim",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "dos",
        headerName: "DOS",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "procedureCode",
        headerName: "Procedure",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "amount",
        headerName: "Amount",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "startBalance",
        headerName: "Start Balance",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "allowedAmount",
        headerName: "Allowed",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "paid",
        headerName: "Paid",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "adjusted",
        headerName: "Adjusted",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "unpaid",
        headerName: "Unpaid",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "deductible",
        headerName: "Deductible",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "claimStatus",
        headerName: "Status",
        flex: 3,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "otherCredits",
        headerName: "Other Credits",
        flex: 3,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
    {
        field: "endBalance",
        headerName: "End Balance",
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
]