import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getAllUsers = createAsyncThunk("getUsers", async (page) => {
  try {
    const newPage = page?.page + 1;
    const response = await getData(
      `${path}/authenticate/users?page=${newPage}&pageSize=20`
    );
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const getUserByIdAction = createAsyncThunk(
  "getUserById",
  async (userId) => {
    try {
      const response = await getData(
        `${path}/authenticate/getUserById/${userId}`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const newUserAction = createAsyncThunk("createUser", async (data) => {
  try {
    const response = await postData(`${path}/authenticate/register`, data);
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "check Error");
    throw new Error(error);
  }
});

export const updateUserAction = createAsyncThunk("updateUser", async (data) => {
  try {
    const response = await updateData(
      `${path}/authenticate/register/${data?.userId}`,
      data
    );
    console.log(response, "update user action response");
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateUserPasswordAction = createAsyncThunk(
  "updateUserPassword",
  async (data) => {
    console.log(data, "userdata");
    try {
      const response = await updateData(
        `${path}/authenticate/changePassword/${data.userId}`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const switchPracticeAction = createAsyncThunk(
  "swicthPractice",
  async (seqNo) => {
    try {
      const response = await getData(
        `${path}/authenticate/switch-practice?practiceSeqNo=${seqNo}`
      );
      return response;
    } catch (error) {
      console.log(error, "swicthPractice Error");
      return error;
    }
  }
);

// search user
export const getUsersBySearchTermAction = createAsyncThunk(
  "getUsers",
  async ({ searchTerm, page }) => {
    try {
      const response = await getData(
        `${path}/authenticate/searchUser?searchValue=${searchTerm}&pageNumber=${page}&pageSize=20`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
