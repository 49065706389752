import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getRevenueAction = createAsyncThunk("getRevenue", async (page) => {
  try {
    console.log(page);
    const newPage = page?.page+1
    const response = await getData(`${path}/revenueCode?page=${newPage}&pageSize=20`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "fetch revenue action error");
    throw new Error(error);
  }
});

export const newRevenueAction = createAsyncThunk(
  "createRevenue",
  async (data) => {
    try {
      const response = await postData(`${path}/revenueCode`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "create revenue Error");
      throw new Error(error);
    }
  }
);

// get master list(master revenue code)
export const getMasterRevenueCodeAction = createAsyncThunk(
  "getMasterRevenueCode",
  async () => {
    try {
      const response = await getData(`${path}/masterRevenueCode`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search

export const getRevenueCodeBySearchTermAction = createAsyncThunk(
  "getRevenue",
  async ({searchTerm,page}) => {
    try {
      const response = await getData(
        `${path}/revenueCode/SearchRevenueCode?searchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      console.log(response, "action revenue by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getRevenueyByIdAction = createAsyncThunk(
  "getRevenueById",
  async (id) => {
    try {

      const response = await getData(`${path}/revenueCode/GetRevenueCodeById?Id=${id}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateRevenueAction = createAsyncThunk(
  "updateRevenue",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/revenueCode/${data.revenueCodeId}`,
        data
      );
      console.log(response, "update revenue action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
