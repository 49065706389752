import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const newClaimAction = createAsyncThunk("createClaim", async (data) => {
  try {
    const response = await postData(`${path}/claim/AddProfessionalClaim`, data);
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw error;
  }
});

export const getClaimAction = createAsyncThunk("getClaim", async (page) => {
  const newPage = page?.page + 1;
  try {
    const response = await getData(`${path}/claim/GetAllProfessionalClaim?page=${newPage}&pageSize=20`);
    console.log(response, "action calim response");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});
// get claim by claim number
export const getClaimByNumberAction = createAsyncThunk(
  "getClaimByNumber",
  async (claimId) => {
    try {
      const response = await getData(
        `${path}/claim/GetProfessionalClaimById/${claimId}`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error?.response);
    }
  }
);

export const getClaimAcivityActionSingle = createAsyncThunk(
  "getClaimAcivity",
  // async (claimNumber) => {
  async (claimId) => {
    try {
      const response = await getData(
        // `${path}/claimActivity/GetClaimByClaimNumber?claimNo=${claimNumber}`
        `${path}/claimActivity/GetClaimByClaimNumber?claimNo=${claimId}`

      );
      if (response) {
        return [response.result];
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getClaimAcivityActionMultiple = createAsyncThunk(
  "getClaimAcivity",
  async (patientId) => {
    try {
      const response = await getData(
        `${path}/claimActivity/GetClaimActByPatientId?PatientId=${patientId}`
      );
      if (response) {
        return response.result;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getClaimBySearchTermAction = createAsyncThunk(
  "getClaim",
  async ({ searchTerm, page, exactMatch }) => {
    try {
      const response = await getData(
        `${path}/claim/SearchProfessionalClaim?searchValue=${searchTerm}&page=${page}&pageSize=20&isExactMatch=${exactMatch}`
        // `${path}/claim/SearchClaim?searchValue=${val}`
      );
      console.log(response, "action calim by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateClaimAction = createAsyncThunk(
  "updateClaim",
  async (data) => {
    try {
      const response = await updateData(
        // `${path}/claim/UpdateProfessionalClaim/${data.claimNumber}`,
        `${path}/claim/UpdateProfessionalClaim/${data.claimId}`,
        data
      );
      console.log(response, "update claim action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// patient claim details
export const getPatientClaimsDetailAction = createAsyncThunk(
  "patient_claims_details",
  async (page) => {
    try {
      const newPage = page?.page + 1;
      const response = await getData(
        `${path}/patientClaimDetail?page=${newPage}&pageSize=20`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const getPatientClaimBySearchTerm = createAsyncThunk(
  "patient_claims_details",
  async ({ searchTerm, page }) => {
    try {
      const response = await getData(
        `${path}/patientClaimDetail?searchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getClaimChargesHistoryAction = createAsyncThunk(
  "claimChargesHistoryAction",
  async (claimNo) => {
    try {
      const { result } = await getData(
        `${path}/claim/ChargeHistory?claimNo=${claimNo}`
      );
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }
);
// export const getPatientMultipleClaims = createAsyncThunk(
//   "patient-multiple-claims",
//   async (accountNo) => {
//     try {
//       const response = await getData(`${path}/claim/patient/${accountNo}`);
//       if (response) {
//         return response;
//       }
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );
