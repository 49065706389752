import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomPreview from "../../../layout/CustomPreview";
import CustomButton from "../../../components/CustomButton";
import {
  getCompanyAction,
  getCompanyBySearchTermAction,
} from "../../../features/actions/companiesAction";
import { companyCols } from "../../../utils/gridCols";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const navigate=useNavigate();
  const { getCompaniesData } = useSelector((state) => state.companies);
    const rows =
    getCompaniesData?.result?.companies?.length > 0
      ? getCompaniesData?.result?.companies?.map((el) => ({
          id: el?.companyId,
          ...el,
        }))
      : [];
  return (
    <Box>
      <CustomPreview
        searchLabel={"Search company by name and code"}
        handleBySearch={getCompanyBySearchTermAction}
        handleGetAll={getCompanyAction}
        // handleCell={(params) => navigate(`/company/update/${params?.companyCode}`)}
        handleCell={(params) => navigate(`/company/update/${params?.companyId}`)}
        rows={rows}
        paginationDetail={getCompaniesData}
        columns={companyCols}
        moduleHeading="All Companies"
      >
        <CustomButton isAdd={true} isBlue={true} handleClick={() => navigate("/company/new")}>
          Add New
        </CustomButton>
      </CustomPreview>
    </Box>
  );
};

export default Company;
